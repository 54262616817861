import React, { useState } from 'react'

// components
import StaticNotification from 'components/StaticNotification'

// assets
import WarningIcon from 'assets/icons/warning.svg'

type ModalConfirmDeactivationType = {
  onClick: () => void
  onNoClick: () => void
  onClose: () => void
  entity: 'client' | 'budget'
}

const ModalConfirmDeactivation: React.FC<ModalConfirmDeactivationType> = ({
  onClick,
  onNoClick,
  onClose,
  entity,
}) => {
  const [activeContent, setActiveContent] = useState<'preconfirm' | 'confirm'>('preconfirm')

  const modalContent = {
    preconfirm: {
      title: `Deactivate ${entity}`,
      yes: 'Yes, deactivate',
      description: `Are you sure you want to deactivate this ${entity}? ${entity === 'client' ? 'This will also deactivate all client budgets.' : ''}`,
    },
    confirm: {
      title: 'Pause Campaign',
      yes: 'Yes, pause',
      no: 'No, dont pause',
      description: `Do you want to pause all campaigns in this ${entity === 'client' ? 'client\'s budgets' : 'budget'}?`,
      onNoClick,
    },
  }

  const handleClick = () => {
    if (activeContent === 'preconfirm') {
      setActiveContent('confirm')
    } else {
      onClick()
    }
  }

  return (
    <StaticNotification
      icon={WarningIcon}
      title={modalContent[activeContent].title}
      yes={modalContent[activeContent].yes}
      no={modalContent[activeContent].no || 'Cancel'}
      type="warning"
      description={modalContent[activeContent].description}
      handleClick={handleClick}
      onClose={modalContent[activeContent].onNoClick || onClose}
    />
  )
}

export default ModalConfirmDeactivation
