import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { LABEL_ACTIONS } from 'state-manager/constants'

export interface ILabels {
  labels: Array<{
    id: number,
    name: string,
    color: string,
  }>,
}

const initialState: ILabels = {
  labels: [],
}

export const types = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    color: PropTypes.string,
  })),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LABEL_ACTIONS.LABEL_GET_ALL.SUCCESS:
      return updateObject(state, {
        labels: action.data,
      })

    default:
      return state
  }
}
