import React from 'react'
import { ENV } from 'constants/main'

const sizeConfig = {
  xxl: 138,
  xl: 40,
  lg: 32,
  md: 24,
  smd: 18,
  sm: 16,
  xxs: 8,
}

type props = {
  name: string;
  size?: keyof typeof sizeConfig;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  dataCy?: string;
}

const isLocalEnvironment = ENV === 'local'

const SpriteIcon: React.FC<props> = ({
  name,
  size,
  className,
  onClick,
  dataCy,
}) => {
  if (onClick && !dataCy && isLocalEnvironment) {
    console.warn('DataCy prop is required when onClick is provided. \n Component with name = ', name)
  }
  return (
    <svg
      data-cy={dataCy}
      className={className}
      onClick={onClick}
      width={size ? sizeConfig[size] : undefined}
      height={size ? sizeConfig[size] : undefined}>
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}

export default SpriteIcon
