import React, { useState } from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { Location } from 'history'
import { FormFeedback, FormGroup } from 'reactstrap'
import { PaymentMethod, loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

/* utils */
import reqH from 'utils/request-handler'
import globalSpinner from 'utils/global-spinner'
import notification from 'utils/notifications'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'

// constants
import { STRIPE_PUBLIC_KEY } from 'constants/main'
import { SAVE__BILLING_INFO_FORM } from 'constants/custom-events'

// types
import { IBillingInfo } from 'state-manager/reducers/billing-info'

/* styles */
import classes from 'pages/Billing/Billing.module.scss'
import 'pages/Billing/card-form/styles.scss'

const getStripeClientSecret = () => reqH({
  method: 'GET',
  url: 'users/current/stripe/client-secret',
  keepGlobalSpinnerAfterResponse: true,
}).then((res) => res.data.result.clientSecret)

type CardInputType = {
  disabled: boolean;
  billingInfo: IBillingInfo["data"];
  addPaymentMethod: (
    paymentData: { paymentMethod: string | PaymentMethod | null },
    location: Location<unknown>
  ) => void;
}

const CardInput: React.FC<CardInputType> = ({
  disabled,
  billingInfo,
  addPaymentMethod,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState<string | null>(null)

  const location = useLocation()

  const handleSubmit = () => {
    if (!stripe || !elements || disabled) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      notification.error("Something went wrong, try to reload the page")
      return
    }

    getStripeClientSecret()
      .then((clientSecret) => {
        stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: {
              type: 'card',
              card: cardElement,
              billing_details: billingInfo,
            },
          },
        ).then((payload) => {
          if (payload.error?.message) {
            notification.error(payload.error.message)
            globalSpinner.hide()
            return
          }

          if (payload.setupIntent) {
            const paymentData = {
              paymentMethod: payload.setupIntent.payment_method,
            }

            addPaymentMethod(paymentData, location)
          } else {
            notification.error('Payment error: something went wrong')
            globalSpinner.hide()
          }
        })
      })
  }

  _useCustomEventListener(SAVE__BILLING_INFO_FORM, handleSubmit)

  return (
    <>
      <FormGroup className={clsx(classes.creditCard, 'p-4')}>
        <div className="fw-regular fs-main mb-1 d-flex justify-content-start">Card Credentials</div>
        <CardElement
          onChange={(e) => {
            if (e.error) {
              setError(e.error.message)
              return
            }
            setError(null)
          }}
          className="form-control"
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '16px',
                color: '#495057',
                '::placeholder': {
                  color: '#6c757d',
                },
              },
            },
          }}
        />

        {error && (
          <FormFeedback>
            {error}
          </FormFeedback>
        )}
      </FormGroup>
      <div
        role="button"
        className={clsx(disabled && 'disabled', 'btn full-width mt-8')}
        onClick={handleSubmit}>
        Add billing information
      </div>
    </>
  )
}

type CardFormType = {
  disabled: boolean;
  billingInfo: IBillingInfo["data"];
  addPaymentMethod: (
    paymentData: { paymentMethod: string | PaymentMethod | null },
    location: Location<unknown>
  ) => void;
}

const CardForm: React.FC<CardFormType> = ({
  disabled,
  billingInfo,
  addPaymentMethod,
}) => (
  <Elements stripe={loadStripe(STRIPE_PUBLIC_KEY as string)}>
    <CardInput
      disabled={disabled}
      billingInfo={billingInfo}
      addPaymentMethod={addPaymentMethod}
    />
  </Elements>
)

export default CardForm
