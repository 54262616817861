import React from 'react'
import { useHistory } from 'react-router-dom'

// components
import StaticNotification from 'components/StaticNotification'

// routes
import routes from 'routes'

// assets
import SuccessIcon from 'assets/icons/success.svg'

const ModalPasswordChanged: React.FC = () => {
  const history = useHistory()
  return (
    <StaticNotification
      icon={SuccessIcon}
      title="Success!"
      yes="Login"
      description="Password changes successfully. Now you can login"
      handleClick={() => history.push(routes.modalSignIn[0])}
    />
  )
}

export default ModalPasswordChanged
