import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { PERIOD_MONTH, PERIOD_YEAR } from 'constants/plans'
import { PLAN_ACTIONS } from 'state-manager/constants'

export interface IPlans {
  data: Array<{
    id: number,
    currency: number,
    monthlyMediaSpend: number,
    period: typeof PERIOD_MONTH | typeof PERIOD_YEAR,
    price: number,
    plan: string,
  }>,
}

const initialState: IPlans = {
  data: [],
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    currency: PropTypes.number,
    monthlyMediaSpend: PropTypes.number,
    period: PropTypes.oneOf([PERIOD_MONTH, PERIOD_YEAR]),
    price: PropTypes.number,
    plan: PropTypes.string,
  })),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PLAN_ACTIONS.PLAN_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
      })

    case PLAN_ACTIONS.PLAN_GET_CUSTOM.SUCCESS:
      const newState = { ...state }
      if (newState.data.length && !newState.data.some((item) => item.id === action.data.id)) {
        newState.data.push(action.data)

        return newState
      }

      return state

    default:
      return state
  }
}
