import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import reqH from 'utils/request-handler'

type billingType = {
  address: string | null,
  cardHolder: string | null,
  cardLastFour: string | null,
  city: string | null,
  country: string,
  defaultPaymentMethod: string | null,
  gstNumber: string | null,
  paymentMethods: Array<{
    id: string;
    card: {
      lastFour: string;
      brand: string;
    }
  }>
  postcode: string | null,
  state: string | null,
}

export interface IBillingInfo {
  data: billingType | null
}

export const fetchBillingInfo = createAsyncThunk(
  'billingInfo/fetch',
  async () => {
    const res = await reqH<{ result: billingType }>({
      method: 'GET',
      url: 'users/current/stripe/billing-info',
    })

    return res.data.result
  }
)

const initialState: IBillingInfo = {
  data: null
}

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBillingInfo.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default billingSlice.reducer

