import React, { useEffect } from 'react'
import { Line } from 'rc-progress'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'state-manager/store'

// actions
import { skipWelcomeGuide } from 'state-manager/actions/user'

// assets
import DataSourceIcon from 'assets/icons/dataSource.svg'
import NextIcon from 'assets/icons/next.svg'
import CheckIcon from 'assets/icons/check.svg'
import BudgetIcon from 'assets/icons/budget.svg'
import TeamIcon from 'assets/icons/team.svg'

// routes
import routes from 'routes'

import { ADD_TEAM_MEMBER_MODAL, CLIENT_MODAL } from 'state-manager/constants'
import { showGlobalModal } from 'state-manager/actions/global-modal'

import styles from 'pages/Dashboard/shared/WelcomeThumb/WelcomeThumb.module.scss'

type CompleteType = {
  icon: string;
  title: string;
}

const Complete: React.FC<CompleteType> = ({ icon, title }) => (
  <div className={styles.thumbContent}>
    <img className={styles.thumbImg} src={icon} alt="icon" />
    <div>
      <p className="fw-medium">{title}</p>
      <div className="d-flex align-items-center">
        <img src={CheckIcon} alt="check icon" />
        <span className="fw-regular">Done</span>
      </div>
    </div>
  </div>
)

const NotComplete: React.FC<CompleteType> = ({ icon, title }) => (
  <>
    <div className={styles.thumbContent}>
      <img className={styles.thumbImg} src={icon} alt="icon" />
      <div>
        <p className="fw-medium">{title}</p>
      </div>
    </div>
    <img src={NextIcon} className={styles.naxtImg} alt="data source icon" />
  </>
)

const WelcomeThumb = () => {
  const {
    udFirstName,
    udLastName,
    udGuideSkipped,
    udPermissions,
    budgets,
    clients,
    teamMember,
    teamMembersRequests,
    dataSources,
    allClients,
  } = useAppSelector((state) => ({
    udFirstName: state.userData.firstName,
    udLastName: state.userData.lastName,
    udGuideSkipped: state.userData.guideSkipped,
    udPermissions: state.userData.permissions,
    budgets: state.allBudgets.content,
    clients: state.allClients.data,
    teamMember: state.teamMembers.data,
    teamMembersRequests: state.teamMembers.requests,
    dataSources: state.dataSources.data,
    allClients: state.allClients.data,
  }))

  const history = useHistory()
  const redirectTo = (route: string) => history.push(route)

  const dispatch = useDispatch()

  const isDataSources = Boolean(dataSources.length)
  const isClients = Boolean(clients.length && budgets.length)
  const isTeamMember = Boolean(teamMember.length > 1 || teamMembersRequests.length)

  useEffect(() => {
    if (!udGuideSkipped && isDataSources && isClients && isTeamMember) {
      dispatch(skipWelcomeGuide())
    }
  }, [udGuideSkipped, isDataSources, isClients, isTeamMember, dispatch])

  if (udGuideSkipped || !udPermissions.canManageSubscriptions || (isDataSources && isClients && isTeamMember)) {
    return null
  }

  let progress = 0

  if (isDataSources) {
    progress += 1
  }

  if (isClients) {
    progress += 1
  }

  if (isTeamMember) {
    progress += 1
  }

  return (
    <>
      <div className="row justify-content-between flex-column-sm-up">
        <h2 className="fw-semibold mt-0">Welcome, {udFirstName} {udLastName}! Let’s start</h2>
        <div className="d-flex align-items-center">
          <span
            className="pr-2 fw-regular color-dark-grey">
            {progress}/3
          </span>
          <Line percent={progress * 33} strokeWidth={1} strokeColor="#3DB14A" className="w-20" />
          <span
            className="pl-2 fw-regular color-green"
            role="button"
            data-cy="skipTutorial"
            onClick={() => dispatch(skipWelcomeGuide())}>
            Skip
          </span>
        </div>
      </div>
      <div className={styles.thumbWrapper}>
        <div
          className={styles.thumbItem}
          onClick={() => (!isDataSources && redirectTo(routes.addDataSource))}
          data-cy="linkDataSources"
          role="link">
          {isDataSources ? (
            <Complete
              icon={DataSourceIcon}
              title="Link data sources"
            />
          ) : (
            <NotComplete
              icon={DataSourceIcon}
              title="Link data sources"
            />
          )}
        </div>
        <div
          className={styles.thumbItem}
          onClick={() => {
            if (!isClients && isDataSources) {
              dispatch({
                type: CLIENT_MODAL.SHOW,
              })
            } else {
              dispatch(showGlobalModal({
                title: 'Add Client',
                header: 'You cannot perform this action',
                description: 'To add the client please add at least one data source',
              }))
            }
          }}
          data-cy="linkClients"
          role="link">
          {isClients ? (
            <Complete
              icon={BudgetIcon}
              title="Create your first client and budget"
            />
          ) : (
            <NotComplete
              icon={BudgetIcon}
              title="Create your first client and budget"
            />
          )}
        </div>
        <div
          className={styles.thumbItem}
          data-cy="linkTeamMembers"
          onClick={() => {
            if (!allClients.length) {
              dispatch(showGlobalModal({
                title: 'Add Team Member',
                header: 'You cannot perform this action',
                description: 'To add the team member please add at least one client',
              }))
            } else if (!isTeamMember) {
              dispatch({ type: ADD_TEAM_MEMBER_MODAL.SHOW })
            }
          }}
          role="link">
          {isTeamMember ? (
            <Complete
              icon={TeamIcon}
              title="Add team members"
            />
          ) : (
            <NotComplete
              icon={TeamIcon}
              title="Add team members"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default WelcomeThumb
