import React, { useState } from 'react'
import clsx from 'clsx'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// constants
import { TAB_SIZE_SM, TAB_SIZE_MD, TAB_SIZE_LG } from 'components/ui/Tabs/constants'

// styles
import classes from 'components/ui/Tabs/tabs.module.scss'

type TabsType = {
  onSelect: (index: number) => void;
  items: Array<{ id: number, label: string }>;
  size?: typeof TAB_SIZE_SM | typeof TAB_SIZE_MD | typeof TAB_SIZE_LG;
  className?: string;
  value: number;
  disabled?: boolean;
  tabRef?: React.MutableRefObject<HTMLDivElement>
}

const Tabs: React.FC<TabsType> = ({
  onSelect,
  items = [],
  size = TAB_SIZE_LG,
  className = '',
  value = 1,
  disabled = false,
  tabRef,
}) => {
  const [active, setActive] = useState(0)

  _useDidMount(() => {
    setActive(value)
  })

  return (
    <div className="d-inline-block w-smup-20">
      <div
        ref={tabRef}
        className={clsx(
          'd-flex',
          classes.tabsContainer,
          size === TAB_SIZE_SM && classes.tabsContainerSmall,
          className,
        )}>
        {items.map((item, index) => (
          <div
            key={index}
            role="button"
            className={clsx(
              'd-flex justify-content-center align-items-center fw-semibold color-green text-center',
              classes.tab,
              index === active && classes.tabActive,
              size === TAB_SIZE_SM && classes.tabSmall,
              size === TAB_SIZE_MD && classes.tabMedium,
            )}
            data-index={index}
            data-cy={item.label}
            onClick={(e) => {
              if (!disabled) {
                const index = parseInt(e.target.dataset.index)
                setActive(index)
                onSelect(index)
              }
            }}>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tabs
