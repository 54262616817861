export default function(obj: any, attrs: Array<string>): boolean {
  if (typeof obj !== 'object') {
    return false
  }

  for(let i = 0; i < attrs.length; i++) {
    if(obj[attrs[i]] === undefined) {
      return false;
    }
  }

  return true;
}