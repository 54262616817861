import React from 'react'

// components
import StaticNotification from 'components/StaticNotification'

// assets
import SuccessIcon from 'assets/icons/success.svg'

type ModalConfirmActivationType = {
  onClick: () => void
  onClose: () => void
  entity: 'budget' | 'client'
}

const ModalConfirmActivation: React.FC<ModalConfirmActivationType> = ({
  onClick,
  onClose,
  entity,
}) => (
  <StaticNotification
    icon={SuccessIcon}
    title={`Activate ${entity}`}
    yes="Yes, activate"
    no="Cancel"
    description={`Are you sure you want to activate this ${entity}?  ${entity === 'client' ? `This will also activate this ${entity} budgets` : ''}`}
    handleClick={onClick}
    onClose={onClose}
  />
)

export default ModalConfirmActivation
