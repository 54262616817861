export const getPacingColorClass = (pacing: number) => {
  if (pacing < 80 || pacing > 120) {
    return 'color-red'
  }

  if (pacing >= 90 && pacing <= 110) {
    return 'color-green'
  }

  return 'color-orange'
}
