import React from 'react'

// components
import Checkbox from 'components/ui/Checkbox'

// constants
import { NOTIFICATION_TYPE_EMAIL, NOTIFICATION_TYPE_PLATFORM } from 'pages/Notifications/constants'

type TableCellCheckboxType = {
  type: string
  original: {
    emailEnabled: 0 | 1
    platformEnabled: 0 | 1
    id: number
  }
  updateNotification: (
    id: string,
    data: { emailEnabled: boolean | number, platformEnabled: boolean | number }
  ) => void
}

const TableCellCheckbox: React.FC<TableCellCheckboxType> = ({
  type,
  original,
  updateNotification,
}) => {
  const { emailEnabled, platformEnabled } = original

  const handlePlatform = () => {
    updateNotification(
      original.id.toString(),
      {
        emailEnabled: type === NOTIFICATION_TYPE_EMAIL ? !emailEnabled : emailEnabled,
        platformEnabled: type === NOTIFICATION_TYPE_PLATFORM ? !platformEnabled : platformEnabled,
      },
    )
  }
  return (
    <div key={original.id}>
      <Checkbox checked={type === NOTIFICATION_TYPE_EMAIL ? !!original.emailEnabled : !!original.platformEnabled} onChange={handlePlatform} />
    </div>
  )
}

export default TableCellCheckbox
