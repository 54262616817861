const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/

// check if string has YYYY-MM-DD format
export const isDateFormat = (date: unknown) => typeof date === 'string' && dateFormatRegex.test(date)

// accept string in YYYY-MM-DD format and return in DD.MM format
export const formatDateStr = (date: string) => {
  const [year, month, day] = date.split('-')
  return `${month}.${day}`
}

// accept string in YYYY-MM-DD format and return in DD.MM format
export const getDateFromStr = (date: string) => {
  const [year, month, day] = date.split('-').map((item) => Number(item))
  return { year, month, day }
}
