import React, { useMemo } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import * as countries from 'react-flags-select'
import { OnSelect } from 'react-flags-select/build/types'

// components
import InputWrapper from 'components/ui/InputWrapper'

type CountrySelectType = {
  label?: string,
  optional?: boolean,
  errorMsg?: string,
  onSelect: OnSelect,
  selected: string,
}

const CountrySelect: React.FC<CountrySelectType> = ({
  label,
  optional,
  errorMsg,
  onSelect = () => {},
  selected,
}) => {
  const countriesFiltered = useMemo(
    () => [
      ...new Set([
        'US',
        'GB',
        ...Object
          .keys(countries)
          .map((item) => item.toUpperCase()),
      ])],
    [],
  )

  return (
    <InputWrapper
      label={label}
      optional={optional}
      errorMsg={errorMsg}>
      <ReactFlagsSelect
        selected={selected}
        countries={countriesFiltered}
        defaultCountry="US"
        onSelect={onSelect}
        className="flag-select-input"
        searchable
      />
    </InputWrapper>
  )
}

export default CountrySelect
