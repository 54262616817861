import { DEMO_MODAL } from "state-manager/constants"

export interface IDemoModal {
  isVisible: boolean,
}

type actionData = {
  type?: string,
  data?: IDemoModal,
}

const initialState: IDemoModal = {
  isVisible: false,
}

export default (state: IDemoModal = initialState, action: actionData = {}) => {
  switch (action.type) {
    case DEMO_MODAL.SHOW: {
      return {
        isVisible: true,
      }
    }
    case DEMO_MODAL.HIDE: {
      return initialState
    }
    default: {
      return state
    }
  }
}
