import React from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// routes
import routes from 'routes'

const AddDataSourceSuccess = () => {
  const history = useHistory()
  const { location } = history

  _useDidMount(() => {
    const params = queryString.parse(location.search)
    window.opener?.postMessage(params, '*')
    // @ts-expect-error window.location doesn't suit but it works
    window.open(window.location, '_self')?.close()
    history.push(routes.dataSources)
  })

  return (
    <div>
      <p>Successfully added!</p>
    </div>
  )
}

export default AddDataSourceSuccess
