import * as Yup from 'yup'
import { PASSWORD } from 'constants/regex'

// eslint-disable-next-line
const notAllowedSymbolsRegex = /[!@#$%^&*()_+=\[\]{};:"\\|,.<>\/?~0-9]+/

const containSpecialSymbols = (value: string) => !notAllowedSymbolsRegex.test(value)

export const getNameValidation = (label: string) => Yup
  .string()
  .label(label)
  .required()
  .test('validate-name', 'Invalid format', containSpecialSymbols)

export const getPasswordValidation = (label: string) => Yup
  .string()
  .label(label)
  .matches(PASSWORD, (options) => `${options.label} must contain a number and a special character`)
  .min(8)
  .max(48)
  .required()
