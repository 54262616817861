import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import reqH from "utils/request-handler"

type country = {
  code: string
  dial_code: string
  id: number
  name: string
}

export interface ICountries {
  data: country[]
}

export const fetchCountries = createAsyncThunk<country[]>(
  'countries/fetch',
  async () => {
    const res = await reqH<country[]>({
      method: 'GET',
      applicationConfigRequest: true,
      applicationConfigName: 'countries',
    })
    return res.data
  }
)

const initialState: ICountries = {
  data: []
}

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default countriesSlice.reducer
