import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import ReactFlagsSelect from 'react-flags-select'

// components
import MoneySlider from 'pages/Subscriptions/MoneySlider'
import SubscriptionPlanCard from 'pages/Subscriptions/SubscriptionPlanCard'

// actions
import { getAllPlans } from 'state-manager/actions/plans'

// routes
import routes from 'routes'

// constants
import { PERIOD_MONTH, PERIOD_YEAR } from 'constants/plans'

// styles
import classes from 'pages/Subscriptions/Subscriptions.module.scss'
import { useAppDispatch, useAppSelector } from 'state-manager/store'
import { signOutSimple } from 'state-manager/actions/auth'

const DemoSlider: React.FC = () => {
  const {
    allPlans,
    configCurrencies,
  } = useAppSelector((state) => ({
    allPlans: state.plans.data,
    configCurrencies: state.currencies.data,
  }))

  const dispatch = useAppDispatch()

  const [currencyId, setCurrencyId] = useState(3)

  const [currencyCode, setCurrencyCode] = useState(currencyId === 1 ? 'US' : 'GB')

  const [sliderValue, setSliderValue] = useState(25000)

  useEffect(() => {
    dispatch(getAllPlans())
    //eslint-disable-next-line
  }, [])

  const currencySymbol = configCurrencies.length ? configCurrencies.find((item) => item.id === currencyId)?.symbol : '$'

  const monthly = useMemo(() => allPlans.filter((item) => item.period === PERIOD_MONTH), [allPlans])
  const yearly = useMemo(() => allPlans.filter((item) => item.period === PERIOD_YEAR), [allPlans])

  if (!allPlans.length) {
    return null
  }

  const monthlyPlan = allPlans.find((item) => item.monthlyMediaSpend === sliderValue && item.currency === currencyId && item.period === PERIOD_MONTH)
  const yearlyPlan = allPlans.find((item) => item.monthlyMediaSpend === sliderValue && item.currency === currencyId && item.period === PERIOD_YEAR)

  const maxSliderValue = 4000000

  const isLimit = sliderValue === maxSliderValue

  const handleSubscribe = () => {
    window.localStorage.setItem('preselectedPlanCurrencyId', `${currencyId}`)
    window.localStorage.setItem('preselectedPlanSliderValue', `${sliderValue}`)

    dispatch(signOutSimple())
    window.open(window.location.origin + routes.modalSignUp[0] + '?logout=true', '_blank')
  }

  // divided by 100 because finalMonthlyPrice is in pennies
  const monthlyPrice = parseInt(`${monthlyPlan ? monthlyPlan.price / 100 : 0}`)
  const yearlyPrice = parseInt(`${yearlyPlan ? yearlyPlan.price / 100 : 0}`)

  // divided by 100 because finalMonthlyPrice is in pennies and by 4 because we need to calculate 20% discount from original finalMonthlyPrice
  const yearlyProfit = parseFloat(`${(monthlyPrice * 12) - yearlyPrice}`)

  const yearlyPriceByMonth = parseInt(`${yearlyPrice / 12}`)

  return (
    <div className="d-flex justify-content-center mt-10 position-relative">
      <div className="container text-center">
        <div className="d-flex justify-content-between align-items-center mt-5 flex-column-sm-up">
          <div className={clsx(classes.slider, 'mb-sm-up-8')}>
            <MoneySlider
              handleSliderChange={setSliderValue}
              currency={currencySymbol}
            />
          </div>
          <div className="d-flex align-items-center">
            <span
              className="fw-regular pb-1 color-dark-grey mr-2">
              Select Currency
            </span>
            <ReactFlagsSelect
              countries={['GB', 'US']}
              customLabels={{ GB: 'GBP', US: 'USD' }}
              placeholder="GB"
              onSelect={(countryCode) => {
                setCurrencyCode(countryCode)
                setCurrencyId(countryCode === 'US' ? 1 : 3)
              }}
              selected={currencyCode}
            />
          </div>
        </div>
        <div className={clsx(classes.planCardContainer, 'd-flex justify-content-between mt-10')}>
          {monthly.length && (
            <div className={clsx(classes.planCard)}>
              <SubscriptionPlanCard
                isFirstSubscription
                title="Monthly"
                currency={currencySymbol}
                isLimitReached={isLimit}
                subscribeOnPlan={handleSubscribe}
                yearlyPrice={yearlyPrice}
                yearlyPriceWithDiscount={yearlyPrice}
                monthlyPrice={monthlyPrice}
                monthlyPriceWithDiscount={monthlyPrice}
                yearlyProfit={yearlyProfit}
                yearlyPriceByMonth={yearlyPriceByMonth}
                yearlyPriceWithDiscountByMonth={yearlyPriceByMonth}
              />
            </div>
          )}
          {yearly.length && (
            <div className={clsx(classes.planCard, classes.planCardFirst)}>
              <SubscriptionPlanCard
                isFirstSubscription
                isYearlyCard
                title="Yearly"
                currency={currencySymbol}
                isLimitReached={isLimit}
                subscribeOnPlan={handleSubscribe}
                yearlyPrice={yearlyPrice}
                yearlyPriceWithDiscount={yearlyPrice}
                monthlyPrice={monthlyPrice}
                monthlyPriceWithDiscount={monthlyPrice}
                yearlyProfit={yearlyProfit}
                yearlyPriceByMonth={yearlyPriceByMonth}
                yearlyPriceWithDiscountByMonth={yearlyPriceByMonth}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DemoSlider
