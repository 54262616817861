import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// components
import Modal from 'components/ui/Modal'
import Input from 'components/ui/TextInput'
import Button from 'components/ui/Button'
import Dropdown, { DropdownType } from 'components/ui/Dropdown'
import ModalInviteSent from 'pages/shared/modals/ModalAddTeamMember/ModalInviteSent'

// actions
import { inviteTeamMember } from 'state-manager/actions/team-members'

// hooks
import _useToggle from 'hooks/use-toggle'

// helpers
import { hasError } from 'helpers/field-has-errors'

import { ADD_TEAM_MEMBER_MODAL } from 'state-manager/constants'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

const formFields = {
  email: 'email',
  clients: 'clients',
  role: 'role',
}

const AccessLevelOptions = [
  {
    label: 'company admin',
    value: 'company_admin',
  },
  {
    label: 'manager',
    value: 'manager',
  },
  {
    label: 'read only user',
    value: 'read_only_user',
  },
]

const ModalAddTeamMember = () => {
  const [isModalOpen, setIsModalOpen] = _useToggle()
  const dispatch = useAppDispatch()

  const clients = useAppSelector((state) => state.allClients.data)

  const formik = useFormik({
    initialValues: {
      [formFields.email]: '',
      [formFields.role]: '',
      [formFields.clients]: [],
    },

    validationSchema: Yup.object({
      [formFields.email]: Yup
        .string()
        .label('Email')
        .required(),
      [formFields.role]: Yup
        .string()
        .label('Access level')
        .required(),
      [formFields.clients]: Yup
        .array()
        .when(formFields.role, ([role], schema) => (
          role === 'read_only_user' || role === 'manager'
            ? schema.min(1, 'Select at least one item')
            : schema
        )),
    }),

    onSubmit: (values) => {
      const data = {
        ...values,
        clients: values.role === 'company_admin' ? [] : values.clients.map(({ id }) => id)
      }
      dispatch(inviteTeamMember(data))
        .then(() => {
          setIsModalOpen()
        })
    },
  })

  const emailHasError = hasError(formFields.email, formik.touched, formik.errors)
  const roleHasError = hasError(formFields.role, formik.touched, formik.errors)
  const clientsHasError = hasError(formFields.clients, formik.touched, formik.errors)

  const clientsOptions: DropdownType['options'] = clients.map((item) => ({
    id: item.id,
    label: item.name,
    value: item.name,
    color: '#f3f3f3',
  }))

  const handleCloseModal = () => {
    dispatch({
      type: ADD_TEAM_MEMBER_MODAL.HIDE,
    })
  }

  return (
    <>
      {isModalOpen && (
        <ModalInviteSent onClose={handleCloseModal} />
      )}
      <Modal
        withCloseButton
        onClose={handleCloseModal}
        title="Add Team Member">
        <div className="form-page">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-3">
              <Input
                label="Email"
                name="email"
                value={formik.values[formFields.email]}
                errorMsg={emailHasError && formik.errors[formFields.email]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Email"
                dataCy="Add team member - Email input"
              />
              <br />
              <Dropdown
                label="Access Level"
                placeholder="Access Level"
                errorMsg={roleHasError && formik.errors[formFields.role]}
                options={AccessLevelOptions}
                field={{
                  value: formik.values[formFields.role],
                  name: formFields.role,
                  onBlur: formik.handleBlur,
                  onChange: (selectedOption) => {
                    if (selectedOption.value === 'company_admin') {
                      formik.setFieldValue(formFields.clients, [], false)
                    }

                    const selectedVal = selectedOption.value.toString()
                    formik.handleChange(formFields.role)(selectedVal)
                  },
                }}
                dataCy="Add team member - access dropdown"
              />
              <br />
              {formik.values[formFields.role] !== 'company_admin' && (
                <Dropdown
                  menuListMinimumHeight
                  label="Clients"
                  multiselect
                  teamMemberDropdown
                  selectAllOption
                  placeholder="Clients"
                  errorMsg={clientsHasError && formik.errors[formFields.clients]}
                  options={clientsOptions}
                  field={{
                    value: formik.values[formFields.clients],
                    name: formFields.clients,
                    onBlur: formik.handleBlur,
                    onChange: (selectedOption) => {
                      if (!selectedOption) {
                        formik.setFieldValue(formFields.clients, [], true)
                        return null
                      }

                      formik.setFieldValue(formFields.clients, selectedOption, true)
                    },
                  }}
                  dataCy="Add team member - clients dropdown"
                />
              )}
            </div>
            <Button
              type="submit"
              title="Send Invite"
              dataCy="Send invite"
            />
          </form>
        </div>
      </Modal>
    </>
  )
}

export default ModalAddTeamMember
