import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { FormikProps } from 'formik'

// components
import InputWrapper from 'components/ui/InputWrapper'

// styles
import 'react-phone-input-2/lib/style.css'

type TelInputType = {
  name: string;
  value: string;
  label?: string;
  country?: string;
  placeholder?: string;
  optional?: boolean;
  errorMsg?: string | boolean;
  onChange?: (phoneNumber: string) => void;
  setFieldValue: FormikProps<any>['setFieldValue'];
  onBlur?: () => void;
  inputCy: string;
}

const inputStyle = { height: '40px', lineHeight: '40px', border: '1px solid #E8E8E8', borderRadius: '4px', width: '100%' }
const buttonStyle = { background: 'transparent', borderRight: 'none' }

const TelInput: React.FC<TelInputType> = ({
  value,
  name,
  setFieldValue,
  onBlur,
  country = '1',
  label,
  optional,
  errorMsg,
  onChange,
  placeholder,
  inputCy,
}) => {
  return (
    <InputWrapper
      label={label}
      optional={optional}
      dataCy={`wrapper-${inputCy}`}
      errorMsg={errorMsg}>
      <PhoneInput
        preferredCountries={['gb', 'us']}
        inputProps={{ name, 'data-cy': inputCy }}
        country={country.toString()}
        autocompleteSearch
        inputClass="input"
        inputStyle={inputStyle}
        buttonStyle={buttonStyle}
        placeholder={placeholder}
        autoFormat={false}
        value={value}
        onChange={(phoneNumber, country) => {
          if (country.dialCode) {
            setFieldValue(name, phoneNumber, true)
            setFieldValue('countryId', parseInt(country.dialCode))

            if (onChange) {
              onChange(phoneNumber)
            }
          }
        }}
        onBlur={onBlur}
      />
    </InputWrapper>
  )
}

export default TelInput
