import React, { useRef, useState } from 'react'

// styles
import classes from './VerifyCodeInput.module.scss'

const CODE_LENGTH = new Array(6).fill(0)

type verifyCodeInputType = {
  onChange: (value: string) => void
}

const VerifyCodeInput: React.FC<verifyCodeInputType> = ({ onChange }) => {
  const [value, setValue] = useState('')
  const [focused, setFocused] = useState(false)
  const input = useRef<HTMLInputElement>()

  const handleClick = () => {
    input.current?.focus()
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    setFocused(false)
  }

  const values = value.split('')

  const selectedIndex =
    values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1

  const hideInput = !(values.length < CODE_LENGTH.length)

  return (
    <div className="d-flex justify-content-center" onClick={handleClick} role="button">
      <input
        value=""
        ref={input}
        onChange={(e) => {
          const inputValue = e.target.value

          const finalValue = (value + inputValue).slice(0, CODE_LENGTH.length)

          if (finalValue.length > CODE_LENGTH.length) {
            return null
          }

          setValue(finalValue)
          onChange(finalValue)
        }}
        onKeyUp={(e) => {
          if (e.key === 'Backspace') {
            const finalValue = value.slice(0, value.length - 1)

            setValue(finalValue)

            onChange(finalValue)
          }
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={classes.input}
        style={{
          left: `${selectedIndex * 32}px`,
          opacity: hideInput ? 0 : 1,
        }}
      />
      {CODE_LENGTH.map((v, index) => {
        const selected = values.length === index
        const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1

        return (
          <div className={classes.display}>
            {values[index]}
            {(selected || filled) && focused && <div className={classes.shadows} />}
          </div>
        )
      })}
    </div>
  )
}

export default VerifyCodeInput
