import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { DropdownIndicatorProps, StylesConfig, components } from 'react-select'
import {FormikHandlers} from "formik/dist/types"

// components
import InputWrapper from 'components/ui/InputWrapper'
import SpriteIcon from 'components/ui/SpriteIcon'

// styles
import classes from 'components/ui/Dropdown/Dropdown.module.scss'

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <SpriteIcon name="iconDown" />
  </components.DropdownIndicator>
)

const customOption = ({
  label = '',
  customAbbreviation = '',
}) => (
  <div className="d-flex">
    <div>{label}</div>
    <div className={classes.abbreviation}>
      {customAbbreviation}
    </div>
  </div>
)

type optionType = {
  label: string;
  value: string | number;
  color?: '#f3f3f3';
}

export type DropdownType = {
  field: {
    value: any,
    name: string,
    onChange: (selected: Array<optionType>) => void,
    onBlur: FormikHandlers['handleBlur']
  };
  labelsMultiselect?: boolean;
  hiddenLabel?: boolean;
  small?: boolean;
  label?: string;
  placeholder?: string;
  errorMsg?: string | boolean;
  multiselect?: boolean;
  options: Array<optionType>
  customOptions?: typeof customOption;
  disabled?: boolean;
  searchable?: boolean;
  wrapperClassNames?: string;
  teamMemberDropdown?: boolean;
  menuListMinimumHeight?: boolean;
  selectAllOption?: boolean;
  dataCy: string;
}

const allOption = { label: 'Select all', value: '*', color: '#f3f3f3' }

const Dropdown: React.FC<DropdownType> = ({
  field,
  labelsMultiselect,
  hiddenLabel = false,
  small,
  label,
  placeholder,
  errorMsg,
  multiselect = false,
  options = [],
  customOptions = customOption,
  disabled,
  searchable,
  wrapperClassNames,
  teamMemberDropdown,
  menuListMinimumHeight,
  selectAllOption = false,
  dataCy,
}) => {
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#d6d6d6',
    }),
    control: (base) => ({
      ...base,
      minHeight: 'none',
      border: '1px solid #e8e8e8',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #e8e8e8',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: !multiselect && (small ? 30 : 38),
      paddingTop: small ? 2 : 7,
      paddingBottom: 7,
      paddingLeft: small ? 8 : 12,
      paddingRight: small ? 8 : 12,
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: small ? 14 : 16,
      color: '#353333',
      width: '100%',
    }),
    option: (base) => ({
      ...base,
      fontSize: small ? 14 : 16,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      marginRight: 12,
      width: 7,
      height: 4,
    }),
    multiValue: (base, { data }) => ({
      ...base,
      marginRight: 8,
      marginTop: 0,
      backgroundColor: data.color,
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: 14,
      color: teamMemberDropdown ? '#000' : '#fff',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: teamMemberDropdown ? '#3DB14A' : '#fff',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#fff',
      },
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: !menuListMinimumHeight ? '165px' : '100px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1010,
    })
  }

  let value: optionType | Array<optionType> = field.value && options.length && options.find((option) => option.value === field.value)

  if (labelsMultiselect) {
    value = field.value && options.length && options.filter((option) => field.value.includes(option.value))
  }

  if (teamMemberDropdown) {
    value = field.value && options.length && options.filter((option) => {
      if (field.value.some((item) => item.value === option.value)) {
        return option
      }
    })
  }

  return (
    <InputWrapper
      hiddenLabel={hiddenLabel}
      label={label}
      errorMsg={errorMsg}
      dataCy={dataCy}
      classNames={wrapperClassNames}>
      <CreatableSelect
        placeholder={placeholder}
        styles={customStyles}
        isMulti={multiselect}
        isValidNewOption={(inputValue, selectValue, selectOptions) => multiselect && Boolean(inputValue) && !selectOptions.find((item) => item.label === inputValue)}
        isDisabled={disabled}
        isSearchable={searchable}
        options={(selectAllOption && field.value.length < options.length) ? [allOption, ...options] : options}
        value={value}
        formatOptionLabel={customOptions}
        name={field.name}
        onChange={(selected: Array<optionType>) => {
          if (selected !== null && selected.length > 0 && selected.some((item) => item.value === allOption.value)) {
            return field.onChange(options)
          }
          return field.onChange(selected)
        }}
        onBlur={field.onBlur}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
      />
    </InputWrapper>
  )
}

export default Dropdown
