import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// components
import Modal from 'components/ui/Modal'
import TextInput from 'components/ui/TextInput'
import Button from 'components/ui/Button'

// actions
import { applyCoupon } from 'state-manager/actions/coupon'

import { hasError } from 'helpers/field-has-errors'
import { useAppDispatch } from 'state-manager/store'

type ModalCouponType = {
  onClose: () => void
}

const ModalCoupon: React.FC<ModalCouponType> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      coupon: '',
    },

    validationSchema: Yup.object().shape({
      coupon: Yup.string().required(),
    }),

    onSubmit: ({ coupon }) => {
      dispatch(applyCoupon(coupon))
        .then(onClose)
    },
  })

  const nameHasError = hasError('coupon', formik.touched, formik.errors)

  return (
    <Modal
      size="sm"
      withCloseButton
      onClose={onClose}>
      <div className="text-center fw-semibold fs-lg mb-4">Enter a Coupon</div>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          dataCy='coupon input'
          name="coupon"
          value={formik.values.coupon}
          onChange={formik.handleChange}
          label="Coupon"
          errorMsg={nameHasError && formik.errors.coupon}
          onBlur={formik.handleBlur}
        />
        <Button
          type="submit"
          dataCy='apply coupon'
          title="Apply"
          fullWidth
          className="mt-2"
        />
      </form>
      <div
        className="mt-2 text-center fw-semibold fs-18 color-green"
        onClick={onClose}
        role="button">
        Cancel
      </div>
    </Modal>
  )
}

export default ModalCoupon
