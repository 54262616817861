import React, { useState } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputNumber from 'rc-input-number'

import { useAppDispatch } from 'state-manager/store'

// components
import Modal from 'components/ui/Modal'
import Tabs from 'components/ui/Tabs'
import Button from 'components/ui/Button'

// actions
import { addNotification } from 'state-manager/actions/notifications'

// helpers
import { hasError } from 'helpers/field-has-errors'

// constants
import { LESS_THAN, MORE_THAN } from 'constants/comparison'
import { PERCENT } from 'constants/regex'
import { formFields } from 'pages/Notifications/modalAddNotification/constants'

// styles
import classes from 'pages/Notifications/modalAddNotification/modalAddNotification.module.scss'

const TabsOptions = [
  {
    id: 0,
    label: '<',
  },
  {
    id: 1,
    label: '>',
  },
]

type ModalAddNotificationType = {
  onClose: () => void
}

const ModalAddNotification: React.FC<ModalAddNotificationType> = ({
  onClose,
}) => {
  const [tab, setTab] = useState(0)

  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      [formFields.pacing]: 0,
      [formFields.comparison]: LESS_THAN,
    },

    validationSchema: Yup.object({
      [formFields.pacing]: Yup.number()
        .label('Pacing')
        .min(1, 'Minimum is 1')
        .max(100, 'Maximum is 100')
        .required(),
    }),

    onSubmit: (values) => {
      dispatch(addNotification(values))
        .then(onClose)
    },
  })

  const pacingHasError = hasError(formFields.pacing, formik.touched, formik.errors)

  const handlePacing: React.MouseEventHandler<HTMLDivElement> = (pacingVal) => {
    formik.handleChange(formFields.pacing)(pacingVal)
  }

  const handleComparison = (val: number) => {
    setTab(val)
    formik.handleChange(formFields.comparison)(tab ? LESS_THAN : MORE_THAN)
  }

  return (
    <Modal
      withCloseButton
      title="Add Notification"
      onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <div className="row align-items-start mb-1 flex-column-sm align-items-center-sm">
          <span
            className="font-meidum fs-20 align-self-center mb-sm-2">
            Budget pacing
          </span>
          <div onClick={handlePacing} className="mb-sm-2" role="button">
            <Tabs
              value={tab}
              items={TabsOptions}
              className={classes.tabs}
              onSelect={handleComparison}
            />
          </div>
          <div className="position-relative">
            <InputNumber
              data-cy="budget pacing input"
              className={classes.reduceSpendInput}
              label=""
              onBlur={formik.handleBlur}
              onChange={(val) => formik.setFieldValue(formFields.pacing, val, true)}
              formatter={(val) => {
                if (val) {
                  return parseInt(val.replace(PERCENT, '').toString().substring(0, 3))
                }
                return ''
              }}
              parser={(val) => val?.replace(PERCENT, '').toString().substring(0, 3)}
              name={formFields.pacing}
              value={formik.values[formFields.pacing]}
              errorMsg={pacingHasError && formik.errors[formFields.pacing]}
            />
            {pacingHasError && (
              <div
                className={clsx(classes.reduceSpendError, 'text-right fw-medium fs-sm color-red mt-1')}>
                {formik.errors[formFields.pacing]}
              </div>
            )}
            <span className={clsx(classes.reduceSpendAddon, 'fw-regular')}>
              %
            </span>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <Button title="Add Notification" type="submit" dataCy="submit add notification" />
        </div>
      </form>
    </Modal>
  )
}

export default ModalAddNotification
