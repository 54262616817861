export const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  companyName: 'companyName',
  email: 'email',
  password: 'password',
  phoneNumber: 'phoneNumber',
  terms: 'terms',
  countryId: 'countryId',
  confirmPassword: 'confirmPassword',
}
