import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ENV, SENTRY_DSN } from 'constants/main'

if (ENV !== 'local') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.02,
    environment: ENV,
    beforeSend(event) {
      if (event?.exception?.values?.[0]?.type === 'AxiosError') {
        return null
      }
      return event
    },
  })
}
export default Sentry
