import React, { useState } from 'react'
import noScroll from 'no-scroll'
import Loader from 'react-loaders'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'
import _useEventListener from 'hooks/use-event-listener'

/* constants */
import { TOGGLE__VISIBILITY__SPINNER } from 'constants/custom-events'
import { SHOW, HIDE } from 'constants/visibility-actions'

/* styles */
import 'components/GlobalSpinner/styles.scss'

const GlobalSpinner = () => {
  const [visible, setVisible] = useState(false)

  const _hideSpinner = () => {
    noScroll.off()

    setVisible(false)
  }

  const _showSpinner = () => {
    noScroll.on()

    setVisible(true)
  }

  const _toggleSpinnerVisibility = (action: string) => {
    switch (action) {
      case SHOW:
        _showSpinner()
        break
      case HIDE:
        _hideSpinner()
        break
      default:
        break
    }
  }

  _useEventListener(window, 'beforeunload', _showSpinner)

  _useCustomEventListener(
    TOGGLE__VISIBILITY__SPINNER,
    (e) => {
      const action = e.detail?.action
      _toggleSpinnerVisibility(action)
    },
  )

  if (!visible) {
    return null
  }

  return (
    <div className="global-spinner">
      <Loader
        type="ball-pulse-sync"
        active={visible}
      />
    </div>
  )
}

export default GlobalSpinner
