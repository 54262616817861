import React from 'react'
import { useDispatch } from 'react-redux'

// actions
import { verifyEmail } from 'state-manager/actions/auth'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

const ConfirmEmailVerification: React.FC = () => {
  const dispatch = useDispatch()

  _useDidMount(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    const hash = urlParams.get('hash')
    if (id && hash) {
      dispatch(verifyEmail(id, hash))
    }
  })

  return (
    <h1 className="fw-regular text-center">
      Confirm email verification
    </h1>
  )
}

export default ConfirmEmailVerification
