import React from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'

// components
import SpriteIcon from 'components/ui/SpriteIcon'
import Input from 'components/ui/TextInput'
import CountrySelect from 'pages/Billing/CountrySelect'
import CardForm from 'pages/Billing/card-form'

// actions
import { addPaymentMethod } from 'state-manager/actions/subscriptions'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// helpers
import { hasError } from 'helpers/field-has-errors'

// constants
import { formFields } from 'pages/Billing/constants'

// routes
import routes from 'routes'

// styles
import classes from 'pages/Billing/Billing.module.scss'
import { useAppSelector } from 'state-manager/store'
import { fetchBillingInfo } from 'state-manager/reducers/billing-info'

const Billing = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const billingInfo = useAppSelector((state) => state.billingInfo.data)

  _useDidMount(() => {
    if (!billingInfo) {
      dispatch(fetchBillingInfo())
    }
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      address_line1: '',
      address_city: '',
      address_state: '',
      postcode: '',
      address_country: 'US',
    },

    validationSchema: Yup.object({
      name: Yup
        .string()
        .label('Cardholder')
        .required(),
      address_line1: Yup
        .string()
        .label('Address')
        .required(),
      address_city: Yup
        .string()
        .label('City')
        .required(),
      address_state: Yup
        .string(),
      postcode: Yup
        .string()
        .label('Postal code')
        .required(),
      address_country: Yup
        .string()
        .label('Country')
        .required(),
    }),
  })

  const nameHasError = hasError('name', formik.touched, formik.errors)
  const addressLine1HasError = hasError('address_line1', formik.touched, formik.errors)
  const addressCityHasError = hasError('address_city', formik.touched, formik.errors)
  const addressStateHasError = hasError('address_state', formik.touched, formik.errors)
  const addressCountryHasError = hasError('address_country', formik.touched, formik.errors)
  const postalCodeHasError = hasError('postcode', formik.touched, formik.errors)

  const isSubmitDisabled = !formik.isValid || !formik.dirty

  const billingDetails = {
    name: formik.values.name,
    address: {
      line1: formik.values.address_line1,
      city: formik.values.address_city,
      country: formik.values.address_country,
      state: formik.values.address_state,
      postal_code: formik.values.postcode,
    },
  }

  return (
    <>
      <div className={classes.closeIconWrapper}>
        <SpriteIcon
          name="back"
          size="md"
          onClick={() => (history.length > 2 ? history.goBack() : history.push(formatRoute(routes.subscriptions)))}
        />
      </div>
      <div className="d-flex justify-content-center text-center mt-13">
        <div className="container text-center">
          <h1 className="fw-semibold fs-lg mb-3">Billing Information</h1>
          {billingInfo && (
            <p className={clsx(classes.caption, 'fw-medium mb-5 m-auto')}>Billing will start after the free trial (30 days) is over but you will receive an email reminder 3 days before this happens.</p>
          )}
          <div className="d-flex justify-content-center flex-column-sm-up align-items-center-sm-up" style={{ marginBottom: 185 }}>
            <div className={classes.leftSide}>
              <Input
                label="Cardholder name"
                name={formFields.name}
                value={formik.values.name}
                errorMsg={nameHasError && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Cardholder name"
                dataCy="cardholder input"
              />
              <br />
              <Input
                label="Address"
                name={formFields.address_line1}
                value={formik.values.address_line1}
                errorMsg={addressLine1HasError && formik.errors.address_line1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Address"
                dataCy="address input"
              />
              <br />
              <Input
                label="City"
                name={formFields.address_city}
                value={formik.values.address_city}
                errorMsg={addressCityHasError && formik.errors.address_city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="City"
                dataCy="city input"
              />
              <br />
              <div className="d-flex justify-content-between align-center">
                <Input
                  optional
                  label="Region / State"
                  name={formFields.address_state}
                  value={formik.values.address_state}
                  errorMsg={addressStateHasError && formik.errors.address_state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Region/state"
                  dataCy="region input"
                />
                <div className="divider" />
                <Input
                  label="Postcode / ZIP Code"
                  name={'postcode'}
                  value={formik.values.postcode}
                  errorMsg={postalCodeHasError && formik.errors.postcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Postcode"
                  dataCy="postcode input"
                />
              </div>
              <br />
              <CountrySelect
                label="Country"
                errorMsg={addressCountryHasError && formik.errors.address_country}
                name={formFields.address_country}
                onSelect={(val) => formik.setFieldValue(formFields.address_country, val, false)}
                placeholder="country"
                selected={formik.values.address_country}
              />
            </div>
            <div className={classes.rightSide}>
              <CardForm
                addPaymentMethod={(paymentData, location) => dispatch(addPaymentMethod(paymentData, location))}
                disabled={isSubmitDisabled}
                billingInfo={billingDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Billing
