import React, { useState } from 'react'

// components
import Modal from 'components/ui/Modal'
import Checkbox from 'components/ui/Checkbox'
import Button from 'components/ui/Button'

// styles
import classes from 'components/ui/Table/ModalCustomize/ModalCustomize.module.scss'

type ModalCustomizeType = {
  columns: Array<unknown>;
  customizeColumns: (columns: unknown) => void;
  onClose: () => void;
}

const ModalCustomize: React.FC<ModalCustomizeType> = ({
  columns,
  customizeColumns,
  onClose,
}) => {
  const [finalColumns, setFinalColumns] = useState(columns)
  const handleColumnChange = (item) => {
    const changedColumnIdx = finalColumns.findIndex(({ name }) => name === item.name)
    if (changedColumnIdx === -1) {
      return
    }
    const newValue = [
      ...finalColumns.slice(0, changedColumnIdx),
      {
        ...finalColumns[changedColumnIdx],
        visible: !finalColumns[changedColumnIdx].visible,
      },
      ...finalColumns.slice(changedColumnIdx + 1),
    ]
    setFinalColumns(newValue)
  }

  const handleCustomize = () => {
    customizeColumns(finalColumns)
    onClose()
  }

  return (
    <Modal
      title="Customize Table"
      onClose={onClose}>
      <div className={classes.modalCustomizeContainer}>
        <div className={classes.itemsContainer}>
          {finalColumns.map((item, index) => (
            <div key={index} className="d-flex mb-2 align-items-center">
              <Checkbox onChange={() => handleColumnChange(item)} checked={Boolean(item.visible)} dataCy={`check ${item.Header}`} />
              <span className="fw-regular fs-main">{item.Header}</span>
            </div>
          ))}
        </div>
        <Button title="Customize" onClick={handleCustomize} dataCy="Save customize settings" />
      </div>
    </Modal>
  )
}

export default ModalCustomize
