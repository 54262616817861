import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { AUTH_ACTIONS, USER_ACTIONS } from 'state-manager/constants'

export interface IUserData {
  id: null | number,
  isAuthorized: boolean,
  authorizationData: {
    token_type: string,
    access_token: string,
  },
  '2fa': boolean,
  techSupport: boolean,
  avatar: {
    id: number
    userId: number
    type: string
    hash: string
  },
  clients: Array<unknown>,
  company: {
    id: number,
    name: string,
    dataSources: Array<number>,
  },
  countryId: number,
  phoneNumber: number,
  email: string,
  firstName: string,
  lastName: string,
  guideSkipped: boolean,
  hasVerifiedEmail: number,
  responseWaiting: boolean,
  permissions: {
    canEditCompanyName: boolean,
    canInviteUsers: boolean,
    canManageBudgets: boolean,
    canManageClients: boolean,
    canManageDataSources: boolean,
    canManageSubscriptions: boolean,
    canSeeChangesHistory: boolean,
    canSeePreviousInvoices: boolean,
    canSetUpNotifications: boolean,
  },
  createdAt: null | string,
}

const initialState: IUserData = {
  id: null,
  isAuthorized: false,
  authorizationData: {
    token_type: '',
    access_token: '',
  },
  '2fa': false,
  techSupport: false,
  avatar: '',
  clients: [],
  company: {
    name: '',
    dataSources: [],
    id: null,
  },
  permissions: {
    canEditCompanyName: false,
    canInviteUsers: false,
    canManageBudgets: false,
    canManageClients: false,
    canManageDataSources: false,
    canManageSubscriptions: false,
    canSeeChangesHistory: false,
    canSeePreviousInvoices: false,
    canSetUpNotifications: false,
  },
  countryId: null,
  phoneNumber: null,
  email: '',
  firstName: '',
  lastName: '',
  guideSkipped: true,
  hasVerifiedEmail: null,
  responseWaiting: false,
  createdAt: null,
}

export const types = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([null]),
  ]),
  isAuthorized: PropTypes.bool,
  authorizationData: PropTypes.shape({
    token_type: PropTypes.string.isRequired,
    access_token: PropTypes.string.isRequired,
  }),
  '2fa': PropTypes.bool,
  techSupport: PropTypes.number,
  avatar: PropTypes.shape({
    id: PropTypes.number,
    userId: PropTypes.number,
    type: PropTypes.string,
    hash: PropTypes.string,
  }),
  clients: PropTypes.arrayOf(PropTypes.object),
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    dataSources: PropTypes.arrayOf(PropTypes.number),
  }),
  countryId: PropTypes.number,
  phoneNumber: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  guideSkipped: PropTypes.bool,
  hasVerifiedEmail: PropTypes.number,
  permissions: PropTypes.shape({
    canEditCompanyName: PropTypes.bool,
    canInviteUsers: PropTypes.bool,
    canManageBudgets: PropTypes.bool,
    canManageClients: PropTypes.bool,
    canManageDataSources: PropTypes.bool,
    canManageSubscriptions: PropTypes.bool,
    canSeeChangesHistory: PropTypes.bool,
    canSeePreviousInvoices: PropTypes.bool,
    canSetUpNotifications: PropTypes.bool,
  }),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTH_ACTIONS.SIGN_IN.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case AUTH_ACTIONS.SIGN_IN.SUCCESS:
      return updateObject(state, {
        isAuthorized: action.data.isAuthorized,
        authorizationData: action.data.authorizationData,
        responseWaiting: false,
      })

    case AUTH_ACTIONS.SIGN_IN.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case AUTH_ACTIONS.SIGN_OUT.SYSTEM:
      return updateObject(state, initialState)

    case USER_ACTIONS.USER_GET_DATA.SUCCESS:
      return updateObject(state, {
        id: action.data.id,
        '2fa': action.data['2fa'],
        techSupport: action.data.supportEnabled,
        avatar: action.data.avatar,
        clients: action.data.clients,
        company: {
          ...action.data.company,
          name: action.data.company.name || 'Company Name',
        },
        permissions: action.data.permissions,
        countryId: action.data.countryId,
        phoneNumber: action.data.phoneNumber,
        email: action.data.email,
        firstName: action.data.firstName || 'First Name',
        lastName: action.data.lastName || 'Last Name',
        guideSkipped: action.data.guideSkipped,
        hasVerifiedEmail: action.data.hasVerifiedEmail,
        createdAt: action.data.createdAt,
      })
    default:
      return state
  }
}
