import React from 'react'
import { useDispatch } from 'react-redux'

// assets
import WarningIcon from 'assets/icons/warning.svg'
import SuccessIcon from 'assets/icons/success.svg'

// components
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'

import { useAppSelector } from 'state-manager/store'
import { hideGlobalModal } from 'state-manager/actions/global-modal'

const GlobalModal = () => {
  const globalModalState = useAppSelector((state) => state.globalModal)

  const dispatch = useDispatch()

  if (!globalModalState.isVisible) {
    return null
  }

  const handlePressButton = () => {
    if (globalModalState.btnHandler) {
      globalModalState.btnHandler()
    }
    dispatch(hideGlobalModal())
  }

  return (
    <Modal
      withCloseButton
      onClose={() => dispatch(hideGlobalModal())}
      title={globalModalState.title}>
      <img
        className="align-center"
        src={globalModalState.type === 'error' ? WarningIcon : SuccessIcon}
        alt="icon"
      />
      <p className="text-center fw-regular fs-lg mt-2 mb-2">
        {globalModalState.header}
      </p>
      <p className="text-center fw-regular mb-3">{globalModalState.description}</p>
      <Button
        type="submit"
        title={globalModalState.btnText}
        className="background-red mt-2"
        onClick={handlePressButton}
        dataCy="global modal submit"
      />
    </Modal>
  )
}

export default GlobalModal
