// utils
import reqH from 'utils/request-handler'

// constants
import { COUPON_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'
import notifications from "utils/notifications"

export type couponInfoResponseType = {
  result: {
    message: string | null
    data: {
      id: string
      percentOff: number
      valid: boolean // we can ignore this field
      promotionCode: string
    }
  }
}

export const getUserCoupon = () => (dispatch: AppDispatch) =>
  reqH<couponInfoResponseType>({
    method: 'GET',
    url: 'users/current/coupons',
    skipNotifications: true,
  }).then(({ data }) =>
    dispatch({ type: COUPON_ACTIONS.COUPON_GET_INFO.SUCCESS, data: data.result.data })
  )

export const applyCoupon = (promotionCode: string) => (dispatch: AppDispatch) =>
  reqH<couponInfoResponseType>({
    method: 'POST',
    url: `users/current/coupons/${promotionCode}/apply`
  }).then(({ data }) => {
    dispatch({ type: COUPON_ACTIONS.COUPON_GET_INFO.SUCCESS, data: data.result.data })
    notifications.success('The coupon has been successfully applied')
  })

export const swapCoupon = (promotionCode: string) => (dispatch: AppDispatch) =>
  reqH<couponInfoResponseType>({
    method: 'POST',
    url: `users/current/coupons/${promotionCode}/swap`
  }).then(({ data }) => {
    dispatch({ type: COUPON_ACTIONS.COUPON_GET_INFO.SUCCESS, data: data.result.data })
    notifications.success('New coupon has been successfully activated')
  })

export const removeCurrentCoupon = () => (dispatch: AppDispatch) =>
  reqH({
    method: 'DELETE',
    url: `users/current/coupons`
  }).then(() => {
    dispatch({ type: COUPON_ACTIONS.COUPON_REMOVE.SUCCESS })
    notifications.success('The coupon has been successfully removed')
  })
