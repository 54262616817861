import { NUMBER_FORMATTER } from "constants/regex";

export function formatNumber(inputNumber: number) {
  if (isNaN(inputNumber)) {
    console.error("Number formating error. Value: ", inputNumber);
    return;
  }

  const [integerPart, decimalPart] = inputNumber.toString().split(".");

  // Форматування цілої частини числа
  const formattedInteger = integerPart.replace(NUMBER_FORMATTER, ",");

  // Форматування десяткової частини числа
  const formattedDecimal = decimalPart === undefined
    ? "00"
    : decimalPart.length === 1
    ? decimalPart + "0"
    : decimalPart.slice(0, 2);

  return formattedInteger + "." + formattedDecimal;
}
