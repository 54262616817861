import React from 'react'
import clsx from 'clsx'
import InputNumber from 'rc-input-number'

// components
import InputWrapper from 'components/ui/InputWrapper'

// constants
import { NUMBER, NUMBER_FORMATTER } from 'constants/regex'
import { FormikHandlers } from "formik/dist/types"

type propsType = {
  label?: string;
  optional?: boolean;
  placeholder?: string;
  errorMsg?: string | boolean;
  name?: string;
  value?: number | string;
  onChange?: (value: any) => void;
  onBlur?: FormikHandlers['handleBlur']
  addon?: string | JSX.Element;
  className?: string;
  maxLength?: number;
  dataCy: string;
  formatter?: (value: string | undefined) => string;
}

const NumberInput: React.FC<propsType> = ({
  label,
  optional,
  placeholder,
  errorMsg,
  name,
  value = 0,
  onChange,
  onBlur,
  addon = null,
  className = '',
  maxLength = null,
  dataCy,
  formatter,
}) => {
  // Specifies the format of the value presented
  const defaultFormatter = (value: string | undefined) => {
    if (!value) {
      return ''
    }
    const formatted = value.replace(NUMBER, '').replace(NUMBER_FORMATTER, '$&,')
    if (maxLength) {
      return formatted.substring(0, maxLength)
    }
    return formatted
  }

  // Specifies the value extracted from formatter
  const defaultParser = (displayValue?: string) => {
    if (!displayValue) {
      return ''
    }
    const formatted = displayValue.replace(NUMBER, '')
    if (maxLength) {
      return formatted.substring(0, maxLength)
    }
    return formatted
  }

  return (
    <InputWrapper
      label={label}
      optional={optional}
      errorMsg={errorMsg}>
      <div className="position-relative">
        <InputNumber
          data-cy={dataCy}
          className={clsx(
            'input',
            errorMsg && 'input-invalid',
            className,
          )}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={`${value}`}
          name={name}
          formatter={formatter ?? defaultFormatter}
          parser={defaultParser}
        />
        {addon && (
          <span className="fw-regular input-addon">
            {addon}
          </span>
        )}
      </div>
    </InputWrapper>
  )
}

export default NumberInput
