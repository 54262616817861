import { useCallback, useState } from 'react'

type useToggleType = (initialState?: boolean) => [boolean, () => void]

const _useToggle: useToggleType = (initialState = false) => {
  const [state, setState] = useState(initialState)
  const toggle = useCallback(() => setState((state) => !state), [])

  return [state, toggle]
}

export default _useToggle
