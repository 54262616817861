import React from 'react'

// constants
import { ACTIVE, PAUSED } from 'constants/status'

type formDataType = {
  status: string;
}

type propsType = {
  original: {
    status: string;
    id: string;
  };
  updateCampaignStatus: (id: string, formData: formDataType) => void;
}

const CellStatusSelect: React.FC<propsType> = ({
  original,
  updateCampaignStatus,
}) => {
  const { status, id } = original

  const handleStatusChange = (val: string) => {
    const formData: formDataType = {
      status: val,
    }

    updateCampaignStatus(id, formData)
  }

  const options = [
    ACTIVE,
    PAUSED,
  ]

  return (
    <select
      onChange={(e) => handleStatusChange(e.target.value)}
      value={status}
      className="form-control">
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default CellStatusSelect
