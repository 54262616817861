import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { CLIENT_ACTIONS } from 'state-manager/constants'

// prop-types
import { types as allBudgetsTypes, budgetInfoType } from 'state-manager/reducers/all-budgets'

export interface ISingleClient {
  data: {
    id: number,
    status: string,
    name: string,
    currencyId: number,
    budgetInfo: budgetInfoType,
  } | {},
  responseWaiting: boolean,
  timeLastUpdate: string | null,
}

const initialState: ISingleClient = {
  data: {},
  responseWaiting: false,
  timeLastUpdate: null,
}

export const types = {
  data: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    currencyId: PropTypes.number,
    budgetInfo: allBudgetsTypes.data,
  }),
  responseWaiting: PropTypes.bool,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLIENT_ACTIONS.CLIENT_GET_SINGLE.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CLIENT_ACTIONS.CLIENT_GET_SINGLE.SUCCESS:
      return updateObject(state, {
        data: action.data,
        responseWaiting: false,
      })

    case CLIENT_ACTIONS.CLIENT_GET_SINGLE.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CLIENT_ACTIONS.CLIENT_DELETE_SINGLE.SYSTEM:
      return initialState

    default:
      return state
  }
}
