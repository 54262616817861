import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { MORE_THAN, LESS_THAN, DEACTIVATED } from 'constants/comparison'
import { NOTIFICATION_ACTIONS } from 'state-manager/constants'

export interface INotifications {
  data: Array<{
    id: number,
    type: string,
    comparison: typeof MORE_THAN | typeof LESS_THAN | typeof DEACTIVATED,
    emailEnabled: 0 | 1,
    platformEnabled: 0 | 1,
    pacing: number,
  }>
}

const initialState: INotifications = {
  data: [],
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    comparison: PropTypes.oneOf([MORE_THAN, LESS_THAN, DEACTIVATED]),
    emailEnabled: PropTypes.oneOf([0, 1]),
    platformEnabled: PropTypes.oneOf([0, 1]),
    pacing: PropTypes.number,
  })),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.NOTIFICATION_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
      })

    default:
      return state
  }
}
