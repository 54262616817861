import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'state-manager/store'

// images
import closeIcon from 'assets/icons/closeWhite.svg'

import { isValidDate } from 'utils/isInvalidDate'

// styles
import classes from 'components/TrialNotification/TrialNotification.module.scss'

// routes
import routes from 'routes'

const getDateDifferenceByNow = (date: Date) => {
  if (!isValidDate(date)) {
    return ''
  }

  const millisecondsPerHour = 3600000
  const millisecondsPerDay = millisecondsPerHour * 24

  // @ts-expect-error we can do math with Date objects
  const dateDifference = Math.abs(date - new Date())

  const days = Math.floor(dateDifference / millisecondsPerDay)
  const remainMilliseconds = dateDifference - days * millisecondsPerDay
  const hours = Math.floor(remainMilliseconds / millisecondsPerHour)

  let daysStrPart
  if (days === 1) {
    daysStrPart = '1 day'
  } else if (days > 1) {
    daysStrPart = `${days} days`
  }

  let hoursStrPart
  if (hours === 1) {
    hoursStrPart = '1 hour'
  } else if (hours > 1) {
    hoursStrPart = `${hours} hours`
  }

  if (daysStrPart && hoursStrPart) {
    return `${daysStrPart} and ${hoursStrPart}`
  } if (!daysStrPart && hours === 0) {
    return 'less than 1 hour'
  }
  return daysStrPart || hoursStrPart
}

const TrialNotification = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [renewalDate, setRenewalDate] = useState<Date | null>(null)

  const billingInfo = useAppSelector((store) => store.billingInfo.data)
  const { canManageSubscriptions } = useAppSelector((state) => state.userData.permissions)
  const { isOnTrial, renewsAt: renewalDateStr, isSubscriptionScheduledToCancel } = useAppSelector((state) => state.subscriptions.data)

  useEffect(() => {
    if (isValidDate(renewalDateStr)) {
      setRenewalDate(new Date(renewalDateStr))
    }
  }, [renewalDateStr])

  if (!isOnTrial || !renewalDate || !canManageSubscriptions || !isVisible) {
    return null
  }

  const hasUserEnteredBillingInfo = Boolean(billingInfo?.paymentMethods?.length)

  const getTrialNotificationContent = () => {
    if (isSubscriptionScheduledToCancel) {
      return (
        <>
          Trial subscription ends on <span className="bold">{renewalDate.toLocaleDateString()}</span>
        </>
      )
    } else {
      if (hasUserEnteredBillingInfo) {
        return (
          <>
            Payment method added. Your paid subscription starts on <span className="bold">{renewalDate.toLocaleDateString()}</span>
          </>
        )
      } else {
        return (
          (
            <>
              Your free trial expires in <span className="bold">{getDateDifferenceByNow(renewalDate)}</span>.
              {' '}
              <Link to={routes.billing} className={clsx(classes.link)}>Add a payment method now</Link> to keep using EDEE.
            </>
          )
        )
      }
    }
  }

  return (
    <div className={clsx(classes.container, (hasUserEnteredBillingInfo && !isSubscriptionScheduledToCancel) && classes.billingAdded)}>
      <div className={clsx(classes.text)}>
        {getTrialNotificationContent()}
      </div>
      <span role="button" onClick={() => setIsVisible(false)} data-cy="closeTrialNotification">
        <img src={closeIcon} alt="close" />
      </span>
    </div>
  )
}

export default TrialNotification
