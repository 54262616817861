const getColorClass = ({
  value,
  min,
  between,
  max,
  isInvertedColors,
}: {
  value: number;
  min: number;
  between: {
    min: number;
    max: number;
  };
  max: number;
  isInvertedColors?: boolean;
}) => {
  if (value < min) return isInvertedColors ? 'color-green' : 'color-red'

  if (value >= between.min && value <= between.max) return 'color-orange'

  if (value >= max) return isInvertedColors ? 'color-red' : 'color-green'
}

export default getColorClass
