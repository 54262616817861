import React, { FC, SVGProps } from 'react'

// components
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'

type propsType = {
  yes?: string;
  no?: string;
  title?: string;
  size?: "sm" | "md" | "lg" | "xl";
  icon?: string | FC<SVGProps<SVGSVGElement>>
  description?: string;
  type?: 'warning';
  handleClick?: () => void;
  onClose?: () => void;
  onNoClick?: () => void;
}

const StaticNotification: React.FC<propsType> = ({
  yes = 'Got it',
  no,
  title,
  size = 'sm',
  icon,
  description,
  type,
  handleClick,
  onClose,
  onNoClick,
}) => (
  <Modal
    size={size}
    withCloseButton
    onClose={onClose}>
    <img className="align-center" src={icon} alt="icon" />
    <h1
      className="text-center fw-regular fs-lg">
      {title}
    </h1>
    <p className="text-center fw-regular">{description}</p>
    <br />
    {yes && (
      <Button
        fullWidth
        type="submit"
        title={yes}
        onClick={handleClick}
        colorRed={type === 'warning'}
        dataCy="notification submit"
      />
    )}
    <br />
    {no && (
      <div role="button" className="green text-center fw-regular cursor-pointer" onClick={onNoClick || onClose} data-cy="notification close">
        {no}
      </div>
    )}
  </Modal>
)

export default StaticNotification
