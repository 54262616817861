import React, { useState } from 'react'

import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import Checkbox from 'components/ui/Checkbox'

// actions
import { update2FA, updateTechSupport } from 'state-manager/actions/user'

// constants
import { CHECKBOX_TYPE_SWITCH } from 'components/ui/Checkbox/constants'


const Privacy = () => {
  const {
    ud2FA,
    udTechSupport,
    udPermissions
  } = useAppSelector((state) => ({
    ud2FA: state.userData['2fa'],
    udTechSupport: state.userData.techSupport,
    udPermissions: state.userData.permissions,
  }))

  const dispatch = useAppDispatch()

  const [support, setSupport] = useState(udTechSupport)
  const [TwoFAStatus, setTwoFaStatus] = useState(ud2FA)

  const handleUpdateTwoFA = () => {
    dispatch(update2FA({ 'two-factor-auth-status': !TwoFAStatus }))
      .then(() => {
        setTwoFaStatus(!TwoFAStatus)
      })
  }

  const handleUpdateTechSupport = () => {
    dispatch(updateTechSupport({ techSupportEnabled: !support }))
      .then(() => {
        setSupport(!support)
      })
  }

  return (
    <div className="form-page card w-sm-20">
      <form>
        <div className="form-group">
          <div className="row">
            <p className="fw-medium mb-3 fs-20">Privacy</p>
          </div>
          <div className="d-flex flex-column">
            <div className="row align-items-center">
              <span className="fw-regular fs-main">Two-Factor Authentication</span>
              <Checkbox
                label=""
                type={CHECKBOX_TYPE_SWITCH}
                checked={TwoFAStatus}
                onChange={handleUpdateTwoFA}
                dataCy="change 2fa"
              />
            </div>
            <br />
            <p className="fw-regular fs-14 color-dark-grey">
              An extra layer of protection used to ensure the security of your online account.
            </p>
            <br />

            {udPermissions.canManageSubscriptions && (
              <>
                <div className="row align-items-center">
                  <span className="fw-regular fs-main">Technical support </span>
                  {support !== null && (
                    <Checkbox
                      label=""
                      type={CHECKBOX_TYPE_SWITCH}
                      checked={support}
                      onChange={handleUpdateTechSupport}
                      dataCy="change technical support"
                    />
                  )}
                </div>
                <br />
                <p className="fw-regular fs-14 color-dark-grey">
                  Let EDEE technical support representatives access your data when necessary to provide service and find solutions to technical issues.
                </p>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Privacy
