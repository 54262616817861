import { CurrencyType } from 'constants/application-configs'
import getCurrency from 'helpers/get-currency'

export type tableDataType = {
  currencyId: number;
  [key: string]: any;
}

export default (tableData: Array<tableDataType>, currencies: Array<CurrencyType>) => tableData.map((item) => ({
  ...item,
  currency: getCurrency(item.currencyId, currencies),
}))
