import React, { useState } from 'react'
import clsx from 'clsx'
import { useAppDispatch } from 'state-manager/store'

// components
import Radio from 'components/ui/RadioButton'
import Button from 'components/ui/Button'

// actions
import { updateDefaultPaymentMethod } from 'state-manager/actions/subscriptions'

// assets
import VisaIcon from 'assets/icons/visa.svg'
import MasterCardIcon from 'assets/icons/mastercard.svg'
import AmexIcon from 'assets/icons/americanExpress.svg'
import UnionPayIcon from 'assets/icons/unionPay.svg'
import JcbIcon from 'assets/icons/jcb.svg'

import { _useGlobalDemo } from 'hooks/use-demo'
import { IBillingInfo } from 'state-manager/reducers/billing-info'

import classes from 'pages/Invoices/PaymentMethods/PaymentMethods.module.scss'

const cardBrandIcon = (brand: string) => {
  switch (brand) {
    case 'mastercard':
      return MasterCardIcon
    case 'amex':
      return AmexIcon
    case 'unionpay':
      return UnionPayIcon
    case 'jcb':
      return JcbIcon
    default:
      return VisaIcon
  }
}

type PaymentMethodsType = {
  data: IBillingInfo["data"]
}

const PaymentMethods: React.FC<PaymentMethodsType> = ({
  data,
}) => {
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(data?.defaultPaymentMethod || '')

  const demoHOC = _useGlobalDemo()

  const dispatch = useAppDispatch()

  const handlePaymentMethodUpdate = () => {
    if (!defaultPaymentMethod) {
      return
    }
    const data = {
      paymentMethodId: defaultPaymentMethod,
    }
    dispatch(updateDefaultPaymentMethod(data))
  }

  return (
    <div className={clsx(classes.card, 'd-flex flex-column p-3')}>
      {data?.paymentMethods?.map((paymentMethod, index) => (
        <div className="row mb-2" key={index}>
          <div className="d-flex align-items-center">
            <Radio
              checked={paymentMethod.id === defaultPaymentMethod}
              name="defaultPaymentMethod"
              onChange={() => setDefaultPaymentMethod(paymentMethod.id)}
            />
            <span className="fw-medium ml-1">•••• {paymentMethod.card.lastFour}</span>
          </div>
          <img src={cardBrandIcon(paymentMethod.card.brand)} alt="visa" />
        </div>
      ))}
      <div className="d-flex justify-content-center mt-2">
        <Button
          disabled={data?.defaultPaymentMethod === defaultPaymentMethod}
          fullWidth
          title="Choose as Default"
          className="w-20"
          onClick={demoHOC(handlePaymentMethodUpdate)}
          dataCy='choose-as-default-payment-button'
        />
      </div>
    </div>
  )
}

export default PaymentMethods
