import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

// components
import SpriteIcon from 'components/ui/SpriteIcon'
import DataTable from 'pages/Clients/table'
import BarChart from 'components/ui/Charts/BarChart'
import LineChart from 'components/ui/Charts/LineChart'
import ModalConfirmDeactivation from 'pages/shared/modals/modalConfirmDeactivation'
import ModalConfirmActivation from 'pages/shared/modals/modalConfirmActivation'
import ModalConfirmDelete from 'pages/shared/modals/modalConfirmDelete'
import Widgets from 'components/ui/Widgets'
import RefreshButton from 'components/ui/RefreshButton'

// actions
import { getSingleClient, getSingleClientCharts, updateClientStatus, deleteClient } from 'state-manager/actions/clients'

// hooks
import _useToggle from 'hooks/use-toggle'

// helpers
import { getLabelsInBudgets } from 'helpers/get-labels-in-budgets'

// constants
import { ACTIVE } from 'constants/status'
import { CLIENT_ACTIONS, CLIENT_MODAL } from 'state-manager/constants'

// routes
import routes from 'routes'

// proptypes
import { types as singleClientTypes } from 'state-manager/reducers/single-client'
import { types as allClientsTypes } from 'state-manager/reducers/all-clients'
import { types as userDataTypes } from 'state-manager/reducers/user-data'
import { types as labelsTypes } from 'state-manager/reducers/labels'


// styles
import classes from 'pages/Clients/Client.module.scss'
import { formatNumber } from 'utils/formatNumber'
import { useAppSelector } from 'state-manager/store'
import useRefreshData from 'hooks/useRefreshData'


const Clients = ({
  udPermissions,
  labels,
  client,
  allClients,
  updateClientStatus,
  getSingleClient,
  deleteClient,
}) => {
  const history = useHistory()
  const { id } = useParams()
  const [deactivateModalOpen, toggleDeactivateModalOpen] = _useToggle()
  const [activateModalOpen, toggleActivateModalOpen] = _useToggle()
  const [deleteModalOpen, toggleDeleteModalOpen] = _useToggle()

  const [cumulative, setCumulative] = useState([])
  const [daily, setDaily] = useState([])

  const timeLastUpdate = useAppSelector((state) => state.singleClient.timeLastUpdate)

  const dispatch = useDispatch()

  const { isDataNeedToRefresh, restoreTimer } = useRefreshData()

  const loadSingleClient = () => (
    Promise.all([
      getSingleClient(id),
      getSingleClientCharts(id)
        .then(({ parsedDaily, parsedCumulative }) => {
          setCumulative(parsedCumulative)
          setDaily(parsedDaily)
        })
    ])
  )

  useEffect(() => {
    loadSingleClient()
    return () => {
      dispatch({ type: CLIENT_ACTIONS.CLIENT_DELETE_SINGLE.SYSTEM })
    }
    //eslint-disable-next-line
  }, [])

  const tableData = client?.budgetInfo
  const modifiedTableData = useMemo(
    () => (tableData ? getLabelsInBudgets(tableData, labels) : []),
    [labels, tableData],
  )

  if (!Object.keys(client).length) {
    return <div className='text-center'>Client not found</div>
  }

  const handleOpenModal = () => {
    client.status === ACTIVE ? toggleDeactivateModalOpen() : toggleActivateModalOpen()
  }

  const handleUpdateStatus = (shouldUpdateCampaigns = true) => {
    const updateData = {
      clientId: client.id,
      clientStatus: client.status === ACTIVE ? 2 : 1,
      shouldUpdateCampaigns: !!shouldUpdateCampaigns,
    }

    updateClientStatus(updateData)
      .then(() => {
        getSingleClient(id)
        client.status === ACTIVE ? toggleDeactivateModalOpen() : toggleActivateModalOpen()
      })
  }

  const handleDeleteClient = () => {
    deleteClient(id)
      .then(() => {
        toggleDeleteModalOpen()
        history.push(routes.dashboardClients)
      })
  }

  const handleBackButton = () => {
    history.goBack()
  }

  const renderWidgets = (data) => {
    const widgetData = [
      {
        value: formatNumber(data.budgetTarget),
        name: 'Budget',
        color: 'green',
        isPercent: false,
      },
      {
        value: formatNumber(data.spent),
        name: 'Spend',
        color: 'blue',
        isPercent: false,
      },
      {
        value: data.pacing,
        name: 'Pacing',
        color: 'yellow',
        isPercent: true,
      },
    ]
    return <Widgets items={widgetData} />
  }

  const isChartsShow = allClients.length
    ? Boolean(allClients.find((item) => item.id === client.id)?.graphData && cumulative.length && daily.length)
    : false
  // const timelineData = isChartsShow ? Object.entries(allClients.find((item) => item.id === client.id).graphData.daily).map((item) => ({ label: format(new Date(item[0]), 'dd.MM'), actualData: parseFloat(item[1].actual), budgetData: parseFloat(item[1].predicted) })) : []
  // const cumlativeTimelineData = isChartsShow ? Object.entries(allClients.find((item) => item.id === client.id).graphData.cumulative).map((item) => ({ label: format(new Date(item[0]), 'dd.MM'), actualData: parseFloat(item[1].actual), budgetData: parseFloat(item[1].predicted) })) : []

  const currentClientData = allClients.find(({ id }) => id === client.id)

  const handleEditClick = () => {
    if (currentClientData) {
      dispatch({
        type: CLIENT_MODAL.SHOW,
        data: {
          id: client.id,
        },
      })
    }
  }

  return (
    <>
      <div className="heading d-flex justify-content-between align-items-center mb-3 flex-column-sm">
        <div className="d-flex align-items-center align-self-start-sm mb-sm-2">
          <SpriteIcon name="arrowLeft" size="md" className="mr-3" onClick={handleBackButton} dataCy="Go back" />
          <span
            className={clsx(classes.dotStatus, client.status === ACTIVE && classes.dotStatusActive)}
          />
          <div className="fw-semibold fs-lg fs-sm-main color-black ml-2 mr-3">{client.name}</div>
        </div>

        {udPermissions.canManageClients && (
          <div className="d-flex align-self-end-sm">
            <button
              type="button"
              data-cy={client.status === ACTIVE ? 'Deactivate client' : 'Activate client'}
              className={clsx(classes.buttonStatus, client.status !== ACTIVE && 'color-green-fade', client.status === ACTIVE && 'color-red-fade', 'btn mr-2')}
              onClick={handleOpenModal}>
              {client.status === ACTIVE ? 'Deactivate' : 'Activate'}
            </button>
            <div
              role="button"
              data-cy="edit client"
              onClick={() => handleEditClick()}>
              <div className={clsx(classes.buttonEdit, 'btn', currentClientData ? '' : classes.disabled)}>Edit</div>
            </div>
            {client.status !== ACTIVE && (
              <div
                onClick={() => toggleDeleteModalOpen()}
                role="button">
                <div className={clsx(classes.buttonEdit, 'btn color-red ml-2')}>Delete</div>
              </div>
            )}
          </div>
        )}
      </div>

      {currentClientData && renderWidgets(currentClientData)}

      {isChartsShow && (
        <div className="mb-3">
          <BarChart budgetData={daily} />
          <div className="mb-8" />
          <LineChart budgetData={cumulative} />
        </div>
      )}

      <RefreshButton
        isDataNeedToRefresh={isDataNeedToRefresh}
        onClick={() => {
          loadSingleClient()
            .then(restoreTimer)
        }}
      />

      <DataTable data={modifiedTableData} />
      {deactivateModalOpen && <ModalConfirmDeactivation entity="client" onClick={handleUpdateStatus} onNoClick={() => handleUpdateStatus(false)} onClose={toggleDeactivateModalOpen} />}
      {activateModalOpen && <ModalConfirmActivation entity="client" onClick={handleUpdateStatus} onClose={toggleActivateModalOpen} />}
      {deleteModalOpen && <ModalConfirmDelete entity="client" onClick={handleDeleteClient} onClose={toggleDeleteModalOpen} />}
    </>
  )
}

Clients.propTypes = {
  udPermissions: userDataTypes.permissions.isRequired,
  labels: labelsTypes.labels.isRequired,
  client: singleClientTypes.data.isRequired,
  allClients: allClientsTypes.data.isRequired,
  updateClientStatus: PropTypes.func.isRequired,
  getSingleClient: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  udPermissions: state.userData.permissions,
  labels: state.labels.labels,
  client: state.singleClient.data,
  allClients: state.allClients.data,
})

const mapDispatchToProps = {
  updateClientStatus,
  getSingleClient,
  deleteClient,
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
