import { useEffect, useState } from 'react'

export const _useHubspotChat = (
  portalId: number,
  { email, firstName, lastName }: { email: string, firstName: string, lastName: string },
) => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    window.hsConversationsOnReady = [() => {
      setHasLoaded(true)
    }]

    const script = document.createElement('script')
    script.src = `//js.hs-scripts.com/${portalId}.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      window.hsConversationsOnReady = []
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const _hsq = window._hsq = window._hsq || []
    _hsq.push(['identify', {
      email,
      firstName,
      lastName,
    }])
    //eslint-disable-next-line
  }, [hasLoaded])
}
