import React from 'react'
import { NavLink } from 'react-router-dom'
// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'
import { TableCellRenderer } from 'react-table'

// components
import Table, {cellType} from 'components/ui/Table/index'

// constants
import { ACTIVE } from 'constants/status'

// routes
import routes from 'routes'
import { ICustomColumn } from 'state-manager/reducers/table'

const StatusCell: TableCellRenderer = ({ value }) => <span className={`color-${value === ACTIVE ? 'green' : 'red'}`}>{value}</span>
const BudgetCell: TableCellRenderer = ({ original }) => <NavLink className="fw-semibold color-green" to={{ pathname: formatRoute(routes.budgets, { id: original.id }) }} data-cy={`${original.name}`}>{original.name}</NavLink>

type DataTableType = {
  data: cellType[]
}

const DataTable: React.FC<DataTableType> = ({
  data,
}) => {
  const columns = React.useMemo(
    () => [
      {
        name: 'status',
        Header: 'Status',
        filterType: 'select',
        Cell: StatusCell,
        width: 104,
        visible: true,
      },
      {
        name: 'name',
        Header: 'Budget name',
        Cell: BudgetCell,
        width: 264,
        visible: true,
        style: { whiteSpace: 'unset' },
        className: 'contentOverflow',
        fixed: 'left',
      },
      {
        name: 'pacing',
        Header: 'Pacing',
        filterType: 'numberRange',
        filterTypeColored: true,
        width: 144,
        visible: true,
      },
      {
        name: 'cycleBudget',
        Header: 'Cycle budget',
        filterType: 'numberRange',
        width: 144,
        // @ts-expect-error unknown error
        visible: true,
      },
      {
        name: 'rolloverBudget',
        Header: 'Rollover budget',
        filterType: 'numberRange',
        width: 144,
        visible: true,
      },
      {
        name: 'suggestedDailyBudget',
        Header: 'Suggested daily budget',
        filterType: 'numberRange',
        width: 200,
        visible: true,
      },
      {
        name: 'totalDailyBudget',
        Header: 'Total daily budget',
        filterType: 'numberRange',
        width: 200,
        visible: true,
      },
      {
        name: 'budgetTarget',
        Header: 'Budget target',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'spentToDate',
        Header: 'Spend to date',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'spentYesterday',
        Header: 'Spend yesterday',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'conversionVTarget',
        Header: 'Conversions vs target',
        filterType: 'percentRange',
        width: 184,
        visible: false,
      },
      {
        name: 'cpcVTarget',
        Header: 'Cost per conversion vs target',
        filterType: 'percentRange',
        width: 224,
        visible: false,
      },
      {
        name: 'roasVTarget',
        Header: 'ROAS vs. target',
        filterType: 'percentRange',
        width: 224,
        visible: false,
      },
      {
        name: 'impressions',
        Header: 'Impressions',
        filterType: 'numberRange',
        width: 120,
        visible: false,
      },
      {
        name: 'clicks',
        Header: 'Clicks',
        filterType: 'numberRange',
        width: 120,
        visible: false,
      },
      {
        name: 'ctr',
        Header: 'CTR',
        filterType: 'percentRange',
        width: 120,
        visible: false,
      },
      {
        name: 'averageCpc',
        Header: 'Avg CPC',
        filterType: 'numberRange',
        width: 120,
        visible: false,
      },
      {
        name: 'conversions',
        Header: 'Conversions',
        filterType: 'numberRange',
        width: 120,
        visible: false,
      },
      {
        name: 'conversionRate',
        Header: 'Conversion rate',
        filterType: 'percentRange',
        width: 160,
        visible: false,
      },
      {
        name: 'costPerConversion',
        Header: 'Cost per conversion',
        filterType: 'numberRange',
        width: 184,
        visible: false,
      },
      {
        name: 'conversionValue',
        Header: 'Conversion Value',
        filterType: 'numberRange',
        width: 184,
        visible: false,
      },
      {
        name: 'roas',
        Header: 'ROAS',
        filterType: 'percentRange',
        width: 184,
        visible: false,
      },
      {
        name: 'searchImpressionShare',
        Header: 'Search impression share',
        filterType: 'percentRange',
        width: 200,
        visible: false,
      },
      {
        name: 'cycleStartDate',
        Header: 'Cycle start date',
        filterType: 'datepicker',
        width: 264,
        visible: false,
      },
      {
        name: 'cycleEndDate',
        Header: 'Cycle end date',
        filterType: 'datepicker',
        width: 264,
        visible: false,
      },
      {
        name: 'labels',
        Header: 'Labels',
        filterType: 'multi-select',
        width: 200,
        visible: false,
      },
    ] as ICustomColumn[],
    [],
  )

  return (
    <Table
      persistable
      tableName="singleClient"
      customizable
      columns={columns}
      data={data}
    />
  )
}

export default DataTable
