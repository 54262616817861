import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { TYPE_MONTHLY, TYPE_CUSTOM, PERIOD_DAYS, PERIOD_WEEKS, PERIOD_MONTHS } from 'constants/budgets'
import { BUDGET_ACTIONS } from 'state-manager/constants'

export type CampaignType ={
  "id": number;
  "externalId": number;
  "status": string;
  "name": string;
  "dailyBudget": number;
  "source": string;
  "budgetName": string;
  "client": string;
  "suggestedDailyBudget": number;
  "lastCycleMonth": [],
  "previousCycleMonth": {
      "spentToDate": number
  },
  "spentToDate": number;
  "spentYesterday": number;
  "conversionsVTarget": number;
  "cpcVTarget": number;
  "roasVTarget": number;
  "roas": number;
  "impressions": number;
  "clicks": number;
  "ctr": number;
  "averageCpc": number;
  "conversions": number;
  "conversionRate": number;
  "costPerConversion": number;
  "searchImpressionShare": number;
  "conversionValue": number;
  "cycleStartDate": string;
  "cycleEndDate": string;
  "account": {
      "id": number;
      "name": string;
  },
  "currency": number;
  "sharedBudget": boolean;
}

export interface ISingleBudget {
  data: {
    id: number,
    type: typeof TYPE_MONTHLY | typeof TYPE_CUSTOM,
    status: 'active' | 'paused' | 'none',
    client: {
      id: number;
      user_id: number;
      currency_id: number;
      name: string;
      company_id: number,
    },
    manager: {
      id: number;
      firstName: string;
      lastName: string;
    }
    calculated: {
      graphsData: {
        cumulative: Record<string, { actual: number, predicted: number }>,
        daily: Record<string, { actual: number, predicted: number }>,
      }
    },
    campaigns: Array<CampaignType>,
    name: string,
    amount: number,
    conversionTarget: number,
    cpcTarget: number,
    roasTarget: number,
    budgetRolloverEnabled: boolean,
    rolloverFrom: number,
    overspendControlEnabled: number,
    overspendControlType: string,
    reduceSpendPercents: number,
    cycleDate: number,
    isRepeating: number,
    pacingPeriodDuration: number,
    pacingPeriodType: typeof PERIOD_DAYS | typeof PERIOD_WEEKS | typeof PERIOD_MONTHS,
    startDate: string,
    endDate: string,
  } | Record<string, never>,
}

const initialState: ISingleBudget = {
  data: {},
}

export const types = {
  data: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf([TYPE_MONTHLY, TYPE_CUSTOM]),
    client: PropTypes.shape({
      result: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        name: PropTypes.string,
        currencyId: PropTypes.number,
      }),
    }),
    name: PropTypes.string,
    amount: PropTypes.number,
    conversionTarget: PropTypes.number,
    cpcTarget: PropTypes.number,
    roasTarget: PropTypes.number,
    budgetRolloverEnabled: PropTypes.bool,
    rolloverFrom: PropTypes.number,
    overspendControlEnabled: PropTypes.number,
    overspendControlType: PropTypes.string,
    reduceSpendPercents: PropTypes.number,
    cycleDate: PropTypes.number,
    isRepeating: PropTypes.number,
    pacingPeriodDuration: PropTypes.number,
    pacingPeriodType: PropTypes.oneOf([PERIOD_DAYS, PERIOD_WEEKS, PERIOD_MONTHS]),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case BUDGET_ACTIONS.BUDGET_GET_SINGLE.SUCCESS:
      return updateObject(state, {
        data: action.data,
      })

    case BUDGET_ACTIONS.BUDGET_DELETE_SINGLE.SYSTEM: {
      return initialState
    }
    default:
      return state
  }
}
