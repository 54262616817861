import PropTypes from 'prop-types'

export type CurrencyType = {
  id: number;
  name: string;
  code: string;
  symbol: string;
}

export const currenciesTypes = {
  currencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    symbol: PropTypes.string,
  })),
}

export const APPLICATION_CONFIGS = [
  {
    name: 'countries',
  },
  {
    name: 'currencies',
  },
]
