import React from 'react'

export const selectFilterMethod = (filter, row, options, type) => {
  if (filter.value?.min) {
    return true
  }

  if (filter.value === 'all') {
    return true
  }

  // if (row?.selected) {
  //   return true
  // }

  if (type === 'range') {
    const inRange = (value, min, max) => value >= min && value <= max

    const currentOption = options.find((option) => parseInt(option.value) === parseInt(filter.value))
    return inRange(row[filter.id], currentOption?.min, currentOption?.max)
  }

  return filter.value === row[filter.id]
}

const SelectFilter = ({ onChange, filter, options = [], type = '', name }) => (
  <select
    data-cy={`${name} select`}
    onChange={(e) => onChange(e.target.value)}
    value={filter ? filter.value : 'all'}
    className="form-control">
    <option value="all" data-cy={`${name} option - all`}>All</option>
    {options.map((option, index) => {
      if (type === 'range' && typeof option === 'object' && typeof option.value !== 'undefined' && typeof option.label !== 'undefined') {
        return (
          <option key={index} value={option.value} data-cy={`${name} option - ${option.value}`}>
            {option.label.charAt(0).toUpperCase() + option.label.slice(1)}
          </option>
        )
      }
      if (option) {
        return (
          <option key={index} value={option} data-cy={`${name} option - ${option.value}`}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </option>
        )
      }
    })}
  </select>
)

export default SelectFilter
