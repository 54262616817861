import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// components
import Modal from 'components/ui/Modal'
import Input from 'components/ui/TextInput'
import Button from 'components/ui/Button'
import PasswordInput from 'components/ui/PasswordInput'

// actions
import { signIn } from 'state-manager/actions/auth'

// helpers
import { hasError } from 'helpers/field-has-errors'

// routes
import routes from 'routes'

const SignIn: React.FC = () => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .label('Email')
        .email()
        .required(),
      password: Yup
        .string()
        .label('Password')
        .required(),
    }),
    onSubmit: (values) => {
      dispatch(signIn(values))
    }
  })

  const emailHasError = hasError('email', formik.touched, formik.errors)
  const passwordHasError = hasError('password', formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <Modal
      title="Login"
      withCloseButton={false}>
      <div className="form-page">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <Input
              label="Email"
              name="email"
              value={formik.values.email}
              errorMsg={emailHasError && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Email"
              dataCy="email"
            />
            <br />
            <PasswordInput
              label="Password"
              name="password"
              value={formik.values.password}
              errorMsg={passwordHasError && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Password"
              dataCy="password"
            />
          </div>
          <br />
          <Button
            type="submit"
            title="Login"
            disabled={isSubmitDisabled}
            dataCy="submit"
          />
          <br />
        </form>
        <div className="d-flex justify-content-between align-items-centers flex-column-sm align-items-center-sm">
          <div className="fw-regular">
            Don’t have an account?{' '}
            <Link to={routes.modalSignUp[0]} className="fw-semibold" data-cy="sign up">
              Sign up
            </Link>
          </div>
          <Link to={routes.modalForgotPassword} className="fw-semibold" data-cy="forgot password">
            Forgot password?
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default SignIn
