// actions
import { getUserData } from 'state-manager/actions/user'
import { getAllBudgets } from 'state-manager/actions/budgets'

// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { DATASOURCE_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

const urlPrefix = 'data-source'

export const getAllDataSources = () => (dispatch: AppDispatch) => {
  dispatch({ type: DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.ATTEMPT })

  return reqH({
    method: 'GET',
    url: urlPrefix,
    withoutLoader: true,
  })
    .then((res) => {
      dispatch({
        type: DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.ERROR })
    })
}

export const deleteDataSource = (id: { dataSourceId: number }) => (dispatch: AppDispatch) => reqH({
  method: 'DELETE',
  url: urlPrefix,
  data: id,
})
  .then(() => {
    notification.success('Data source successfully deleted!')
    dispatch(getAllDataSources())
    dispatch(getAllBudgets())
    dispatch(getUserData())
  })
  .finally(() => {
    dispatch({ type: DATASOURCE_ACTIONS.DATASOURCE_DELETE.SYSTEM })
  })

export const refreshDataSource = (id: string) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url: `${urlPrefix}/${id}/refresh`,
})
  .then(() => {
    notification.success('Data source successfully refreshed!')
  })
  .finally(() => {
    dispatch({ type: DATASOURCE_ACTIONS.DATASOURCE_REFRESH.SYSTEM })
  })
