import React from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// components
import TextInput from 'components/ui/TextInput'
import Button from 'components/ui/Button'
import TelInput from 'components/ui/TelInput'
import AvatarUpload from 'pages/Settings/AvatarUpload'

// actions
import { updateUser, uploadUserAvatar, deleteAvatar } from 'state-manager/actions/user'

// helpers
import { hasError } from 'helpers/field-has-errors'

// styles
import classes from 'pages/Settings/PersonalInfo/PersonalInfo.module.scss'
import { getNameValidation } from 'utils/validation'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

export const formFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  companyName: 'companyName',
  phoneNumber: 'phoneNumber',
  email: 'email',
  countryId: 'countryId',
}

const PersonalInfo = () => {
  const { 
    udFirstName,
    udLastName,
    udCompany,
    udEmail,
    udPhoneNumber,
    udCountryId,
    udAvatar,
    udPermissions,
    configCountries,
  } = useAppSelector((state) => ({
    udFirstName: state.userData.firstName,
    udLastName: state.userData.lastName,
    udCompany: state.userData.company,
    udEmail: state.userData.email,
    udPhoneNumber: state.userData.phoneNumber,
    udCountryId: state.userData.countryId,
    udAvatar: state.userData.avatar,
    udPermissions: state.userData.permissions,
    configCountries: state.countries.data,
  }))

  const dispatch = useAppDispatch()

  const phoneCode = configCountries.find((item) => item.id === udCountryId)
  const countryDialCode = configCountries.find((item) => item.id === udCountryId)?.dial_code.replace(/\D/g, '')

  const formik = useFormik({
    initialValues: {
      firstName: udFirstName,
      lastName: udLastName,
      companyName: udCompany.name,
      phoneNumber: `${phoneCode?.dial_code}${udPhoneNumber}`,
      email: udEmail,
      countryId: countryDialCode,
    },

    validationSchema: Yup.object({
      firstName: getNameValidation('First name'),
      lastName: getNameValidation('Last Name'),
      companyName: Yup
        .string()
        .label('Company Name')
        .required(),
      phoneNumber: Yup
        .string()
        .label('Phone Number')
        .required(),
      email: Yup
        .string()
        .label('Email')
        .email()
        .required(),
    }),

    onSubmit: (values) => {
      const { firstName, lastName, email, companyName, phoneNumber, countryId } = values
      const cId = configCountries.find((item) => item.dial_code === `+${countryId}`)?.id
      const pNumber = phoneNumber.replace(/\D/g, '').substring(countryId?.toString().length)
      const formData = { firstName, lastName, email, countryId: cId, companyName, phoneNumber: pNumber }
      dispatch(updateUser(formData))
    },
  })

  const fistNameHasError = hasError('firstName', formik.touched, formik.errors)
  const lastNameHasError = hasError('lastName', formik.touched, formik.errors)
  const companyNameHasError = hasError('companyName', formik.touched, formik.errors)
  const phoneHasError = hasError('phoneNumber', formik.touched, formik.errors)
  const emailHasError = hasError('email', formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <div className={clsx(classes.personalInfoCard, 'form-page card')}>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <div className="row">
            <p className="fw-medium mb-3 fs-20">Personal Info</p>
          </div>
          <div className="mb-3">
            <AvatarUpload
              avatar={udAvatar}
              uploadAvatar={(data: FormData) => dispatch(uploadUserAvatar(data))}
              deleteAvatar={() => dispatch(deleteAvatar())}
            />
          </div>
          <div className="row flex-column-sm">
            <div className="w-20 mb-sm-2">
              <TextInput
                label="First Name"
                name={formFields.firstName}
                value={formik.values.firstName}
                errorMsg={fistNameHasError && formik.errors.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="First Name"
                dataCy="set first name"
              />
            </div>
            <div className="divider" />
            <div className="w-20">
              <TextInput
                label="Last Name"
                name={formFields.lastName}
                value={formik.values.lastName}
                errorMsg={lastNameHasError && formik.errors.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Last Name"
                dataCy="set last name"
              />
            </div>
          </div>
          <br />
          <div className="row flex-column-sm">
            <div className="w-20 mb-sm-2">
              <TextInput
                disabled={!udPermissions.canManageSubscriptions}
                label="Company Name"
                name={formFields.companyName}
                value={formik.values.companyName}
                errorMsg={companyNameHasError && formik.errors.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Company Name"
                dataCy="set company name"
              />
            </div>
            <div className="divider" />
            <div className="w-20">
              <TelInput
                label="Phone Number"
                country={formik.values.countryId}
                errorMsg={phoneHasError && formik.errors.phoneNumber}
                name={formFields.phoneNumber}
                value={formik.values.phoneNumber}
                onBlur={formik.handleBlur}
                setFieldValue={formik.setFieldValue}
                inputCy="set phone"
              />
            </div>
          </div>
          <br />
          <div className="row flex-column-sm">
            <div className="w-20">
              <TextInput
                disabled
                label="Email"
                name={formFields.email}
                value={formik.values.email}
                errorMsg={emailHasError && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Email"
                dataCy="set email"
              />
            </div>
            <div className="divider" />
            <div className="w-20">
              <br />
              <Button
                fullWidth
                type="submit"
                disabled={isSubmitDisabled}
                title="Save changes"
                dataCy="save changes"
                className={clsx(classes.submitButton, 'w-20')}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PersonalInfo
