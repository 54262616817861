import React from 'react'

// components
import StaticNotification from 'components/StaticNotification'

// assets
import EmailIcon from 'assets/icons/email.svg'

type ModalInveteSentType = {
  onClose: () => void
}

const ModalInviteSent: React.FC<ModalInveteSentType> = ({
  onClose,
}) => (
  <StaticNotification
    onClose={onClose}
    icon={EmailIcon}
    title="Invite Sent"
    yes="Got it"
    handleClick={onClose}
    description="New team member successfully added. The invite  has been sent and this account will be active once a new team member completes the registration process."
  />
)

export default ModalInviteSent
