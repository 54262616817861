import React from 'react'

import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import DataTable from 'pages/Notifications/table'

// actions
import { getAllNotifications, turnOffAllNotifications, deleteNotification, updateNotification } from 'state-manager/actions/notifications'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'


const Notifications: React.FC = () => {
  const notifications = useAppSelector((state) => state.notifications.data)

  const dispatch = useAppDispatch()

  _useDidMount(() => {
    dispatch(getAllNotifications())
  })

  const handleTurnOffNotifications = () => {
    dispatch(turnOffAllNotifications())
  }

  const handleDeleteNotification = (id: string) => {
    dispatch(deleteNotification(id))
  }

  const handleUpdateNotification = (id: string, data: { emailEnabled: number | boolean; platformEnabled: number | boolean; }) => {
    dispatch(updateNotification(id, data))
  }

  return (
    <>
      <DataTable data={notifications} updateNotification={handleUpdateNotification} deleteNotification={handleDeleteNotification} />
      {!!notifications.length && (
        <div className="text-right mt-3">
          <div role="button" className="btn color-red-fade" onClick={handleTurnOffNotifications} data-cy="turn off all notifications">Turn off all notifications</div>
        </div>
      )}
    </>
  )
}

export default Notifications
