import axios from 'axios'
import FormData from 'form-data'
import isPlainObject from 'lodash.isplainobject'
import notification from 'utils/notifications'

// actions
import { signOutSimple } from 'state-manager/actions/auth'
import { getUserData } from 'state-manager/actions/user'

// state-manager
import { store } from 'state-manager/store'

// utils
import globalSpinner from 'utils/global-spinner'

// constants
import { API_URL, CONFIG_URL, ADMIN_URL } from 'constants/main'
import { errorMessages } from 'constants/errorMessages'

type optionsType = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: { [key: string]: any };
  headers?: { [key: string]: string };
  sendMethod?: 'json' | 'form-data';
  url?: string;
  urlPrefix?: string;
  applicationConfigRequest?: boolean;
  applicationConfigName?: string;
  adminRequest?: boolean;
  skipNotifications?: boolean;
  skipUnauthorizedNotification?: boolean;
  shouldUpdateUserData?: boolean;
  keepGlobalSpinnerAfterResponse?: boolean;
  data?: FormData | Record<string, any>;
  withoutLoader?: boolean,
}

const getUrl = (options: optionsType) => {
  switch (true) {
    case options.applicationConfigRequest:
      return CONFIG_URL
    case options.adminRequest:
      return ADMIN_URL
    default:
      return API_URL
  }
}

const parseMessage = (message: string) => errorMessages[message] ?? message

const reqH = <Response>(options: optionsType = {}) => {
  const requestOptions: optionsType = {}

  if (typeof options.method === 'undefined') {
    options.method = 'GET'
  }
  requestOptions.method = options.method

  if (typeof options.params === 'undefined') {
    options.params = {}
  }
  requestOptions.params = options.params

  if (options.method !== 'GET' && options.data) {
    if (typeof options.sendMethod === 'undefined') {
      options.sendMethod = 'json'
    }

    if (options.sendMethod === 'form-data') {
      const formData = new FormData()
      Object.keys(options.data).forEach((key) => {
        const value = options.data[key]
        if (Array.isArray(value)) { // if value is array - add each key
          Object.keys(value).forEach((innerKey) => {
            formData.append(`${key}[]`, value[innerKey] || '')
          })
        } else if (isPlainObject(value) && !(value instanceof File)) { // if value is object - add each key to the overall key
          for (const innerKey in value) {
            formData.append(`${key}[${innerKey}]`, value[innerKey] || '')
          }
        } else {
          formData.append(key, value || '')
        }
      })
      requestOptions.data = formData
    } else {
      requestOptions.data = options.data
    }
  }

  if (options.urlPrefix) {
    options.url = `${options.urlPrefix}/${options.url}`
  }

  requestOptions.url = options.applicationConfigRequest ? `${options.applicationConfigName}.json` : options.url

  requestOptions.headers = {
    'Content-Type': 'application/json',
  }

  if (options.headers) {
    requestOptions.headers = {
      ...requestOptions.headers,
      ...options.headers,
    }
  }

  if (!options.withoutLoader) {
    globalSpinner.show()
  }

  const baseUrl = getUrl(options)

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 1000 * 60 * 5,
  })

  axiosInstance.interceptors.request.use((config) => {
    // if (!window.navigator.onLine) {
    //   notification.error('No internet connection')
    // }

    if (options.applicationConfigRequest) {
      return config
    }

    const state = store.getState()

    let token = null
    if (state && state.userData && state.userData.authorizationData) {
      token = `${state.userData.authorizationData.token_type} ${state.userData.authorizationData.access_token}`
    }
    config.headers.Authorization = token

    return config
  })
  axiosInstance.interceptors.response.use(
    (res) => {
      if (options.shouldUpdateUserData) {
        store.dispatch(getUserData())
      }

      if (!options.keepGlobalSpinnerAfterResponse && !options.withoutLoader) {
        globalSpinner.hide()
      }

      return res
    }, (error) => {
      if (!options.withoutLoader) {
        globalSpinner.hide()
      }

      // if response is empty - skip this interceptor
      if (!error.response) {
        throw error
      }

      const isUnauthorizedError = error.response.status === 401 && error.response.statusText === 'Unauthorized'
      const isUnauthenticatedError = error.response.status === 401 && error.response.data?.error?.message === 'Unauthenticated.'

      if ((isUnauthorizedError && options.skipUnauthorizedNotification) || isUnauthenticatedError) {
        options.skipNotifications = true
      }

      const validation = error.response.data?.validation
      if (!options.skipNotifications && validation) {
        Object.values(validation).forEach((fieldErrors) => {
          if (Array.isArray(fieldErrors)) {
            fieldErrors.forEach((error) => {
              notification.error(error)
            })
          } else {
            notification.error(fieldErrors)
          }
        })

        throw error
      }

      const errorMessage = error.response.data?.error?.message
      if (!options.skipNotifications && errorMessage) {
        notification.error(parseMessage(errorMessage))
      }

      if (isUnauthorizedError || isUnauthenticatedError) {
        store.dispatch(signOutSimple())
      }

      throw error
    },
  )

  return axiosInstance<Response>(requestOptions)
}

export default reqH
