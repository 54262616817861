const trigger = (name: string, detail = {}) => {
  const event = new CustomEvent(name, { detail })
  document.dispatchEvent(event)
}

const on = (name: string, listener: EventListenerOrEventListenerObject) => {
  document.addEventListener(name, listener)
}

const off = (name: string, listener: EventListenerOrEventListenerObject) => {
  document.removeEventListener(name, listener)
}

export default {
  on,
  off,
  trigger,
}
