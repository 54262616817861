import React from 'react'
import StaticNotification from 'components/StaticNotification'

// icons
import WarningIcon from 'assets/icons/warning.svg'

type ModalDemoWarningType = {
  onClose: () => void;
}

const ModalDemoWarning: React.FC<ModalDemoWarningType> = ({ onClose }) => (
  <StaticNotification
    icon={WarningIcon}
    onClose={onClose}
    handleClick={onClose}
    title="Restricted!"
    description="Sorry, you cannot perform this action on a demo account."
  />
)

export default ModalDemoWarning
