import React from 'react'

// components
import DataTable from 'pages/TeamMembers/table'

// actions
import { getAllTeamMembers, getAllTeamMembersRequests } from 'state-manager/actions/team-members'
import { getAllClients } from 'state-manager/actions/clients'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

import { useAppDispatch, useAppSelector } from 'state-manager/store'

const TeamMembers: React.FC = () => {
  const {
    teamMembers,
    teamMembersRequests,
    allClients,
    udPermissions,
  } = useAppSelector((state) => ({
    teamMembers: state.teamMembers.data,
    teamMembersRequests: state.teamMembers.requests,
    allClients: state.allClients.data,
    udPermissions: state.userData.permissions,
  }))

  const dispatch = useAppDispatch()

  _useDidMount(() => {
    dispatch(getAllTeamMembers())
    dispatch(getAllTeamMembersRequests())
    dispatch(getAllClients(true))
  })

  const allTeamMembers = teamMembersRequests.concat(teamMembers)

  return (
    <DataTable
      data={allTeamMembers}
      allClients={allClients}
      udPermissions={udPermissions}
    />
  )
}

export default TeamMembers
