import React from 'react'
import clsx from 'clsx'

import classes from 'components/ui/RefreshButton/refreshButton.module.scss'

type RefreshButtonType = {
  isDataNeedToRefresh: boolean
  onClick: () => void
}

const RefreshButton: React.FC<RefreshButtonType> = ({ isDataNeedToRefresh, onClick }) => (
  isDataNeedToRefresh ? (
    <div className="text-center">
      <button className={clsx(classes.refreshButton, classes.pop)} onClick={onClick}>Refresh Data</button>
    </div>
  ) : null
)

export default RefreshButton