import { useRef, useEffect } from 'react'

const _useEventListener = (element: HTMLElement | Window, eventName: string, handler: EventListenerOrEventListenerObject) => {
  const savedHandler = useRef<EventListenerOrEventListenerObject>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const isSupported = element && element.addEventListener
      if (!isSupported) {
        return
      }

      const eventListener = savedHandler.current || (() => {})

      element.addEventListener(eventName, eventListener)
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element],
  )
}

export default _useEventListener
