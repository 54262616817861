import React, { PropsWithChildren } from 'react'

// components
import HeaderSimple from 'components/HeaderSimple'

const SimpleAuthorizedPageLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="full-height">
    <HeaderSimple />
    <div className="page-content-simple d-flex justify-content-center align-items-center pb-15">
      {children}
    </div>
  </div>
)

export default SimpleAuthorizedPageLayout
