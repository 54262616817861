import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import reqH from 'utils/request-handler'

type currency = {
  id: number
  name: string
  code: string
  symbol: string
}

export interface ICurrencies {
  data: currency[]
}

export const fetchCurrencies = createAsyncThunk(
  'currencies/fetch',
  async () => {
    const res = await reqH<currency[]>({
      method: 'GET',
      applicationConfigRequest: true,
      applicationConfigName: 'currencies',
    })
    return res.data
  })

const initialState: ICurrencies = {
  data: []
}

const currenciesSlice = createSlice({
  name: 'currencies',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default currenciesSlice.reducer
