import { HISTORY_ACTIONS, DATASOURCE_ACTIONS, USER_ACTIONS, SUMO_PLANS, AUTH_ACTIONS } from 'state-manager/constants'
import reqH from 'utils/request-handler'
import parseFilters from 'utils/parse/parseFilters.ts'

export const fetchDataSourceLink = (data) => reqH({
  method: 'GET',
  urlPrefix: 'data-source',
  url: `auth/${data}`,
})

export const fetchAllDataSources = (dispatch) => reqH({
  method: 'GET',
  url: 'data-source',
}).then((res) => {
  dispatch({
    type: DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.SUCCESS,
    data: res.data.result,
  })
})

export const fetchUserData = (dispatch) => reqH({
  method: 'GET',
  url: 'user',
})
  .then((res) => {
    dispatch({
      type: USER_ACTIONS.USER_GET_DATA.SUCCESS,
      data: res.data.result,
    })
  })

export const getChangesHistory = (
  page,
  itemsPerPage,
  filters = [],
  sort = { id: 'date', desc: true },
) => (dispatch) => {
  const params = {
    count: itemsPerPage,
    page,
    ...parseFilters(filters),
  }

  if (sort) {
    const value = sort.desc ? 'desc' : 'asc'

    const paramKeys = {
      userName: 'sort_user_name',
      date: 'sort_date',
      description: 'sort_description',
      location: 'sort_location',
    }
    const key = paramKeys[sort.id]
    params[key] = value
  }

  return reqH({
    method: 'GET',
    url: 'changes-history',
    params,
  })
    .then((res) => {
      dispatch({
        type: HISTORY_ACTIONS.GET_PAGE.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: HISTORY_ACTIONS.GET_PAGE.ERROR })
    })
}

export const getAppSumoPlans = (dispatch) => reqH({
  method: 'GET',
  url: 'appsumo/plans',
}).then(({ data }) => {
  if (Array.isArray(data)) {
    dispatch({
      type: SUMO_PLANS.sumoGetPlans.SUCCESS,
      data,
    })
  }
})

export const signOut = (dispatch) => reqH({
  method: 'POST',
  urlPrefix: 'auth',
  url: 'logout',
})
  .then(() => {
    dispatch({ type: AUTH_ACTIONS.SIGN_OUT.SYSTEM })
  })
