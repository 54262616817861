import React from 'react'
import clsx from 'clsx'
import { FormikProps } from 'formik'

// components
import Checkbox from 'components/ui/Checkbox'
import NumberInput from 'components/ui/NumberInput'
import RadioButton from 'components/ui/RadioButton'

// constants
import { formFields } from 'pages/ManageBudget/constants'
import {
  OVERSPEND_CONTROL_TYPE_PAUSE,
  OVERSPEND_CONTROL_TYPE_REDUCE,
} from 'constants/budgets'
import { CHECKBOX_TYPE_SWITCH } from 'components/ui/Checkbox/constants'

// styles
import classes from 'pages/ManageBudget/steps/styles.module.scss'
import { BudgetFormValuesType } from '..'

const StepFive: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
}) => {
  const overspendControlEnabled = values.overspendControlEnabled
  const overspendControlPauseEnabled = values.overspendControlType === OVERSPEND_CONTROL_TYPE_PAUSE
  const overspendControlReduceEnabled = values.overspendControlType === OVERSPEND_CONTROL_TYPE_REDUCE

  const isBudgetRolloverEnabled = !!values.budgetRolloverEnabled

  const toggleBudgetRolloverEnabled = () => {
    setFieldValue(formFields.budgetRolloverEnabled, !isBudgetRolloverEnabled)
  }

  const toggleOverspendControlEnabled = () => {
    setFieldValue(formFields.overspendControlEnabled, !overspendControlEnabled)
  }

  const switchToOverspendReduce = () => {
    setFieldValue(formFields.overspendControlType, OVERSPEND_CONTROL_TYPE_REDUCE)
  }

  const switchToOverspendPause = () => {
    setFieldValue(formFields.overspendControlType, OVERSPEND_CONTROL_TYPE_PAUSE)
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-2">
        <span className="fw-medium fs-20">
          Budget rollover
        </span>
        <Checkbox
          type={CHECKBOX_TYPE_SWITCH}
          checked={isBudgetRolloverEnabled}
          onChange={toggleBudgetRolloverEnabled}
          dataCy="budget rollover"
        />
      </div>
      {isBudgetRolloverEnabled && (
        <NumberInput
          dataCy="starting rollover"
          label="Starting rollover (for the first cycle)"
          name={'rolloverFrom'}
          value={values.rolloverFrom}
          onChange={(val) => setFieldValue('rolloverFrom', val)}
          onBlur={handleBlur}
          errorMsg={errors.rolloverFrom}
          placeholder="Starting rollover (for the first cycle)"
        />
      )}
      <div className="d-flex justify-content-between align-items-center my-2">
        <span className="fw-medium fs-20">
          Overspend control
        </span>
        <Checkbox
          dataCy="overspend control"
          type={CHECKBOX_TYPE_SWITCH}
          checked={overspendControlEnabled}
          onChange={toggleOverspendControlEnabled}
        />
      </div>
      {overspendControlEnabled && (
        <>
          <div className="fw-regular color-dark-grey">
            Overspend control pauses or reduces daily spends on your campaigns if the budget is hit early in the cycle.
          </div>
          <div className="d-flex align-items-center mt-2">
            <RadioButton
              dataCy="overspend pause"
              name="radio-button"
              checked={overspendControlPauseEnabled}
              onChange={switchToOverspendPause}
            />
            <span className="fw-medium color-black fs-16 ml-1">
              Pause
            </span>
          </div>
          <div className="d-flex align-items-start mt-2 align-items-center flex-wrap justify-content-start">
            <div className={clsx(classes.overspendControlReduceWrapper, 'd-flex align-items-center align-self-start flex-grow-sm')}>
              <RadioButton
                dataCy="overspend reduce spend"
                name="radio-button"
                checked={overspendControlReduceEnabled}
                onChange={switchToOverspendReduce}
              />
              <span className="fw-medium color-black fs-16 ml-1 mr-4">
                Reduce spend
              </span>
            </div>
            {overspendControlReduceEnabled && (
              <>
                <div className="position-relative">
                  <div className={clsx(classes.overspendControlReduceInput, "rc-input-number input")}>
                    <input
                      autoComplete="off"
                      step="1"
                      maxLength={3}
                      type="number"
                      data-cy="overspend control reduce input"
                      name="reduceSpendPercents"
                      className="rc-input-number-input _ar_hide_"
                      value={values.reduceSpendPercents}
                      onChange={(event) => {
                        if (event.target.value.length > 3) {
                          return
                        }
                        setFieldValue('reduceSpendPercents', event.target.value, true)
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="fw-regular input-addon">
                    <span className={classes.overspendControlReduceInputAddon}>%</span>
                  </div>
                </div>


                <div className={clsx('fw-medium fs-sm color-red')}>
                  {errors.reduceSpendPercents}
                </div>
              </>
            )}
          </div>
          {overspendControlReduceEnabled && (
            <p className="fw-regular text-left pl-5 color-dark-grey mt-2">
              If campaign budgets are automatically reduced they will need to be increased again manually when needed as
              this won’t happen automatically when the next budget cycle starts.
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default StepFive
