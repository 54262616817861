export const formFields = {
  // step 1
  name: 'name',
  clientId: 'clientId',
  managerId: 'managerId',

  // step 2
  type: 'type',
  amount: 'amount',
  cycleDate: 'cycleDate',
  isRepeating: 'isRepeating',
  startDate: 'startDate',
  endDate: 'endDate',
  pacingPeriodDuration: 'pacingPeriodDuration',
  pacingPeriodType: 'pacingPeriodType',

  // step 3
  dataSource: 'dataSource',
  account: 'account',
  campaigns: 'campaigns',

  // step 4
  roasTarget: 'roasTarget',
  conversionTarget: 'conversionTarget',
  cpcTarget: 'cpcTarget',

  // step 5
  budgetRolloverEnabled: 'budgetRolloverEnabled',
  rolloverFrom: 'rolloverFrom',
  overspendControlEnabled: 'overspendControlEnabled',
  reduceSpendPercents: 'reduceSpendPercents',
  overspendControlType: 'overspendControlType',
}

export const LOCATION_BUDGET_EDIT = 'edit'
