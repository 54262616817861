import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

// components
import TextInput from 'components/ui/TextInput'
import Dropdown from 'components/ui/Dropdown'

// actions
import { getAllClients } from 'state-manager/actions/clients'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

import { getAllTeamMembers } from '../../../state-manager/actions/team-members'
import globalSpinner from '../../../utils/global-spinner'
import { useAppSelector } from 'state-manager/store'
import { FormikProps } from 'formik'
import { BudgetFormValuesType } from '..'

/* TODO functional with clients getting can be removed when clientId will be provided from clientModal through askBudgetModal instead of clientName */
const StepOne: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
}) => {
  const location = useLocation<{ clientName?: string }>()

  const dispatch = useDispatch()

  const {
    clients,
    clientsResponseWaiting,
    teamMember,
    teamMembersResponseWaiting,
  } = useAppSelector((state) => ({
    clients: state.allClients.data,
    clientsResponseWaiting: state.allClients.responseWaiting,
    teamMember: state.teamMembers.data,
    teamMembersResponseWaiting: state.teamMembers.responseWaiting,
  }))

  _useDidMount(() => {
    if (!clients.length && !clientsResponseWaiting) {
      dispatch(getAllClients())
    }
    if (!teamMember.length && !teamMembersResponseWaiting) {
      dispatch(getAllTeamMembers())
    }
  })

  if (!clients.length || clientsResponseWaiting) {
    globalSpinner.show()
    return <div>Loading...</div>
  }

  if (location?.state?.clientName) {
    values.clientId = clients
      .filter((item) => item.name === location.state.clientName)
      .reduce((prev, cur) => (prev.id > cur.y ? prev : cur), 0).id
  }

  return (
    <>
      <TextInput
        dataCy="budget name"
        label="Budget name"
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMsg={errors.name}
        placeholder="Budget name"
      />
      <br />
      <Dropdown
        dataCy="client name"
        label="Client name"
        options={clients.map((item) => ({ value: item.id, label: item.name }))}
        field={{
          name: 'clientId',
          value: values.clientId,
          onChange: (selectedOption) => setFieldValue('clientId', selectedOption.value, true),
          onBlur: handleBlur,
        }}
        disabled={!!location?.state?.clientName}
        errorMsg={errors.clientId}
        placeholder="Client name"
      />
      <br />
      <Dropdown
        dataCy="budget manager"
        label="Budget Manager"
        options={teamMember.map((item) => ({ value: item.id, label: `${item.firstName} ${item.lastName}` }))}
        field={{
          name: 'managerId',
          value: values.managerId,
          onChange: (selectedOption) => setFieldValue('managerId', selectedOption.value, true),
          onBlur: handleBlur,
        }}
        errorMsg={errors.managerId}
        placeholder="Budget Manager"
      />
    </>
  )
}

export default StepOne
