import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Input } from 'reactstrap'
import { InputType } from 'reactstrap/types/lib/Input'

// components
import InputWrapper from 'components/ui/InputWrapper'

type TextInputType = {
  type?: InputType;
  small?: boolean;
  label?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  addon?: ReactNode;
  placeholder?: string;
  optional?: boolean;
  errorMsg?: string | boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  wrapperClassName?: string;
  dataCy: string;
}

const TextInput: React.FC<TextInputType> = ({
  type = 'text',
  small,
  label,
  value,
  addon,
  placeholder,
  optional,
  errorMsg,
  disabled,
  onChange,
  name,
  onBlur,
  className,
  wrapperClassName,
  dataCy,
}) => (
  <InputWrapper
    label={label}
    optional={optional}
    errorMsg={errorMsg}
    dataCy={`wrapper-${dataCy}`}
    classNames={wrapperClassName}>
    <div className="position-relative">
      <Input
        type={type}
        className={clsx(
          'input',
          errorMsg && 'input-invalid',
          small && 'input-small',
          className,
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        id={name}
        data-cy={dataCy}
      />
      {addon !== null && (
        <span
          className="fw-regular input-addon">
          {addon}
        </span>
      )}
    </div>
  </InputWrapper>
)

export default TextInput
