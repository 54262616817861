import React from 'react'
import { useHistory } from 'react-router-dom'

// components
import StaticNotification from 'components/StaticNotification'

// routes
import routes from 'routes'

// assets
import EmailIcon from 'assets/icons/email.svg'

const ModalEmailVerification: React.FC = () => {
  const history = useHistory()
  return (
    <StaticNotification
      icon={EmailIcon}
      title="Check Mailbox"
      yes="Got it"
      onClose={() => history.push(routes.modalSignIn[0])}
      handleClick={() => history.push(routes.modalSignIn[0])}
      description="A verification link has been sent to your email account. Please click on the link to verify your email address and continue the registration process."
    />
  )
}

export default ModalEmailVerification
