import React, {FunctionComponent, SVGAttributes} from 'react'

// constants
import { DATASOURCE_GOOGLE, DATASOURCE_MICROSOFT, DATASOURCE_FACEBOOK } from 'constants/data-sources'

// assets
import MicrosoftIcon from 'assets/icons/microsoft.svg'
import GoogleIcon from 'assets/icons/google.svg'
import FacebookIcon from 'assets/icons/facebook.svg'

// classes
import classes from 'components/ui/Table/Cells/Cell.module.scss'

type propsType = {
  value: typeof DATASOURCE_MICROSOFT | typeof DATASOURCE_GOOGLE | typeof DATASOURCE_FACEBOOK
}

const CellDataSourceIcon: React.FC<propsType> = ({
  value,
}) => {
  let sourceIcon: FunctionComponent<SVGAttributes<SVGElement>>

  switch (value) {
    case (DATASOURCE_MICROSOFT):
      sourceIcon = MicrosoftIcon
      break
    case (DATASOURCE_GOOGLE):
      sourceIcon = GoogleIcon
      break
    case (DATASOURCE_FACEBOOK):
      sourceIcon = FacebookIcon
      break
  }

  return <img className={classes.imageCell} src={sourceIcon} alt="datasource-icon" />
}

export default CellDataSourceIcon
