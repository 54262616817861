import React from 'react'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'

// components
import WelcomeThumb from 'pages/Dashboard/shared/WelcomeThumb'
import Tabs from 'components/ui/Tabs'

// routes
import routes from 'routes'

// styles
import classes from 'pages/Dashboard/shared/DashboardMenu/DashboardMenu.module.scss'

const LOCATION_DASHBOARD_CHART = 'chart'

const BudgetCycleOptions = [
  {
    id: 0,
    label: 'Current budget cycle',
  },
  {
    id: 1,
    label: 'Previous budget cycle',
  },
]

type DashboardMenuType = {
  onCycleChange: (index: number) => void;
}

const DashboardMenu: React.FC<DashboardMenuType> = ({
  onCycleChange,
}) => (
  <>
    <WelcomeThumb />
    <div className="row justify-content-center mb-2">
      <Tabs
        value={BudgetCycleOptions[0].id}
        items={BudgetCycleOptions}
        onSelect={onCycleChange}
      />
    </div>
    <div className="row justify-content-start flex-column-sm align-items-center-sm mb-sm-2">
      <div className={clsx(classes.linksContainer, 'd-flex mr-3')}>
        <NavLink
          data-cy="go to clients"
          to={(location) => (location.pathname.includes(LOCATION_DASHBOARD_CHART) ? routes.dashboardClientsChart : routes.dashboardClients)}
          isActive={(match, location) => {
            return location.pathname.includes(routes.dashboardClients)
          }}
          className={clsx(classes.menuLink, 'd-block text-center fw-semibold')}
          activeClassName={clsx(classes.activeLink)}>
          Clients
        </NavLink>
        <NavLink
          data-cy="go to budgets"
          to={(location) => (location.pathname.includes(LOCATION_DASHBOARD_CHART) ? routes.dashboardBudgetsChart : routes.dashboardBudgets)}
          isActive={(match, location) => location.pathname.includes(routes.dashboardBudgets)}
          className={clsx(classes.menuLink, 'd-block text-center fw-semibold')}
          activeClassName={clsx(classes.activeLink)}>
          Budgets
        </NavLink>
      </div>
      <div className={clsx(classes.linksContainer, 'd-flex')}>
        <NavLink
          data-cy="switch to table"
          to={(location) => (location.pathname.includes(routes.dashboardBudgets) ? routes.dashboardBudgets : routes.dashboardClients)}
          isActive={(match, location) => !location.pathname.includes(LOCATION_DASHBOARD_CHART)}
          className={clsx(classes.menuLink, 'd-block text-center fw-semibold')}
          activeClassName={clsx(classes.activeLink)}>
          Table
        </NavLink>
        <NavLink
          data-cy="switch to graph"
          to={(location) => (location.pathname.includes(routes.dashboardBudgets) ? routes.dashboardBudgetsChart : routes.dashboardClientsChart)}
          isActive={(match, location) => {
            return location.pathname.includes(LOCATION_DASHBOARD_CHART)
          }}
          className={clsx(classes.menuLink, 'd-block text-center fw-semibold')}
          activeClassName={clsx(classes.activeLink)}>
          Graph
        </NavLink>
      </div>
    </div>
  </>
)

export default DashboardMenu
