import React from 'react'

// components
import Table, { cellType } from 'components/ui/Table/index'
import ModalAddNotification from 'pages/Notifications/modalAddNotification'
import TableCellCheckbox from 'pages/Notifications/table-cell-checkbox'

// hooks
import _useToggle from 'hooks/use-toggle'

// constants
import { NOTIFICATION_TYPE_EMAIL, NOTIFICATION_TYPE_PLATFORM } from 'pages/Notifications/constants'
import { MORE_THAN } from 'constants/comparison'

// assets
import CloseIcon from 'assets/icons/closeRed.svg'
import { ICustomColumn } from 'state-manager/reducers/table'

type DataTableType = {
  data: Array<cellType>
  updateNotification: (
    id: string,
    data: { emailEnabled: boolean | number, platformEnabled: boolean | number }
  ) => void
  deleteNotification: (id: string) => void
}

const DataTable: React.FC<DataTableType> = ({
  data,
  updateNotification,
  deleteNotification,
}) => {
  const [isModalOpen, toggleIsModalOpen] = _useToggle()

  const columns: Array<ICustomColumn> = React.useMemo(
    () => [
      {
        name: 'notification',
        Header: 'Notification',
        filterType: 'select',
        filterable: false,
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => {
          let string
          // eslint-disable-next-line react/prop-types
          const { pacing, comparison, type } = original
          if (type === 'pacing') {
            string = `Budget pacing ${comparison === MORE_THAN ? '>' : '<'} ${pacing}%`
          } else {
            string = 'Budget spent with at least 1 day remaining in budget cycle'
          }
          return (
            <span>{string}</span>
          )
        },
        style: { whiteSpace: 'unset', textAlign: 'center' },
      },
      {
        name: 'platformEnabled',
        Header: 'Platform',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => <TableCellCheckbox type={NOTIFICATION_TYPE_PLATFORM} original={original} updateNotification={updateNotification} />,
        width: 80,
        isHideSortOption: true,
        isHideFilter: true,
      },
      {
        name: 'emailEnabled',
        Header: 'Email',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => <TableCellCheckbox type={NOTIFICATION_TYPE_EMAIL} original={original} updateNotification={updateNotification} />,
        width: 80,
        isHideSortOption: true,
        isHideFilter: true,
      },
      {
        name: 'id',
        Header: '',
        isHideSortOption: true,
        isHideFilter: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <img src={CloseIcon} alt="delete" onClick={() => deleteNotification(value)} role="button" />,
        width: 60,
      },
    ],
    [],
  )

  return (
    <Table
      tableName="notifications"
      columns={columns}
      title="Notifications"
      data={data}>
      <>
        <div
          role="button"
          className="btn mt-sm-1"
          data-cy="add notification"
          onClick={toggleIsModalOpen}>
          Add Notification
        </div>
        {isModalOpen && (
          <ModalAddNotification onClose={toggleIsModalOpen} />
        )}
      </>
    </Table>
  )
}

export default DataTable
