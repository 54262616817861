import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from "state-manager/store"

// components
import Modal from 'components/ui/Modal'
import TextInput from 'components/ui/TextInput'
import Button from 'components/ui/Button'
import Dropdown from 'components/ui/Dropdown'

// actions
import { addClient, editClient } from 'state-manager/actions/clients'
import { addLabel } from 'state-manager/actions/labels'

// helpers
import { hasError } from 'helpers/field-has-errors'

// constants
import { ROLE_READ_ONLY_USER } from 'constants/roles'
import { CLIENT_MODAL } from 'state-manager/constants'

import routes from 'routes'

import { showGlobalModal } from 'state-manager/actions/global-modal'

type ModalAddClientType = {
  clientId?: number
}

const ModalAddClient: React.FC<ModalAddClientType> = ({
  clientId,
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {
    udID,
    allClients,
    teamMembers,
    configCurrencies,
    labels,
  } = useAppSelector((state) => ({
    udID: state.userData.id,
    allClients: state.allClients.data,
    teamMembers: state.teamMembers.data,
    configCurrencies: state.currencies.data,
    labels: state.labels.labels,
  }))

  const client = clientId && allClients.length
    ? allClients.find((client) => client.id === clientId)
    : null

  const teamMembersList = teamMembers
    .filter((item) => item.role !== ROLE_READ_ONLY_USER)
    .map((item) => ({
      value: item.id,
      label: `${item.firstName} ${item.lastName}`,
    }))

  const labelsOptions = labels.map((label) => ({
    id: label.id,
    label: label.name,
    value: label.name,
    color: label.color,
  }))

  const defaultManager = client
    ? teamMembersList.find((item) => item.label === client.manager)?.value
    : udID

  const handleCloseModal = () => {
    dispatch({
      type: CLIENT_MODAL.HIDE,
    })
  }

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      clientName: client !== null ? client.name : '',
      clientManager: defaultManager || udID,
      currencyId: client !== null ? client.currencyId : '',
      labels: client !== null ? labelsOptions.filter((item) => client.labels.includes(item.id)).map((item) => item.label) : [],
    },

    validationSchema: Yup.object({
      clientName: Yup
        .string()
        .label('Client name')
        .required(),
      currencyId: Yup
        .string()
        .label('Currency')
        .required(),
    }),

    onSubmit: (values) => {
      const data = {
        ...values,
        labels: labels.filter((item) => values.labels.includes(item.name)).map((item) => item.id),
      }
      if (client !== null) {
        dispatch(editClient({
          ...data,
          clientId: parseInt(client.id)
        }))
      } else {
        dispatch(addClient(data))
          .then(() => {
            dispatch(showGlobalModal({
              header: 'Good Job!',
              description: 'Do you want to also add a new Budget?',
              type: 'success',
              btnText: 'Add New Budget',
              btnHandler: () => history.push({ pathname: routes.addBudget, state: { clientName: values.clientName } }),
            }))
          })
      }

      handleCloseModal()
    },
  })

  const currenciesList = configCurrencies.map((currency) => ({
    value: currency.id,
    label: `${currency.symbol} ${currency.code} (${currency.name})`,
  }))

  const nameHasError = hasError('clientName', formik.touched, formik.errors)
  const currencyHasError = hasError('currencyId', formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <Modal
      withCloseButton
      onClose={handleCloseModal}
      title={clientId ? 'Edit client' : 'New Client'}>
      <div className="form-page">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group mb-3">
            <TextInput
              label="Client name"
              name="clientName"
              value={formik.values.clientName}
              errorMsg={nameHasError && formik.errors.clientName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Client name"
              dataCy="Client name input"
            />
            <br />
            <Dropdown
              label="Manager"
              placeholder="Manager"
              options={teamMembersList}
              field={{
                value: formik.values.clientManager,
                name: 'clientManager',
                onBlur: formik.handleBlur,
                onChange: (selectedOption) => {
                  const selectedVal = selectedOption.value.toString()
                  formik.handleChange('clientManager')(selectedVal)
                },
              }}
              dataCy="Manager dropdown"
            />
            <br />
            <Dropdown
              label="Currency"
              placeholder="Currency"
              errorMsg={currencyHasError && formik.errors.currencyId}
              options={currenciesList}
              field={{
                value: formik.values.currencyId,
                name: 'currencyId',
                onBlur: formik.handleBlur,
                onChange: (selectedOption) => {
                  const selectedVal = selectedOption.value.toString()
                  formik.handleChange('currencyId')(selectedVal)
                },
              }}
              dataCy="Currency dropdown"
            />
            <br />
            <Dropdown
              menuListMinimumHeight
              multiselect
              label="Labels"
              dataCy="Labels dropdown"
              options={labelsOptions}
              labelsMultiselect
              field={{
                value: formik.values.labels,
                name: 'labels',
                onBlur: formik.handleBlur,
                onChange: (selectedOption) => {
                  if (!selectedOption) {
                    formik.setFieldValue('labels', [], false)
                    return
                  }

                  selectedOption.forEach((item, index) => {
                    if (!item.id) {
                      dispatch(addLabel({ name: item.value }))
                        .then((res) => {
                          selectedOption[index] = { ...res, label: res.name, value: res.name }
                        })
                        .catch(() => {
                          selectedOption.splice(index, 1)
                        })
                    }
                  })

                  const selectedVal = selectedOption.map((item) => item.value)
                  formik.setFieldValue('labels', selectedVal, true)
                },
              }}
            />
          </div>
          <Button
            type="submit"
            dataCy="Submit New Client form"
            title={client === null ? 'Add New Client' : 'Save Changes'}
            disabled={isSubmitDisabled}
          />
        </form>
      </div>
    </Modal>
  )
}

export default ModalAddClient
