import { IBudgetContent } from "state-manager/reducers/all-budgets"
import { ILabels } from "state-manager/reducers/labels"

export const getLabelsInBudgets = (originalArray: Array<IBudgetContent>, labels: ILabels['labels']) => originalArray.map((item) => {
  const calculatedLabels = labels.filter((label) => item.client.labels.includes(label.id)).map((item) => item.name)
  return {
    ...item,
    labels: calculatedLabels,
  }
})
