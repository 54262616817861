// utils
import reqH from 'utils/request-handler'

// constants
import { INVOICE_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

export const getAllInvoices = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    url: 'users/current/stripe/invoices',
    withoutLoader: true,
  })
    .then((res) => {
      dispatch({
        type: INVOICE_ACTIONS.INVOICE_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: INVOICE_ACTIONS.INVOICE_GET_ALL.ERROR })
    })
}
