import React from 'react'
import { FormikConfig, useFormik } from 'formik'
import * as Yup from 'yup'

// components
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'
import Dropdown from 'components/ui/Dropdown'
import NumberInput from 'components/ui/NumberInput'

// helpers
import { hasError } from 'helpers/field-has-errors'

// constants
import { formFields, optionsList, type } from 'pages/Subscriptions/CustomSubscriptionForm/constants'

type CustomSubscriptionFormType = {
  currentSubscriptionType: typeof type.yearly | typeof type.monthly
  handlePlanRequest: FormikConfig<{ currencyId: number; amount: string; type: string; }>['onSubmit']
  onClose: () => void
}

const CustomSubscriptionForm: React.FC<CustomSubscriptionFormType> = ({
  currentSubscriptionType,
  handlePlanRequest,
  onClose,
}) => {
  const formik = useFormik({
    initialValues: {
      currencyId: 3,
      amount: '',
      type: currentSubscriptionType || type.monthly,
    },

    validationSchema: Yup.object({
      currencyId: Yup
        .number()
        .label('Currency')
        .required(),
      amount: Yup
        .number()
        .label('Monthly payment')
        .required(),
    }),

    onSubmit: handlePlanRequest,
  })

  const monthlyPaymentHasError = hasError('amount', formik.touched, formik.errors)
  const currencyHasError = hasError('currencyId', formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <Modal
      title="Custom Subscription"
      onClose={onClose}>
      <div className="form-page">
        <p className="text-center mb-5 fw-regular">Please enter your monthly media spend and we will provide a custom quote.</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group d-flex justify-content-between flex-column-sm">
            <div className="w-13 w-sm-20 mb-sm-2">
              <NumberInput
                label="Maximum monthly media spend"
                name="amount"
                value={formik.values.amount}
                errorMsg={monthlyPaymentHasError && formik.errors.amount}
                onChange={(val) => {
                  if (val) {
                    formik.setFieldValue(formFields.amount, val, true)
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder="Maximum monthly media spend"
              />
            </div>
            <div className="w-6 w-sm-20">
              <Dropdown
                label="Currency"
                placeholder="Currency"
                options={optionsList}
                errorMsg={currencyHasError && formik.errors.currencyId}
                field={{
                  value: formik.values.currencyId,
                  name: formFields.currencyId,
                  onBlur: formik.handleBlur,
                  onChange: (selectedOption) => formik.setFieldValue(formFields.currencyId, selectedOption.value, true),
                }}
              />
            </div>
          </div>
          <br />
          <Button
            dataCy='send request for custom subscription'
            type="submit"
            title="Send Request"
            disabled={isSubmitDisabled}
          />
        </form>
      </div>
    </Modal>
  )
}

export default CustomSubscriptionForm
