export type budgetType = {
  label: string;
  actualData: any;
  budgetData: any;
}

export const getLabels = (budgetData: budgetType[]) => budgetData.map((item) => item.label)

export const getActualData = (budgetData: budgetType[]) => budgetData.map((item) => item.actualData)

export const getBudgetData = (budgetData: budgetType[]) => budgetData.map((item) => item.budgetData)
