import React from 'react'
import { HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { Link } from 'react-router-dom'

// utils
import { getUrlIfValid } from 'utils/checkUrl'

export const replaceOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode.type === 'tag') {
      // Allow to parse only <a /> tag
      if (domNode.name === 'a' && domNode.attribs.href) {
        const url = getUrlIfValid(domNode.attribs.href)

        if (url) {
          return (
            <Link to={url.pathname}>
              {domToReact(domNode.children, replaceOptions)}
            </Link>
          )
        }
        return (
          <span className="color-red cursor-pointer">
            {domToReact(domNode.children, replaceOptions)}
          </span>
        )
      }
      return <></>
    }
  },
}
