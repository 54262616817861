import React, { useEffect, useMemo, useState } from 'react'
import { saveAs } from 'file-saver'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import DashboardMenu from 'pages/Dashboard/shared/DashboardMenu'
import ClientsTable from 'pages/Dashboard/Clients/table'
import RefreshButton from 'components/ui/RefreshButton'

// actions
import { getAllClients, getUrlForExportClientTable } from 'state-manager/actions/clients'

// helpers
import { getLabels } from 'helpers/get-labels'
import useRefreshData, { BUDGET_AND_CLIENT_TABLE_REFRESH_DELAY, checkIfDataNeedToRefresh } from 'hooks/useRefreshData'

const DashboardClients: React.FC = () => {
  const [budgetCycle, setBudgetCycle] = useState(false)

  const [
    labels,
    configCurrencies,
    clients,
    clientsResponseWaiting,
    timeLastUpdate,
  ] = useAppSelector((state) => ([
    state.labels.labels,
    state.currencies.data,
    state.allClients.data,
    state.allClients.responseWaiting,
    state.allClients.timeLastUpdate,
  ]))

  const dispatch = useAppDispatch()

  const { isDataNeedToRefresh, restoreTimer } = useRefreshData()

  useEffect(() => {
    if (timeLastUpdate && checkIfDataNeedToRefresh(new Date(timeLastUpdate), BUDGET_AND_CLIENT_TABLE_REFRESH_DELAY)) {
      dispatch(getAllClients())
        .then(() => {
          if (isDataNeedToRefresh) {
            restoreTimer()
          }
        })
    }
    //eslint-disable-next-line
  }, [])

  const clientsData = useMemo(() => (
    budgetCycle
      ? clients.map((item) => item.previousCycleMonth).filter((item) => item !== null)
      : clients
  ), [budgetCycle, clients])

  const modifiedClientsData = useMemo(() => getLabels(clientsData, labels), [clientsData, labels])

  const handleExportClientsButton = () => {
    dispatch(getUrlForExportClientTable('csv', budgetCycle))
      .then((res) => saveAs(res.url))
  }

  return (
    <>
      <DashboardMenu onCycleChange={setBudgetCycle} />
      <RefreshButton
        isDataNeedToRefresh={isDataNeedToRefresh}
        onClick={() => {
          dispatch(getAllClients()).then(restoreTimer)
        }}
      />
      <ClientsTable
        exportClients={handleExportClientsButton}
        data={modifiedClientsData}
        configCurrencies={configCurrencies}
        isLoading={clientsResponseWaiting}
      />
    </>
  )
}

export default DashboardClients
