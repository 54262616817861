import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { DATASOURCE_ACTIONS } from 'state-manager/constants'

export interface IDataSource {
  data: Array<{
    id: number,
    dataImported: number,
    email: string,
    isConnected: boolean,
    label: string,
    type: string,
    accounts: Array<{
      id: number,
      name: string,
      status: string,
      campaigns: Array<number>,
    }>,
  }>,
  responseWaiting: boolean,
  isLoaded: boolean,
}

const initialState: IDataSource = {
  data: [],
  responseWaiting: false,
  isLoaded: false,
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    dataImported: PropTypes.number,
    email: PropTypes.string,
    isConnected: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
      campaigns: PropTypes.arrayOf(PropTypes.number),
    })),
  })),
  responseWaiting: PropTypes.bool,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
        responseWaiting: false,
        isLoaded: true,
      })

    case DATASOURCE_ACTIONS.DATASOURCE_GET_ALL.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    default:
      return state
  }
}
