import React from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// components
import Button from 'components/ui/Button'
import PasswordInput from 'components/ui/PasswordInput'

// actions
import { updatePassword } from 'state-manager/actions/user'

// helpers
import { hasError } from 'helpers/field-has-errors'

// constants
import { PASSWORD } from 'constants/regex'

// styles
import classes from 'pages/Settings/ChangePassword/ChangePassword.module.scss'
import { getPasswordValidation } from 'utils/validation'

const formFields = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmNewPassword: 'confirmNewPassword',
}

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      [formFields.currentPassword]: '',
      [formFields.newPassword]: '',
      [formFields.confirmNewPassword]: '',
    },

    validationSchema: Yup.object({
      [formFields.currentPassword]: Yup
        .string()
        .label('Current password')
        .matches(PASSWORD, 'Password invalid')
        .min(8)
        .required(),
      [formFields.newPassword]: getPasswordValidation('New password'),
      [formFields.confirmNewPassword]: Yup
        .string()
        .label('Confirm new password')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required(),
    }),

    onSubmit: (values, { resetForm }) => {
      dispatch(updatePassword(values))
      resetForm()
    },
  })

  const currentPasswordHasError = hasError(formFields.currentPassword, formik.touched, formik.errors)
  const newPasswordHasError = hasError(formFields.newPassword, formik.touched, formik.errors)
  const confirmPasswordHasError = hasError(formFields.confirmNewPassword, formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <div className="form-page card w-sm-20">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <div className="row">
            <p className="fw-medium mb-3 fs-button fs-20">Change password</p>
          </div>
          <PasswordInput
            name={formFields.currentPassword}
            label="Current password"
            placeholder="Current password"
            value={formik.values[formFields.currentPassword]}
            errorMsg={currentPasswordHasError && formik.errors[formFields.currentPassword]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dataCy="enter current password"
          />
          <br />
          <PasswordInput
            name={formFields.newPassword}
            label="New password"
            placeholder="New password"
            value={formik.values[formFields.newPassword]}
            errorMsg={newPasswordHasError && formik.errors[formFields.newPassword]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dataCy="enter new password"
          />
          <br />
          <PasswordInput
            name={formFields.confirmNewPassword}
            label="Confirm new password"
            placeholder="Confirm new password"
            value={formik.values[formFields.confirmNewPassword]}
            errorMsg={confirmPasswordHasError && formik.errors[formFields.confirmNewPassword]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dataCy="repeat new password"
          />
        </div>
        <br />
        <Button
          fullWidth
          type="submit"
          title="Change Password"
          disabled={isSubmitDisabled}
          className={clsx(classes.submitButton, 'w-20')}
          dataCy="change-password-button"
        />
      </form>
    </div>
  )
}

export default ChangePassword
