import React from 'react'
import { toast } from 'react-toastify'

// components
import Notification, { notification } from 'components/Notification'

const notify = (type: notification, msg: string) => toast(
  <div className="d-flex align-items-center"><Notification type={type} />{msg}</div>,
  { icon: false, hideProgressBar: false, type },
)

export default {
  error: (msg: string) => notify(notification.error, msg),
  success: (msg: string) => notify(notification.success, msg),
  warning: (msg: string) => notify(notification.warning, msg),
}
