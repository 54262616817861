import parse from 'html-react-parser'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { USER_NOTIFICATIONS_ACTIONS } from 'state-manager/constants'
import { replaceOptions } from 'utils/parseNotification'

export interface IUserNotifications {
  data: Array<{
    createdAt: string,
    readAt: string | null,
    data: {
      budgetId: number,
      body: string,
    },
    content: string | JSX.Element | JSX.Element[]
  }>,
  hasMore: boolean,
}

const initialState: IUserNotifications = {
  data: [],
  hasMore: true,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_GET_ALL.SUCCESS:
      const parsed = action.data.map((item) => ({
        ...item,
        content: parse(item.data.body, replaceOptions),
      }))
      return updateObject(state, {
        data: [
          ...state.data,
          ...parsed,
        ],
      })

    case USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_RESET_ALL.SUCCESS:
      return updateObject(state, {
        data: state.data.map((item) => ({
          ...item,
          readAt: 'read',
        })),
      })

    case USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_HAS_MORE.SUCCESS:
      return updateObject(state, {
        hasMore: false,
      })

    default:
      return state
  }
}
