import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { clsx } from 'clsx'

// routes
import routes from 'routes'

// components
import Modal from 'components/ui/Modal'
import Input from 'components/ui/TextInput'
import Button from 'components/ui/Button'
import PasswordInput from 'components/ui/PasswordInput'

// utils
import { PASSWORD } from 'constants/regex'
import { hasError } from 'helpers/field-has-errors'
import reqH from 'utils/request-handler'

// styles
import classes from './appSumoRegistration.module.scss'

type successModalDataType = {
  isVisible: boolean,
  loginData?: {
    email: string,
    password: string,
  }
}

const AppSumoRegistration = () => {
  const [email, setEmail] = useState('')
  const history = useHistory()
  const { location } = history

  const [successModalData, setSuccessModalData] = useState<successModalDataType>({ isVisible: false })

  const params = new URLSearchParams(location.search)
  const license = params.get('license')

  const goToSignInPage = () => history.replace(routes.modalSignIn[0], { from: location.pathname })

  useEffect(() => {
    if (license) {
      reqH({
        method: 'GET',
        url: `appsumo/sign-up/${license}`,
      }).then(({ data }) => {
        if (data.signedUp) {
          goToSignInPage()
        } else {
          setEmail(data.email)
        }
      }).catch(goToSignInPage)
    }
    // eslint-disable-next-line
  }, [])

  const formik = useFormik({
    initialValues: {
      password: '',
    },

    validationSchema: Yup.object({
      password:  Yup
        .string()
        .label('Password')
        .matches(PASSWORD, (options) => `${options.label} must contain a number and a special character`)
        .min(8)
        .max(48)
        .required(),
    }),

    onSubmit: (values) => {
      reqH({
        url: 'appsumo/sign-up',
        method: 'POST',
        data: {
          email,
	        licenseUuid: license,
	        password: values.password,
        }
      }).then(() => {
        setSuccessModalData({
          isVisible: true,
          loginData: {
            email,
            password: values.password,
          },
        })
      })
    },
  })

  if (!license) {
    return (
      <Modal
        title="Invalid link!"
        withCloseButton={false}>
        <Button
          type="button"
          title="Back to Login"
          className="mt-3"
          onClick={goToSignInPage}
          dataCy="back to login"
        />
      </Modal>
    )
  }

  const passwordHasError = hasError('password', formik.touched, formik.errors)

  const handleLogin = () => {
    if (successModalData.loginData) {
      history.replace(routes.autoAuth, successModalData.loginData)
    } else {
      goToSignInPage()
    }
  }

  return (
    <>
      <div className={classes.formWrapper}>
        <form onSubmit={formik.handleSubmit}>
          <h2>Welcome to EDEE.</h2>
          <p>Your account is almost ready.</p>
          <p>Please set a password to continue.</p>
          <Input
            dataCy="email input"
            label="Email"
            name="email"
            value={email}
            disabled
            wrapperClassName={classes.email}
          />
          <PasswordInput
            dataCy="password input"
            className="mt-2"
            label="Password"
            name="password"
            value={formik.values.password}
            errorMsg={passwordHasError && formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Password"
          />
          <p className={clsx('mt-1', classes.passwordHint)}>Minimum 8 characters including a number and a special character.</p>
          <Button
            type="submit"
            title="Activate"
            className="mt-3"
            dataCy="activate button"
          />
        </form>
      </div>
      {successModalData.isVisible && (
        <Modal
          title="Congratulations!"
          withCloseButton={false}>
          <p className='text-center'>You have activated the Appsumo license</p>
          <Button
            type="button"
            title="Got it"
            className="mt-3"
            onClick={handleLogin}
            dataCy="got it button"
          />
        </Modal>
      )}
    </>
  )
}

export default AppSumoRegistration
