import React from 'react'
import { useAppDispatch } from 'state-manager/store'

// components
import StaticNotification from 'components/StaticNotification'

// actions
import { signOut } from 'state-manager/actions/auth'

// assets
import CancelIcon from 'assets/icons/cancel.svg'

type ModalConfirmLogoutType = {
  onClose: () => void
}

const ModalConfirmLogout: React.FC<ModalConfirmLogoutType> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch()
  return (
    <StaticNotification
      icon={CancelIcon}
      title="Log out"
      yes="Log out"
      no="Cancel"
      type="warning"
      onClose={onClose}
      handleClick={() => dispatch(signOut())}
      description="Are you sure you want to log out?"
    />
  )
}

export default ModalConfirmLogout
