import React from 'react'

// components
import SpriteIcon from 'components/ui/SpriteIcon'

export enum notification {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

type props = {
  type: notification;
}

const Notification: React.FC<props> = ({
  type,
}) => (
  <div className="p-1 mr-2">
    <SpriteIcon name={type === notification.success ? 'check' : 'alert'} size="md" />
  </div>
)

export default Notification
