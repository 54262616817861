// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// actions
import { getUserData } from 'state-manager/actions/user'
import { getAllLabels } from 'state-manager/actions/labels'
import { getAllBudgets } from 'state-manager/actions/budgets'
import { getAllClients } from 'state-manager/actions/clients'
import { getAllDataSources } from 'state-manager/actions/data-sources'
import { getAllTeamMembers, getAllTeamMembersRequests } from 'state-manager/actions/team-members'
import { fetchSubscription } from 'state-manager/reducers/subscription'

// constants
import { AUTH_ACTIONS, PRODUCT_FRUITS } from 'state-manager/constants'

// state-manager
import { AppDispatch, history } from 'state-manager/store'

// routes
import routes from 'routes'

const urlPrefix = 'auth'

export const loadUserData = () => (dispatch: AppDispatch) => Promise.all([
  dispatch(getAllDataSources()),
  dispatch(getAllClients()),
  dispatch(getAllBudgets()),
  dispatch(getAllTeamMembers()),
  dispatch(getAllTeamMembersRequests()),
  dispatch(fetchSubscription()),
  dispatch(getAllLabels())
]).then(() => {
  dispatch({ type: PRODUCT_FRUITS.LOAD_MAIN_DATA })
})

export const signIn = (data: Record<string, any>, { redirectTo = '' } = {}) => (dispatch: AppDispatch) => {
  dispatch({ type: AUTH_ACTIONS.SIGN_IN.ATTEMPT })

  reqH({
    method: 'POST',
    urlPrefix,
    url: 'login',
    data,
  })
    .then((res) => {
      const userData = {
        isAuthorized: true,
        authorizationData: {
          token_type: res.data.result.token_type,
          access_token: res.data.result.access_token,
        },
      }

      dispatch({
        type: AUTH_ACTIONS.SIGN_IN.SUCCESS,
        data: userData,
      })

      dispatch(getUserData())

      dispatch(loadUserData())

      if (redirectTo && redirectTo.includes(routes.panel)) {
        history.push(redirectTo)
      } else {
        history.push(routes.dashboardClients)
      }

      notification.success('Successfully logged in!')
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status === 422 && e.response.data?.validation?.['2fa']) {
          history.push({ pathname: routes.modalTwoFactorCode, state: { data } })
        }
      }

      dispatch({ type: AUTH_ACTIONS.SIGN_IN.ERROR })
    })
}

export const signUp = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: 'sign-up',
    data,
  })
    .then(() => {
      notification.success('Successfully signed up!')
      history.push(routes.modalEmailVerification)
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.SIGN_UP.SYSTEM })
    })
}

export const invitationSignUp = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: 'invitation/sign-up',
    data,
  })
    .then(() => {
      notification.success('Successfully registered!')
      history.push(routes.modalSignIn[0])
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.INVITATION_SIGN_UP.SYSTEM })
    })
}

export const forgotPassword = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: 'forgot-password',
    data,
  })
    .then(() => {
      history.push(routes.modalEmailSent)
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.FORGOT_PASSWORD.SYSTEM })
    })
}

export const resetPassword = (token: string, data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: `password-reset/${token}`,
    data,
  })
    .then(() => {
      history.push(routes.modalPasswordChanged)
    })
    .catch(() => {
      history.push(routes.modalSignIn[0])
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD.SYSTEM })
    })
}

export const signOutSimple = () => (dispatch: AppDispatch) => {
  dispatch({ type: AUTH_ACTIONS.SIGN_OUT.SYSTEM })
}

export const signOut = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: 'logout',
  })
    .finally(() => {
      dispatch(signOutSimple())

      history.push(routes.modalSignIn[0])
      window.location.reload()
    })
}

export const verifyEmail = (id: string, hash: string) => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    urlPrefix,
    url: `verification/${id}/${hash}`,
  })
    .then(() => {
      notification.success('Email verified successfully!')
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.VERIFY_EMAIL.SYSTEM })
      history.push(routes.modalSignIn[0])
    })
}

export const resendEmailVerification = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: 'verification/resend',
  })
    .then(() => {
      notification.success('Email sent!')
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.RESEND_EMAIL.SYSTEM })
    })
}

export const impersonateUser = (token: string) => (dispatch: AppDispatch) => {
  dispatch(signOutSimple())

  reqH({
    adminRequest: true,
    method: 'GET',
    url: `user/${token}/impersonate`,
  })
    .then((res) => {
      const userData = {
        isAuthorized: true,
        authorizationData: {
          token_type: res.data.result.token_type,
          access_token: res.data.result.access_token,
        },
      }

      dispatch({
        type: AUTH_ACTIONS.SIGN_IN.SUCCESS,
        data: userData,
      })

      dispatch(getUserData())
      dispatch(loadUserData())

      setTimeout(() => {
        history.push(routes.dashboardClients)
      }, 100)
    })
    .catch(() => {
      history.push(routes.modalSignIn[0])
    })
}
