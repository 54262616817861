import { HISTORY_ACTIONS } from 'state-manager/constants'

export interface IHistory {
  filters: Array<unknown>,
  pages: {
    [key: string]: Array<unknown>
  },
  currentPage: number,
  lastPage: number,
  itemsPerPage: number,
  locationList: Array<unknown>,
  eventList: Array<unknown>,
}

const initialState: IHistory = {
  filters: [],
  pages: {},
  currentPage: 1,
  lastPage: 1,
  itemsPerPage: 10,
  locationList: [],
  eventList: [],
}

const isShouldUpdateArray = (prevState: Array<any>, newValue: Array<any>) => !prevState.length && Array.isArray(newValue)

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case HISTORY_ACTIONS.GET_PAGE.SUCCESS: {
      const {
        data,
        current_page: currentPage,
        last_page: lastPage,
        per_page: itemsPerPage,
        location_list: locationList,
        event_list: eventList,
      } = action.data
      return {
        ...state,
        locationList: isShouldUpdateArray(state.locationList, locationList) ? locationList : state.locationList,
        eventList: isShouldUpdateArray(state.eventList, eventList) ? eventList : state.eventList,
        pages: {
          ...state.pages,
          [currentPage]: data,
        },
        currentPage,
        lastPage,
        itemsPerPage,
      }
    }
    case HISTORY_ACTIONS.SET_ITEMS_PER_PAGE.SUCCESS: {
      return {
        ...initialState,
        filters: state.filters,
        itemsPerPage: action.data,
      }
    }
    case HISTORY_ACTIONS.SET_FILTERS.SUCCESS: {
      return {
        ...state,
        filters: action.data,
      }
    }
    default: {
      return state
    }
  }
}
