import { COUPON_ACTIONS } from 'state-manager/constants'

export interface ICoupon {
  id: string
  name: string
  percentOff: number
}

const initialState = null

export default (state: ICoupon | null = initialState, action) => {
  switch (action.type) {
    case COUPON_ACTIONS.COUPON_GET_INFO.SUCCESS: {
      return action.data
    }
    case COUPON_ACTIONS.COUPON_REMOVE.SUCCESS:
    case COUPON_ACTIONS.COUPON_GET_INFO.SYSTEM: {
      return initialState
    }
    default: {
      return state
    }
  }
}
