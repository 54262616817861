import React, {useState} from "react"
import * as Yup from "yup"
import {useFormik} from "formik"
import clsx from "clsx"

import { hasError } from "helpers/field-has-errors"

import { useAppDispatch, useAppSelector } from "state-manager/store"
import { removeCurrentCoupon, swapCoupon } from "state-manager/actions/coupon"

import Button from "components/ui/Button"
import Modal from "components/ui/Modal"
import TextInput from "components/ui/TextInput"

import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'

import classes from './coupon.module.scss'

type CouponType = {
  onClick: () => void
  currency: string
  monthlyPrice: number
  monthlyPriceWithDiscount: number
}

const CouponFigure: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <div className={clsx(classes.container, classes.containerWithCoupon, className)}>
    <div className={clsx(classes.circle, classes.circleLeft)}/>
    <div className={clsx(classes.circle, classes.circleRight)}/>
    {children}
  </div>
)

const Coupon: React.FC<CouponType> = ({ onClick, currency, monthlyPrice, monthlyPriceWithDiscount }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  // const discount = useAppSelector((state) => state.subscriptions.data.discount)
  const coupon = useAppSelector((state) => state.coupon)

  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      coupon: '',
    },

    validationSchema: Yup.object().shape({
      coupon: Yup.string().required(),
    }),

    onSubmit: ({ coupon }) => {
      dispatch(swapCoupon(coupon))
        .then(() => setIsModalVisible(false))
    },
  })

  const nameHasError = hasError('coupon', formik.touched, formik.errors)

  const clearCoupon = () => {
    setIsModalVisible(false)
    dispatch(removeCurrentCoupon())
  }

  if (coupon) {
    return (
      <>
        <CouponFigure className={classes.mobile}>
          <div className={classes.openCouponInfo}>
            <div className="fw-semibold">Applied coupon</div>
            <div>{coupon.name}</div>
          </div>
          <Button
            dataCy='some'
            title="Edit Coupon"
            className={clsx(classes.button, 'mt-sm-2')}
            onClick={() => setIsModalVisible(true)}
          />
        </CouponFigure>
        {isModalVisible && (
          <Modal size="sm" withCloseButton={false} className={classes.modal}>
            <CouponFigure>
              <div>
                <div className="fw-semibold text-center">Applied coupon</div>
                <div className="text-center">{coupon.name}</div>
                <div className={clsx("text-center cursor-pointer fs-16", classes.rmvButton)} onClick={clearCoupon}>Remove</div>
                <div className="text-center">
                  <div className="crossedOutTextWhite d-inline-block fs-lg">
                    {currency}
                    {monthlyPrice}
                  </div>
                  <div className="d-inline-block fs-lg">
                    {currency}
                    {monthlyPriceWithDiscount}
                  </div>
                  <div className="d-inline-block fs-16">
                    / month
                  </div>
                </div>
              </div>
            </CouponFigure>
            <div className={classes.inputWrapperInsideModal}>
              <form onSubmit={formik.handleSubmit}>
                <TextInput
                  dataCy='coupon input'
                  name="coupon"
                  value={formik.values.coupon}
                  onChange={formik.handleChange}
                  label="Add a New Coupon"
                  errorMsg={nameHasError && formik.errors.coupon}
                  onBlur={formik.handleBlur}
                  wrapperClassName="mt-3"
                />
                <Button
                  type='submit'
                  dataCy='add new coupon'
                  title="Apply"
                  className="mt-3"
                  fullWidth
                />
              </form>
              <Button
                role="button"
                dataCy='cancel'
                onClick={() => setIsModalVisible(false)}
                title="Cancel"
                className={clsx(classes.cancelButton, "mt-2")}
                fullWidth
              />
            </div>
          </Modal>
        )}
      </>
    )
  }
  return (
    <div className={clsx(classes.container, classes.containerWithoutCoupon)} onClick={onClick}>
      <PlusIcon/>

      <div className="ml-1">Add a coupon</div>
    </div>
  )
}

export default Coupon
