import { useDispatch } from 'react-redux'
import { isDemoAccount, userIdSelector } from 'helpers/demo'
import { DEMO_MODAL } from 'state-manager/constants'
import { useAppSelector } from 'state-manager/store'

type HandlerType = (...params: any[]) => void

export const _useGlobalDemo = () => {
  const userId = useAppSelector(userIdSelector)
  const isDemoEnabled = isDemoAccount(userId)

  const dispatch = useDispatch()

  return <T extends HandlerType>(handler: T) => (...params: Parameters<T>) => {
    if (isDemoEnabled) {
      dispatch({ type: DEMO_MODAL.SHOW })
    } else {
      handler(...params)
    }
  }
}
