import React from 'react'
import { matchSorter } from 'match-sorter'

export const textinputFilterMethod = (filter, rows) => (
  matchSorter(rows, filter.value, { keys: [filter.id], threshold: matchSorter.rankings.CONTAINS })
)

type TextInputFilterType = {
  onChange: (value: string) => void
  filter?: { value: string }
  dataCy: string
}

const TextInputFilter: React.FC<TextInputFilterType> = ({ onChange, filter, dataCy }) => (
  <input
    type="text"
    placeholder="Search"
    onChange={(e) => onChange(e.target.value)}
    value={filter?.value ?? ''}
    className="form-control"
    data-cy={`search ${dataCy}`}
  />
)

export default TextInputFilter
