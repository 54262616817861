import { filter } from 'components/ui/Table'

type paramsType = {
  [key: string]: string | Date,
}

export default (filters?: Array<filter>) => {
  const params: paramsType = {}

  filters?.forEach(({ id, value }) => {
    if (value === 'all') {
      return
    }
    switch (id) {
      case 'userName': {
        params.user_name = value as string
        break
      }
      case 'date': {
        if (typeof value === 'object' && value.start && value.end) {
          params.start_date = value.start
          params.end_date = value.end
        }
        break
      }
      case 'source': {
        params.dataSource = value as string
        break
      }
      default: {
        params[id] = value as string
      }
    }
  })

  return params
}