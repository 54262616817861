import React from 'react'
import { useAppDispatch, useAppSelector } from 'state-manager/store'
import { FormikProps } from 'formik'

// components
import NumberInput from 'components/ui/NumberInput'

// actions
import { getSingleClient } from 'state-manager/actions/clients'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// helpers
import getCurrency from 'helpers/get-currency'

// constants
import { formFields } from 'pages/ManageBudget/constants'

import { BudgetFormValuesType } from '..'

const StepFour: React.FC<FormikProps<BudgetFormValuesType>> = ({
  values,
  setFieldValue,
  handleBlur,
  errors,
}) => {
  const dispatch = useAppDispatch()

  const { client, configCurrencies } = useAppSelector((state) => ({
    client: state.singleClient.data,
    configCurrencies: state.currencies.data,
  }))

  _useDidMount(() => {
    // const clientId = formValues[1/* step number */]?.clientId
    const clientId = values.clientId
    if (clientId) {
      dispatch(getSingleClient(clientId))
    }
  })

  return (
    <>
      <NumberInput
        optional
        dataCy="conversion target"
        label="Conversion Target"
        name={formFields.conversionTarget}
        value={values.conversionTarget}
        onChange={(val) => setFieldValue('conversionTarget', val ?? '')}
        onBlur={handleBlur}
        errorMsg={errors.conversionTarget}
        placeholder="Enter Conversion Target"
        className="mb-3"
      />
      <NumberInput
        optional
        dataCy="cost per conversion target"
        label="Cost Per Conversion Target"
        name={formFields.cpcTarget}
        value={values.cpcTarget}
        onChange={(val) => setFieldValue(formFields.cpcTarget, val ?? '')}
        onBlur={handleBlur}
        addon={'currencyId' in client ? getCurrency(client.currencyId, configCurrencies) : undefined}
        errorMsg={errors.cpcTarget}
        placeholder="Cost per conversion target"
        className="mb-3"
      />
      <NumberInput
        optional
        dataCy="ROAS target"
        label="ROAS Target (%)"
        name={formFields.roasTarget}
        value={values.roasTarget}
        onChange={(val) => setFieldValue(formFields.roasTarget, val ?? '')}
        onBlur={handleBlur}
        errorMsg={errors.roasTarget}
        addon="%"
        placeholder="ROAS Target (%)"
      />
    </>
  )
}

export default StepFour
