import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

// components
import { ReactComponent as Logo } from 'assets/icons/logo_simple.svg'
import ModalConfirmLogout from 'pages/shared/modals/ModalConfirmLogout'

// hooks
import _useToggle from 'hooks/use-toggle'

// routes
import routes from 'routes'

// styles
import classes from 'components/HeaderSimple/HeaderSimple.module.scss'

const HeaderSimple = () => {
  const [isLogoutModalOpen, toggleIsLogoutModalOpen] = _useToggle()

  return (
    <>
      <div className={clsx(classes.headerContainer, 'd-flex justify-content-between py-5 px-2 m-auto')}>
        <Link to={routes.dashboardClients} data-cy="logo - go to clients">
          <Logo />
        </Link>
        <div
          role="button"
          onClick={toggleIsLogoutModalOpen}
          data-cy="Log out"
          className="btn btn-logout color-green-fade">
          Log Out
        </div>
      </div>
      {isLogoutModalOpen && (
        <ModalConfirmLogout onClose={toggleIsLogoutModalOpen} />
      )}
    </>
  )
}

export default HeaderSimple
