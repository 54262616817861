import { ADD_TEAM_MEMBER_MODAL } from "state-manager/constants"

export interface IAddTeamMemberModal {
  isVisible: boolean,
}

type actionData = {
  type?: string,
}

const initialState: IAddTeamMemberModal = {
  isVisible: false,
}

export default (state: IAddTeamMemberModal = initialState, action: actionData = {}): IAddTeamMemberModal => {
  switch (action.type) {
    case ADD_TEAM_MEMBER_MODAL.SHOW: {
      return {
        isVisible: true,
      }
    }
    case ADD_TEAM_MEMBER_MODAL.HIDE: {
      return {
        isVisible: false,
      }
    }
    default: {
      return state
    }
  }
}
