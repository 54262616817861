import React from 'react'

import { useAppDispatch } from 'state-manager/store'

// utils
import notification from 'utils/notifications'

// components
import StaticNotification from 'components/StaticNotification'

// actions
import { deleteTeamMember } from 'state-manager/actions/team-members'

// assets
import CancelIcon from '../../../assets/icons/cancelOutline.svg'

type ModalConfirmTeamMemberDeletionType = {
  id: number | string
  onClose: () => void
  withClients: boolean
  superAdminName: string
}

const ModalConfirmTeamMemberDeletion: React.FC<ModalConfirmTeamMemberDeletionType> = ({
  id,
  onClose,
  withClients,
  superAdminName
}) => {
  const dispatch = useAppDispatch()
  return (
    <StaticNotification
      icon={CancelIcon}
      onClose={onClose}
      title="Remove Team Member"
      yes="Yes, remove"
      no="Cancel"
      type="warning"
      handleClick={() => {
        if (withClients) {
          notification.warning(`This user's clients and budgets will be assigned to ${superAdminName}, the Company Admin.`)
        }
        dispatch(deleteTeamMember(id))
        onClose()
      }}
      description="Are you sure you want to remove this team member?"
    />
  )
}

export default ModalConfirmTeamMemberDeletion
