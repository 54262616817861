import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import {
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'

// components
import Modal from 'components/ui/Modal'
import Input from 'components/ui/TextInput'
import Button from 'components/ui/Button'

// actions
import { forgotPassword } from 'state-manager/actions/auth'

// helpers
import { hasError } from 'helpers/field-has-errors'

// routes
import routes from 'routes'

// utils
import notification from 'utils/notifications'

const formFields = {
  email: 'email',
}

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const formik = useFormik({
    initialValues: {
      [formFields.email]: '',
    },

    validationSchema: Yup.object({
      [formFields.email]: Yup
        .string()
        .label('Email')
        .email()
        .required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      if (executeRecaptcha) {
        const token = await executeRecaptcha('forgotPassword')
        dispatch(forgotPassword({ ...values, recaptcha_token: token }))
      } else {
        notification.error('Something went wrong. Try again!')
      }

      resetForm()
    },
  })

  const emailHasError = hasError(formFields.email, formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <Modal
      title="Forgot Password"
      withCloseButton={false}>
      <div className="form-page">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <Input
              label="Email"
              name="email"
              value={formik.values[formFields.email]}
              errorMsg={emailHasError && formik.errors[formFields.email]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="email"
              dataCy="email"
            />
          </div>
          <br />
          <Button
            type="submit"
            title="Reset Password"
            disabled={isSubmitDisabled}
            dataCy="reset password"
          />
          <p className="fw-regular fs-main mt-4">
            Remembered?
            {' '}
            <Link to={{ pathname: routes.modalSignIn[0] }} className="text-primary fw-semibold" id="sign-in-link" data-cy="sign in">
              sign&nbsp;in
            </Link>
          </p>
        </form>
      </div>
    </Modal>
  )
}

export default ForgotPassword
