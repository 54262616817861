import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { BUDGET_ACTIONS } from 'state-manager/constants'

export interface IManageBudget {
  step: number,
  availableAmount: object,
  formValues: Array<object>,
  responseWaiting: boolean,
}

const initialState: IManageBudget = {
  step: 0,
  availableAmount: {},
  formValues: [],
  responseWaiting: false,
}

export const types = {
  step: PropTypes.number,
  availableAmount: PropTypes.object,
  formValues: PropTypes.arrayOf(PropTypes.object),
  responseWaiting: PropTypes.bool,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case BUDGET_ACTIONS.MANGE_BUDGET_SET_STEP.SUCCESS:
      return updateObject(state, {
        step: action.step,
      })

    case BUDGET_ACTIONS.BUDGET_GET_AVAILABLE_AMOUNT.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case BUDGET_ACTIONS.BUDGET_GET_AVAILABLE_AMOUNT.SUCCESS:
      return updateObject(state, {
        availableAmount: action.data,
        responseWaiting: false,
      })

    case BUDGET_ACTIONS.BUDGET_GET_AVAILABLE_AMOUNT.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case BUDGET_ACTIONS.BUDGET_SET_FORM_VALUES.SUCCESS:
      const updateFormValues = [...state.formValues]

      updateFormValues[action.data.step] = action.data.values

      return updateObject(state, {
        formValues: updateFormValues,
      })

    case BUDGET_ACTIONS.BUDGET_CLEAR_FORM_VALUES.SUCCESS:
      return updateObject(state, {
        formValues: [],
      })

    default:
      return state
  }
}
