import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

// actions
import { impersonateUser } from 'state-manager/actions/auth'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

const Impersonation = () => {
  const { token } = useParams<{ token: string }>()
  const dispatch = useDispatch()

  _useDidMount(() => {
    dispatch(impersonateUser(token))
  })

  return null
}

export default Impersonation
