import { AUTH_ACTIONS, PRODUCT_FRUITS } from "state-manager/constants"

export interface IProductFruits {
  isDataLoaded: boolean,
}

const initialState: IProductFruits = {
  isDataLoaded: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PRODUCT_FRUITS.LOAD_MAIN_DATA: {
      return {
        isDataLoaded: true,
      }
    }
    case AUTH_ACTIONS.SIGN_OUT.SYSTEM:
    case PRODUCT_FRUITS.RESET_MAIN_DATA: {
      return initialState
    }
    default: {
      return state
    }
  }
}
