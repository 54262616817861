import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useAppDispatch } from 'state-manager/store'

// components
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'
import VerifyCodeInput from 'pages/TwoFactorCode/VerifyCodeInput'

// actions
import { signIn } from 'state-manager/actions/auth'

// routes
import routes from 'routes'

const TwoFactorCode: React.FC = () => {
  const [code, setCode] = useState('')
  const history = useHistory()
  const location = useLocation<{
    data: { email: string, password: string }
  }>()

  const dispatch = useAppDispatch()

  const onSubmit = () => {
    dispatch(signIn({
      email: location.state.data.email,
      password: location.state.data.password,
      token: code,
    }))
  }

  const handleResendPassword = () => {
    dispatch(signIn({
      email: location.state.data.email,
      password: location.state.data.password,
      resendToken: true,
    }))
  }

  const isSubmitDisabled = code.length < 6

  return (
    <Modal
      title="Two-Factor Authentification"
      onClose={() => history.push(routes.modalSignIn[0])}>
      <div className="form-page">
        <p className="text-center mb-3 fw-regular">Code via SMS</p>
        <div>
          <div className="form-group">
            <VerifyCodeInput onChange={setCode} />
          </div>
          <br />
          <Button
            title="Confirm"
            disabled={isSubmitDisabled}
            onClick={onSubmit}
            dataCy="confirm button"
          />
          <br />
          <p className="text-center fw-regular">
            SMS not received?
            <button type="submit" onClick={handleResendPassword} className="transparent-btn fw-regular">Resend</button>
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default TwoFactorCode
