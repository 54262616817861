import React from 'react'
import clsx from 'clsx'

// styles
import classes from 'pages/Dashboard/shared/Chart/Chart.module.scss'

type CustomTooltipType = {
  payload: Array<{
    payload: { x: number, y: number }
    name?: 'upperLine' | 'middleLine' | 'underLine' | 'top' | 'mid'
  }>
}

const CustomTooltip: React.FC<CustomTooltipType> = ({ payload }) => {

  if (payload[0] != null) {
    const data = payload[0].payload
    const name = payload[0].name

    if (name === 'upperLine' || name === 'middleLine' || name === 'underLine') {
      return null
    }

    let status

    switch (name) {
      case 'top':
        status = 'Over'
        break
      case 'mid':
        status = 'OK'
        break
      default:
        status = 'Under'
        break
    }

    return (
      <div className={clsx(classes.tooltipContainer)}>
        <div>
          {status}
        </div>
        <div>
          Time Elapsed: {data.x}%
        </div>
        <div>
          Budget Spent: {data.y}%
        </div>
      </div>
    )
  }
  return null
}

export default CustomTooltip
