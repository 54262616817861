import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import reqH from 'utils/request-handler'

type subscriptionType = {
  status?: 'trialing' | 'active' | 'canceled' | 'incomplete' | 'incomplete_expired' | 'past_due'
  maximumMonthlyMediaSpend: string | null
  currentMonthlyMediaSpend: string | null
  currency: number | null
  renewsAt: string // string in UTC format
  cancelsAt: string | null // string in UTC format
  plan: {
    id: string | null
    plan: string | null
    monthlyMediaSpend: number | null
    period: string | null
    price: number | null
    currency: number | null
  } | null
  hasCustomPlanRequest: boolean,
  changePlanLink: string | null
}

export interface ISubscription {
  data: subscriptionType & {
    isSubscriptionEnded: boolean
    isOnTrial: boolean
    isSubscriptionActive: boolean
    isSubscriptionScheduledToCancel: boolean
  }
  isLoaded: boolean
}

export const fetchSubscription = createAsyncThunk(
  'subscription/fetch',
  async () => {
    const res = await reqH<{ result: subscriptionType }>({
      method: 'GET',
      url: 'users/current/subscription',
    })

    return res.data.result
  }
)

const initialState: ISubscription = {
  data: {
    plan: null,
    maximumMonthlyMediaSpend: null,
    currentMonthlyMediaSpend: null,
    currency: null,
    hasCustomPlanRequest: false,
    isSubscriptionEnded: false,
    isOnTrial: false,
    renewsAt: '',
    cancelsAt: null,
    changePlanLink: null,
    isSubscriptionActive: false,
    isSubscriptionScheduledToCancel: false,
  },
  isLoaded: false,
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      state.data = {
        ...action.payload,
        isOnTrial: action.payload.status === 'trialing',
        isSubscriptionEnded: action.payload.status === 'canceled'
          || action.payload.status === 'incomplete_expired'
          || action.payload.status === 'past_due',
        isSubscriptionActive: action.payload.status === 'active'
          || action.payload.status === 'incomplete',
        isSubscriptionScheduledToCancel: Boolean(action.payload.cancelsAt)
      }
      state.isLoaded = true
    })
    builder.addCase(fetchSubscription.rejected, (state) => {
      state.isLoaded = true
    })
  }
})

export default subscriptionSlice.reducer
