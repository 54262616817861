import React from 'react'
import clsx from 'clsx'

// constants
import { type } from 'pages/Subscriptions/CustomSubscriptionForm/constants'

// styles
import classes from 'pages/Subscriptions/SubscriptionPlanCard/SubscriptionPlanCard.module.scss'

type SubscriptionPlanCardType = {
  isCouponApplied?: boolean
  isFirstSubscription?: boolean
  title: string
  isYearlyCard?: boolean
  isLimitReached: boolean
  currency: string
  subscribeOnPlan: (type: string) => void
  yearlyPrice: number
  yearlyPriceWithDiscount: number
  yearlyPriceByMonth: number
  yearlyPriceWithDiscountByMonth: number
  yearlyProfit: number | ''
  monthlyPrice: number
  monthlyPriceWithDiscount: number | string
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardType> = ({
  isCouponApplied,
  isFirstSubscription,
  title,
  isYearlyCard,
  isLimitReached,
  currency,
  subscribeOnPlan,
  yearlyPrice,
  yearlyPriceWithDiscount,
  yearlyPriceByMonth,
  yearlyPriceWithDiscountByMonth,
  yearlyProfit,
  monthlyPrice,
  monthlyPriceWithDiscount,
}) => {
  const requestType = isYearlyCard ? type.yearly : type.monthly

  return (
    <div className={!isYearlyCard ? classes.card : classes.cardFilled}>
      <div className="d-flex flex-column justify-content-between fw-regular p-4 w-20 h-20">
        <p className={!isYearlyCard ? classes.title : classes.titleFilled}>
          {title}
          {isYearlyCard && <span className={classes.discount}>Save 20%</span>}
        </p>
        <div className={clsx(isYearlyCard && !isLimitReached ? classes.filledPrice : classes.price)}>
          {isYearlyCard && !isLimitReached && (
            <ul className={classes.leftSideSubTitle}>
              <li>
                {isCouponApplied && (
                  <span className="crossedOutTextSmall">{currency}{yearlyPrice}</span>
                )}
                {`${currency}${yearlyPriceWithDiscount} billed yearly`}
              </li>
              <li>Save {currency}{yearlyProfit} / year</li>
            </ul>
          )}
          <div className={classes.priceWrapper}>
            <div className={!isYearlyCard ? classes.subtitle : classes.subtitleFilled}>
              {!isLimitReached ? (
                <>
                  {isCouponApplied && (
                    <div className={isYearlyCard ? 'crossedOutTextWhite' : 'crossedOutTextGrey'}>
                      {currency}
                      {isYearlyCard ? yearlyPriceByMonth : monthlyPrice}
                    </div>
                  )}
                  <span>
                    {currency}
                    {isYearlyCard ? yearlyPriceWithDiscountByMonth : monthlyPriceWithDiscount}
                  </span>
                </>
              ) : (
                <span>Contact Us</span>
              )}
            </div>
            {!isLimitReached && <div className={!isYearlyCard ? classes.month : classes.monthFilled}>/ month</div>}
          </div>
        </div>
        <div
          role="button"
          className={clsx(!isYearlyCard && 'color-green-fade', 'btn color-white full-width color-green w-20 fw-regular')}
          onClick={() => subscribeOnPlan(requestType)}>
          {isFirstSubscription ? 'Start Your Free Trial' : 'Update'}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPlanCard
