import { useRef, useEffect } from 'react'

/* utils general */
import customEvent from 'utils/custom-event'

const _useCustomEventListener = (eventName: string, handler: EventListenerOrEventListenerObject) => {
  const savedHandler = useRef<EventListenerOrEventListenerObject>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const listener = savedHandler.current || (() => {})

      customEvent.on(eventName, listener)
      return () => {
        customEvent.off(eventName, listener)
      }
    },
    [eventName],
  )
}

export default _useCustomEventListener
