import React from 'react'

// components
import Table from 'components/ui/Table/index.tsx'
import CellStatusSelect from 'components/ui/Table/Cells/CellStatusSelect'
import CellDailyBudgetSelect from 'pages/Budgets/CellDailyBudgetSelect'

type DataTableType = {
  updateCampaignDailyBudget: (budgetId: string, campaignId: string, formatted: number) => Promise<void>;
  updateCampaignStatus: (id: string, formData: { status: string }) => void;
}

const DataTable: React.FC<DataTableType> = ({
  data,
  budgetId,
  updateCampaignStatus,
  updateCampaignDailyBudget,
  canEdit,
  openUpdate,
}) => {
  const columns = React.useMemo(
    () => [
      {
        name: 'name',
        Header: 'Campaign name',
        width: 440,
        visible: true,
        style: { whiteSpace: 'unset' },
        fixed: 'left',
      },
      {
        name: 'externalId',
        Header: 'Campaign ID',
        width: 150,
        visible: true,
        style: { whiteSpace: 'unset' },
        fixed: 'left',
      },
      {
        name: 'status',
        Header: 'Status',
        filterType: 'select',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ original }) => (canEdit && original.status !== 'unspecified' && original.status !== 'removed' ? <CellStatusSelect original={original} updateCampaignStatus={updateCampaignStatus} /> : <span>{original.status}</span>),
        width: 104,
        visible: true,
      },
      {
        name: 'dailyBudget',
        Header: 'Daily budget',
        filterType: 'numberRange',
        filterWithoutCell: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ original }) => (
          <div className="w-100 pr-1">
            <CellDailyBudgetSelect canManageBudgets={canEdit} isSharedBudget={!!original.sharedBudget} budgetId={budgetId} campaignId={original.id} value={original.dailyBudget} updateCampaignDailyBudget={updateCampaignDailyBudget} />
          </div>
        ),
        width: 160,
        visible: true,
      },
      {
        name: 'spentToDate',
        Header: 'Spend to date',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'spentYesterday',
        Header: 'Spend yesterday',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'impressions',
        Header: 'Impressions',
        filterType: 'numberRange',
        integer: true,
        width: 120,
        visible: false,
      },
      {
        name: 'clicks',
        Header: 'Clicks',
        filterType: 'numberRange',
        integer: true,
        width: 120,
        visible: false,
      },
      {
        name: 'ctr',
        Header: 'CTR',
        filterType: 'percentRange',
        width: 120,
        visible: false,
      },
      {
        name: 'averageCpc',
        Header: 'Avg CPC',
        filterType: 'numberRange',
        width: 120,
        visible: false,
      },
      {
        name: 'conversions',
        Header: 'Conversions',
        filterType: 'numberRange',
        width: 120,
        visible: false,
      },
      {
        name: 'conversionValue',
        Header: 'Conversion value',
        filterType: 'numberRange',
        width: 160,
        visible: false,
      },
      {
        name: 'conversionRate',
        Header: 'Conversion rate',
        filterType: 'percentRange',
        width: 160,
        visible: false,
      },
      {
        name: 'costPerConversion',
        Header: 'Cost per conversion',
        filterType: 'numberRange',
        width: 184,
        visible: false,
      },
      {
        name: 'roas',
        Header: 'ROAS',
        filterType: 'percentRange',
        width: 184,
        visible: false,
      },
      {
        name: 'searchImpressionShare',
        Header: 'Search impression share',
        filterType: 'percentRange',
        width: 200,
        visible: false,
      },
      {
        name: 'cycleStartDate',
        Header: 'Cycle start date',
        filterType: 'datepicker',
        width: 264,
        visible: false,
      },
      {
        name: 'cycleEndDate',
        Header: 'Cycle end date',
        filterType: 'datepicker',
        width: 264,
        visible: false,
      },
    ],
    [],
  )

  return (
    <Table
      persistable
      tableName="singleBudget"
      customizable
      title="Campaigns"
      columns={columns}
      data={data}
      updatable={canEdit}
      openUpdate={openUpdate}
    />
  )
}

export default DataTable
