export const formFields = {
  currencyId: 'currencyId',
  amount: 'amount',
  type: 'type',
}

export const optionsList = [
  {
    value: 1,
    label: 'USD',
  },
  {
    value: 3,
    label: 'GBP',
  },
]

export const type = {
  monthly: 'monthly',
  yearly: 'yearly',
}
