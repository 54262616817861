import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { useAppSelector } from 'state-manager/store'

// components
import SpriteIcon from 'components/ui/SpriteIcon'
import ModalConfirmLogout from 'pages/shared/modals/ModalConfirmLogout'
import UserNotifications from 'components/UserNotifications'

// media queries
import MQ from 'utils/mq'

// hooks
import _useToggle from 'hooks/use-toggle'

// routes
import routes from 'routes'

// styles
import classes from 'components/Header/header.module.scss'

const Header: React.FC<{
  onClick: (data: any) => void
}> = ({
  onClick,
}) => {
  const [isDropdownOpen, toggleIsDropdownOpen] = _useToggle()
  const [isLogoutModalOpen, toggleIsLogoutModalOpen] = _useToggle()

  const { company: udCompanyName, firstName: udFirstName, lastName: udLastName } = useAppSelector((state) => state.userData)

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (isDropdownOpen && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        toggleIsDropdownOpen()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [wrapperRef, isDropdownOpen, toggleIsDropdownOpen])

  return (
    <div
      className={clsx(classes.headerContainer, 'd-flex justify-content-between align-items-center py-2 px-4')}>
      <div
        className="fw-semibold color-black d-flex align-items-center">
        <MQ.LG_DOWN>
          <div role="button" className={clsx(classes.menuIconContainer, 'mr-3 p-2')} onClick={onClick} data-cy="menu">
            <SpriteIcon name="iconMenu" size="lg" />
          </div>
        </MQ.LG_DOWN>
        <MQ.SM_UP>
          <div
            className={clsx(
              classes.userName,
              'fw-semibold fs-main color-black',
            )}>
            {udCompanyName.name}
          </div>
        </MQ.SM_UP>
      </div>
      <div className="d-flex align-items-center">
        <div
          role="button"
          className="d-flex align-items-center position-relative"
          data-cy="user dropdown menu"
          onClick={toggleIsDropdownOpen}>
          <SpriteIcon name="avatar" size="xl" />

          <div
            className={clsx(
              classes.userName,
              'fw-semibold fs-main color-black',
            )}>
            {udFirstName} {udLastName}
          </div>

          <SpriteIcon name="iconDown" size="xxs" />

          {isDropdownOpen && (
            <div
              ref={wrapperRef}
              className={clsx(
                classes.dropdownContainer,
                'p-2',
              )}>
              <Link to={routes.settings} data-cy="settings">
                <div
                  className="fw-medium fs-main color-black mb-1">
                  Settings
                </div>
              </Link>
              <div role="button" data-cy="logOut" className="fw-medium fs-main color-red" onClick={toggleIsLogoutModalOpen}>
                Log out
              </div>
            </div>
          )}
        </div>

        <UserNotifications />
      </div>

      {isLogoutModalOpen && (
        <ModalConfirmLogout onClose={toggleIsLogoutModalOpen} />
      )}
    </div>
  )
}

export default Header
