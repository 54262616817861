import React, { useState } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// components
import SpriteIcon from 'components/ui/SpriteIcon'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import { _useGlobalDemo } from 'hooks/use-demo'

// utils
import notification from 'utils/notifications'
import { fetchAllDataSources, fetchDataSourceLink, fetchUserData } from 'utils/requests'

// constants
import { DATASOURCE_MICROSOFT, DATASOURCE_GOOGLE, DATASOURCE_FACEBOOK } from 'constants/data-sources'

// routes
import routes from 'routes'

import { showGlobalModal } from 'state-manager/actions/global-modal'

// styles
import classes from 'pages/AddDataSources/AddDataSources.module.scss'

const AddDataSources: React.FC = () => {
  const history = useHistory()

  const demoHOC = _useGlobalDemo()

  const [MicrosoftLink, setMicrosoftLink] = useState(null)
  const [GoogleLink, setGoogleLink] = useState(null)
  const [FacebookLink, setFacebookLink] = useState(null)

  const dispatch = useDispatch()

  _useDidMount(() => {
    const generateDataSourceLink = async (datasource: string) => {
      const res = await fetchDataSourceLink(datasource)
      return res.data.oauthLink
    }

    generateDataSourceLink(DATASOURCE_MICROSOFT)
      .then(setMicrosoftLink)

    generateDataSourceLink(DATASOURCE_GOOGLE)
      .then(setGoogleLink)

    generateDataSourceLink(DATASOURCE_FACEBOOK)
      .then(setFacebookLink)
  })

  const w = Number(window.screen.width / 2)
  const h = Number(window.screen.height / 2)
  const left = Number((window.screen.width / 2) - (w / 2))
  const tops = Number((window.screen.height / 2) - (h / 2))

  const openDataSourceLink = (dataSourceLink: string | null) => {
    if (dataSourceLink) {
      window.open(dataSourceLink, '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${tops}, left=${left}`)
    }
  }

  window.onmessage = (e) => {
    if (e.data.message) {
      if (parseInt(e.data.error) && e.data.provider === 'google') {
        dispatch(showGlobalModal({
          type: 'error',
          header: 'Error!',
          description: 'Please grant all requested permissions, including access to Google Ads accounts and data.',
          btnText: 'Try again',
          btnHandler: () => {
            openDataSourceLink(GoogleLink)
          },
        }))
        return
      }

      if (parseInt(e.data.error)) {
        notification.error(e.data.message)
        return
      }

      notification.success('Please note it can take up to 15 minutes to gather the advertising data for newly added sources. You may close this window.')
      fetchAllDataSources(dispatch)
      fetchUserData(dispatch)
        .then(() => {
          history.push(routes.dataSources)
        })
    }
  }

  return (
    <div className="text-center">
      <h1 className="fw-semibold fs-lg mb-4">Add Data Source</h1>
      <div className="d-flex justify-content-center flex-column-sm">
        <div className={clsx(classes.addDataSourceWrapper, classes.addDataSourceWrapperLeft, 'd-flex flex-column align-items-center p-2 pt-3 mr-3')}>
          <SpriteIcon name="microsoft" size="xxl" />
          <div
            role="button"
            className="btn btn-datasources mt-2"
            data-cy="add microsoft"
            onClick={demoHOC(() => openDataSourceLink(MicrosoftLink))}>
            Add
          </div>
        </div>

        <div className={clsx(classes.addDataSourceWrapper, classes.addDataSourceWrapperLeft, 'd-flex flex-column align-items-center p-2 pt-3 mr-3')}>
          <SpriteIcon name="google" size="xxl" />
          <div
            role="button"
            className="btn btn-datasources mt-2"
            data-cy="add google"
            onClick={demoHOC(() => openDataSourceLink(GoogleLink))}>
            Add
          </div>
        </div>

        <div className={clsx(classes.addDataSourceWrapper, 'd-flex flex-column align-items-center p-2 pt-3')}>
          <SpriteIcon name="facebook" size="xxl" />
          <div
            role="button"
            className="btn btn-datasources mt-2"
            data-cy="add facebook"
            onClick={demoHOC(() => openDataSourceLink(FacebookLink))}>
            Add
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddDataSources
