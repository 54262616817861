export const ENV = process.env.REACT_APP_ENV

export const API_URL = process.env.REACT_APP_API_URL

export const DATA_URL = process.env.REACT_APP_DATA_URL
export const CONFIG_URL = `${DATA_URL}${process.env.REACT_APP_CONFIG_PATH}`
export const UPLOADS_URL = `${DATA_URL}${process.env.REACT_APP_UPLOADS_PATH}`

export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
