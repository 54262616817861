import React from 'react'
import clsx from 'clsx'

// styles
import classes from 'components/ui/Button/Button.module.scss'

interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dataCy: string;
  colorRed?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<CustomButtonProps> = ({
  title,
  className = '',
  colorRed = false,
  fullWidth = false,
  dataCy,
  ...restProps
}) => (
  <button
    type="button"
    data-cy={dataCy}
    {...restProps}
    className={clsx(classes.button, fullWidth && classes.fullWidth, colorRed && classes.buttonColorRed, className)}>
    {title}
  </button>
)

export default Button
