import React from 'react'

// components
import StaticNotification from 'components/StaticNotification'

// assets
import WarningIcon from 'assets/icons/warning.svg'

type ModalConfirmDeleteType = {
  onClick: () => void
  onClose: () => void
  entity: 'client' | 'budget'
}

const ModalConfirmDelete: React.FC<ModalConfirmDeleteType> = ({
  onClick,
  onClose,
  entity,
}) => (
  <StaticNotification
    icon={WarningIcon}
    title={`Delete ${entity}`}
    yes="Yes, delete"
    no="Cancel"
    type="warning"
    description={`Are you sure you want to delete this ${entity}?`}
    handleClick={onClick}
    onClose={onClose}
  />
)

export default ModalConfirmDelete
