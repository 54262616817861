import React, { useState, useRef, createRef, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import { format, compareAsc, subDays, addDays, isWithinInterval, startOfDay } from 'date-fns'

import clsx from 'clsx'
import { getDateWithoutTimeZone } from 'helpers/getDateWithoutTimeZone'

import classes from '../Table.module.scss'

export const datepickerFilterMethod = (filter, row) => {
  const startDate = filter.value.start
  const endDate = filter.value.end

  if (!startDate || !endDate /* || startDate === 'all' || endDate === 'all' */) {
    return true
  }

  // if (row?.selected) {
  //   return true
  // }

  return isWithinInterval(startOfDay(new Date(row[filter.id])), { start: startDate, end: endDate })
}

/* eslint-disable react/prop-types */
const CustomDatepickerFilterInput = forwardRef(({
  onClear,

  setStartDate,
  startDate,
  setEndDate,
  endDate,

  onClick,
  onChange,
  placeholder,
}, ref) => {
  const formattedStartDate = startDate ? format(startDate, 'MM.dd.yyyy') : ''
  const formattedEndDate = endDate ? format(endDate, 'MM.dd.yyyy') : ''

  let value = ''
  if (startDate) {
    value += `${formattedStartDate}`
  }
  if (startDate && endDate && (startDate.toString() !== endDate.toString())) {
    value += ` | ${formattedEndDate}`
  }

  const clear = () => {
    onClear(null)
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <>
      <input
        onClick={onClick}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        title={value}
        ref={ref}
        className="form-control"
      />
      {value && (
        <button
          className="react-datepicker__close-icon"
          type="button"
          onClick={clear}
        />
      )}
    </>
  )
})

const options = ['Custom', 'Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days']
const maxDate = new Date()

const CalendarContainer = ({ className, onApply, range, setRange, children }) => (
  <div className={clsx(className, classes.calendarContainer)}>
    {children}
    <div className={classes.datePickerDropdownWrapper}>
      <select
        data-cy="select date range"
        onChange={(e) => setRange(e.target.value)}
        value={range}
        className="form-control">
        {options.map((option, index) => (
          <option key={index} value={option} data-cy={`date range option - ${option}`}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </option>
        ))}
      </select>
      <button type="button" onClick={onApply} className={clsx(classes.apply, 'cursor-pointer')}>Apply</button>
    </div>
  </div>
)

export const DatepickerFilter = ({ onChange, dates = [], filter = null }) => {

  const processedDates = dates.filter((date) => Date.parse(date)).map((date) => new Date(date)).sort(compareAsc)

  const minDate = subDays(processedDates[0], 1)
  const maxDate = addDays(processedDates[processedDates.length - 1], 1)

  let datepickerRef = useRef(null)

  const [startDate, setStartDate] = useState(filter ? filter.value.start : null)
  const [endDate, setEndDate] = useState(filter ? filter.value.end : null)

  const onDateChange = (dates) => {
    if (!dates) {
      // onChange({ start: 'all', end: 'all' })
      onChange({ start: '', end: '' })
      return
    }

    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)

    if (start && end) {
      datepickerRef.setOpen(false)
      onChange({ start, end })
    }
  }

  const selected = startDate || ''

  return (
    <DatePicker
      preventOverflow={false}
      minDate={new Date(minDate)}
      maxDate={new Date(maxDate)}
      highlightDates={processedDates}

      selected={selected}
      onChange={onDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange

      shouldCloseOnSelect={false}
      placeholderText="Select date"
      /* eslint-disable-next-line no-return-assign */
      ref={(r) => datepickerRef = r}

      customInput={(
        <CustomDatepickerFilterInput
          onClear={onDateChange}

          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}

          ref={createRef()}
        />
      )}
    />
  )
}

const customHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}) => (
  <div>
    <button
      aria-label="Previous Month"
      type="button"
      className="react-datepicker__navigation react-datepicker__navigation--previous"
      style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
      onClick={decreaseMonth}>
      <span
        className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
        {'<'}
      </span>
    </button>
    <span className="react-datepicker__current-month">
      {monthDate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      })}
    </span>
    <button
      aria-label="Next Month"
      type="button"
      className="react-datepicker__navigation react-datepicker__navigation--next"
      style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
      onClick={increaseMonth}>
      <span
        className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
        {'>'}
      </span>
    </button>
  </div>
)

export const NewDatePickerFilter = ({ onChange, filter = null }) => {
  let datepickerRef = useRef(null)

  const [startDate, setStartDate] = useState(filter ? filter.value.start : null)
  const [endDate, setEndDate] = useState(filter ? filter.value.end : null)
  const [rangeState, setRangeState] = useState(options[0])

  const onDateChange = (dates) => {
    if (!dates) {
      onChange({ start: '', end: '' })
      return
    }

    const [start, end] = dates

    end?.setHours(23, 59, 59, 0)

    setStartDate(start)
    setEndDate(end)

    if (start && end) {
      datepickerRef.setOpen(false)
      onChange({ start, end })
    }
  }

  const selected = startDate || ''

  const handlePickRange = () => {
    let dates
    const today = getDateWithoutTimeZone()

    switch (rangeState) {
      case options[1]: {
        const dateEnd = getDateWithoutTimeZone()
        dates = [today, dateEnd]
        break
      }
      case options[2]: {
        const dateStart = getDateWithoutTimeZone()
        const dateEnd = getDateWithoutTimeZone()
        dateStart.setDate(dateStart.getDate() - 1)
        dateEnd.setDate(dateEnd.getDate() - 1)
        dates = [dateStart, dateEnd]
        break
      }
      case options[3]: {
        const oneWeekAgo = getDateWithoutTimeZone()
        oneWeekAgo.setDate(today.getDate() - 7)
        dates = [oneWeekAgo, today]
        break
      }
      case options[4]: {
        const oneMonthAgo = getDateWithoutTimeZone()
        oneMonthAgo.setMonth(today.getMonth() - 1)
        dates = [oneMonthAgo, today]
        break
      }
      default: {
        return
      }
    }
    onDateChange(dates)
  }

  const calendarContainer = (props) => <CalendarContainer onApply={handlePickRange} range={rangeState} setRange={setRangeState} {...props} />

  return (
    <DatePicker
      preventOverflow={false}
      maxDate={maxDate}
      calendarContainer={calendarContainer}

      selected={selected}
      onChange={onDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange

      shouldCloseOnSelect={false}
      placeholderText="Select date"
      ref={(r) => { datepickerRef = r }}
      monthsShown={2}

      renderCustomHeader={customHeader}
      customInput={(
        <CustomDatepickerFilterInput
          onClear={onDateChange}

          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}

          ref={createRef()}
        />
      )}
    />
  )
}
