import React from 'react'

import SpriteIcon from 'components/ui/SpriteIcon'

type TableCellRemoveType = {
  value: number;
  onRemove: (value: number) => void;
}

const TableCellRemove: React.FC<TableCellRemoveType> = ({
  value,
  onRemove,
}) => (
  <div
    className="d-flex align-items-center">
    <div
      role="button"
      data-cy="Remove data source"
      onClick={() => onRemove(value)}>
      <SpriteIcon name="remove" size="md" />
    </div>
  </div>
)

export default TableCellRemove
