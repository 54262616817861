import React, {PropsWithChildren} from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// routes
import routes from 'routes'

// pages
import SignIn from 'pages/SignIn'
import SignUp from 'pages/SignUp'
import TwoFactorCode from 'pages/TwoFactorCode'
import ChangePassword from 'pages/ChangePassword'
import ForgotPassword from 'pages/ForgotPassword'
import ModalPasswordChanged from 'pages/ModalPasswordChanged'
import ModalEmailSent from 'pages/ModalEmailSent'
import ConfirmEmailVerification from 'pages/ConfirmEmailVerification'
import ModalEmailVerification from 'pages/ModalEmailVerification'
import Impersonation from 'pages/Impersonation'
import Subscriptions from 'pages/Subscriptions'
import AppSumoRegistration from 'pages/AppSumoRegistration'
import AutoAuth from 'pages/AutoAuth'

import { signOut } from 'utils/requests'

const GoogleReCaptchaProviderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  // @ts-expect-error GoogleReCaptchaProvider accepts children
  return <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>{children}</GoogleReCaptchaProvider>
}

const UnauthorizedAccess = () => {
  const location = useLocation()

  const dispatch = useAppDispatch()

  const udIsAuthorized = useAppSelector((state) => state.userData.isAuthorized)

  return (
    <Route
      render={() => {
        if (udIsAuthorized && location.pathname === routes.appSumoRegistration) {
          signOut(dispatch)
          return null
        }
        if (!udIsAuthorized) {
          return (
            <GoogleReCaptchaProviderWrapper>
              <Switch>
                <Route exact path={routes.modalSignIn} component={SignIn} />
                <Route exact path={routes.modalSignUp} component={SignUp} />
                <Route exact path={routes.modalTwoFactorCode} component={TwoFactorCode} />
                <Route exact path={routes.modalChangePassword} component={ChangePassword} />
                <Route exact path={routes.modalForgotPassword} component={ForgotPassword} />
                <Route exact path={routes.modalPasswordChanged} component={ModalPasswordChanged} />
                <Route exact path={routes.modalEmailSent} component={ModalEmailSent} />
                <Route exact path={routes.modalVerification} component={ConfirmEmailVerification} />
                <Route exact path={routes.modalEmailVerification} component={ModalEmailVerification} />
                <Route exact path={routes.impersonate} component={Impersonation} />
                <Route exact path={routes.appSumoRegistration} component={AppSumoRegistration} />
                <Route exact path={routes.autoAuth} component={AutoAuth} />

                <Route exact path={routes.subscriptionsIframe} component={Subscriptions} />

                <Redirect to={{ pathname: routes.modalSignIn[0], state: { from: location.pathname } }} />
              </Switch>
            </GoogleReCaptchaProviderWrapper>
          )
        }

        if (location.pathname.includes(routes.impersonate.replace(/:.*/, ''))) {
          const locationPartsArray = location.pathname.split('/')
          const token = locationPartsArray[locationPartsArray.length - 1]

          return (
            <Redirect to={{ pathname: formatRoute(routes.panel + routes.impersonate, { token }) }} />
          )
        }

        return (
          <Redirect to={{ pathname: routes.panel }} />
        )
      }}
    />
  )
}

export default UnauthorizedAccess
