// utils
import reqH from 'utils/request-handler'

import notification from 'utils/notifications'

// constants
import { USER_NOTIFICATIONS_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

const urlPrefix = 'notification'

export const getAllUserNotifications = (pageId: number) => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    url: `${urlPrefix}?page=${pageId}`,
  })
    .then((res) => {
      let data
      if (Array.isArray(res.data)) {
        data = res.data
      } else if (Array.isArray(res.data.result)) {
        data = res.data.result
      } else {
        throw new Error('Unable to load notifications')
      }
      if (data.length === 0) {
        dispatch({
          type: USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_HAS_MORE.SUCCESS,
        })
      }
      dispatch({
        type: USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_GET_ALL.SUCCESS,
        data,
      })
    })
    .catch(() => {
      notification.error('Unable to load notifications')
      dispatch({ type: USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_GET_ALL.ERROR })
    })
}

export const getUserNotificationUnreadStatus = () => reqH<{ result: number }>({
  method: 'GET',
  urlPrefix,
  url: 'unread/exist',
})

export const resetUserNotifications = () => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url: urlPrefix,
})
  .then(() => {
    dispatch({ type: USER_NOTIFICATIONS_ACTIONS.USER_NOTIFICATION_RESET_ALL.SUCCESS })
  })
