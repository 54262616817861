import React from 'react'
import clsx from 'clsx'
import { Bar } from 'react-chartjs-2'

// helpers
import { getLabels, getActualData, getBudgetData, budgetType } from 'components/ui/Charts/helpers'

// styles
import classes from 'components/ui/Charts/chart.module.scss'

type props = {
  budgetData: Array<budgetType>;
}

const BarChart: React.FC<props> = ({
  budgetData,
}) => {
  const labels = getLabels(budgetData)
  const budgetedSpend = getBudgetData(budgetData)
  const actualSpend = getActualData(budgetData)

  const maxYAxe = Math.ceil(Math.max(...budgetedSpend, ...actualSpend) / 10) * 10
  const stepSize = maxYAxe / 2

  const data = {
    labels,
    datasets: [{
      label: 'Daily Spend',
      type: 'line',
      data: actualSpend,
      fill: false,
      borderColor: '#71B37C',
      backgroundColor: '#71B37C',
      pointBorderColor: '#71B37C',
      pointBackgroundColor: '#71B37C',
      pointHoverBackgroundColor: '#71B37C',
      pointHoverBorderColor: '#71B37C',
    }, {
      type: 'bar',
      label: 'Budget',
      data: budgetedSpend,
      fill: false,
      backgroundColor: '#BABABA',
      borderColor: '#BABABA',
      hoverBackgroundColor: '#878787',
      hoverBorderColor: '#878787',
    }],
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0,
          max: maxYAxe,
          stepSize,
        },
      }],
    },
  }

  return (
    <div className={clsx(classes.graphContainer)}>
      <Bar
        data={data}
        width={1920}
        height={200}
        options={options}
      />
    </div>
  )
}

export default React.memo(BarChart)
