import { useEffect, useRef } from 'react'

const _useDidUpdate = (fn: () => void, conditions: Array<unknown>) => {
  const didMountRef = useRef(false)
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }

    // Cleanup effects when f returns a function
    return fn && fn()
  }, conditions)
}

export default _useDidUpdate
