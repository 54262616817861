import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "state-manager/store"

import AppSumoBilling from './AppSumoBilling'
import StripeBilling from './StripeBilling'

import { fetchSubscription } from 'state-manager/reducers/subscription'

const appSumoIds = ['edee_tier1', 'edee_tier2', 'edee_tier3', 'edee_tier4']

const Invoices = () => {  
  const dispatch = useAppDispatch()

  const subscriptionId = useAppSelector((state) => state.subscriptions.data?.plan?.id)

  useEffect(() => {
    if (!subscriptionId) {
      dispatch(fetchSubscription())
    }
    //eslint-disable-next-line
  }, [])

  if (!subscriptionId) {
    return null
  }

  return appSumoIds.includes(subscriptionId)
    ? <AppSumoBilling selectedPlanId={subscriptionId} />
    : <StripeBilling />
}

export default Invoices
