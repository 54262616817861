import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
// @ts-expect-error no d.ts found for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'

// components
import Table from 'components/ui/Table/index'

// helpers
import transformCurrencies, { tableDataType } from 'helpers/transform-currency'

// routes
import routes from 'routes'

import { CurrencyType } from 'constants/application-configs'
import { ICustomColumn } from "state-manager/reducers/table"

type BudgetsTableType = {
  data: Array<tableDataType>;
  configCurrencies: Array<CurrencyType>;
  exportBudgets: () => void;
  cycle: boolean;
  isLoading: boolean;
}

const BudgetsTable: React.FC<BudgetsTableType> = ({
  data,
  configCurrencies,
  exportBudgets,
  cycle,
  isLoading,
}) => {
  const columns = React.useMemo<Array<ICustomColumn>>(
    () => [
      {
        name: 'name',
        Header: 'Budget name',
        Cell: ({ original }) => <Link className="fw-semibold color-green" to={{ pathname: formatRoute(routes.budgets, { id: original.id }) }} data-cy={original.name}>{original.name}</Link>,
        width: 264,
        visible: true,
        style: { whiteSpace: 'unset' },
        className: 'contentOverflow',
        fixed: 'left',
      },
      {
        name: 'clientName',
        Header: 'Client name',
        accessor: (data) => data.client.name,
        Cell: ({ original }) => <Link className="fw-semibold color-green" to={{ pathname: formatRoute(routes.clients, { id: original.client.id }) }} data-cy={original.client.name}>{original.client.name}</Link>,
        width: 264,
        visible: true,
        style: { whiteSpace: 'unset' },
        className: 'contentOverflow',
        fixed: 'left',
      },
      {
        name: 'status',
        Header: 'Status',
        filterType: 'select',
        width: 104,
        Cell: ({ value }) => <span className={`text-capitalize color-${value.toLowerCase() === 'active' ? 'green' : 'red'}`}>{value.toLowerCase()}</span>,
        visible: true,
      },
      {
        name: 'currency',
        Header: 'Currency',
        filterType: 'select',
        customOptions: ['£ GBP', '€ EUR', '$ USD'],
        width: 144,
        visible: true,
      },
      {
        name: 'manager',
        Header: 'Manager',
        accessor: (data) => (data.manager ? `${data.manager.firstName} ${data.manager.lastName}` : ''),
        width: 200,
        visible: true,
      },
      {
        name: 'pacing',
        Header: 'Pacing',
        filterType: 'numberRange',
        accessor: (data) => (data.pacing === 'N/A' ? 0 : data.pacing),
        filterTypeColored: true,
        width: 144,
        visible: true,
      },
      {
        name: 'cycleBudget',
        Header: 'Cycle Budget',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'rolloverBudget',
        Header: 'Rollover budget',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'budgetTarget',
        Header: 'Budget target',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'suggestedDailyBudget',
        Header: 'Suggested daily budget',
        filterType: 'numberRange',
        width: 200,
        visible: true,
      },
      {
        name: 'totalDailyBudget',
        Header: 'Total daily budget',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'spentToDate',
        Header: 'Spend to date',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'spentYesterday',
        Header: 'Spend yesterday',
        filterType: 'numberRange',
        width: 160,
        visible: true,
      },
      {
        name: 'conversionVTarget',
        accessor: (data) => (data.conversionVTarget),
        Header: 'Conversions vs target',
        filterType: 'percentRange',
        filterTypeColoredCustom: true,
        filterColoredValues: {
          min: 80,
          between: {
            min: 80,
            max: 90,
          },
          max: 90,
        },
        width: 200,
        visible: false,
      },
      {
        name: 'cpcVTarget',
        accessor: (data) => (data.cpcVTarget),
        Header: 'Cost per conversion vs target',
        filterType: 'percentRange',
        filterTypeColoredCustom: true,
        filterColoredValues: {
          min: 110,
          between: {
            min: 110,
            max: 119,
          },
          max: 119,
          isInvertedColors: true,
        },
        width: 224,
        visible: false,
      },
      {
        name: 'roasVTarget',
        accessor: (data) => (data.roasVTarget),
        Header: 'ROAS vs. target',
        filterType: 'percentRange',
        filterTypeColoredCustom: true,
        filterColoredValues: {
          min: 80,
          between: {
            min: 80,
            max: 90,
          },
          max: 90,
        },
        width: 160,
        visible: false,
      },
      {
        name: 'impressions',
        Header: 'Impressions',
        filterType: 'numberRange',
        integer: true,
        width: 160,
        visible: false,
      },
      {
        name: 'clicks',
        Header: 'Clicks',
        filterType: 'numberRange',
        integer: true,
        width: 160,
        visible: false,
      },
      {
        name: 'ctr',
        Header: 'CTR',
        filterType: 'percentRange',
        width: 160,
        visible: false,
      },
      {
        name: 'averageCpc',
        Header: 'Avg CPC',
        filterType: 'numberRange',
        width: 160,
        visible: false,
      },
      {
        name: 'conversions',
        Header: 'Conversions',
        filterType: 'numberRange',
        width: 160,
        visible: false,
      },
      {
        name: 'conversionRate',
        Header: 'Conversion rate',
        filterType: 'percentRange',
        width: 160,
        visible: false,
      },
      {
        name: 'costPerConversion',
        Header: 'Cost per conversion',
        filterType: 'numberRange',
        width: 160,
        visible: false,
      },
      {
        name: 'conversionValue',
        Header: 'Conversion value',
        filterType: 'numberRange',
        width: 160,
        visible: false,
      },
      {
        name: 'roas',
        Header: 'ROAS',
        filterType: 'percentRange',
        width: 160,
        visible: false,
      },
      {
        name: 'searchImpressionShare',
        Header: 'Search impression share',
        filterType: 'percentRange',
        width: 200,
        visible: false,
      },
      {
        name: 'cycleStartDate',
        Header: 'Cycle start date',
        filterType: 'datepicker',
        width: 264,
        visible: false,
      },
      {
        name: 'cycleEndDate',
        Header: 'Cycle end date',
        filterType: 'datepicker',
        width: 264,
        visible: false,
      },
      {
        name: 'labels',
        Header: 'Labels',
        filterType: 'multi-select',
        width: 200,
        visible: false,
      },
    ],
    [],
  )

  const tableData = useMemo(
    () => transformCurrencies(data, configCurrencies),
    [configCurrencies, data],
  )

  return (
    <Table
      persistable
      tableName="dashboardBudgets"
      exportHandle={exportBudgets}
      customizable
      columns={columns}
      data={tableData}
      pageSize={5}
      actionsUrl="budgets"
      cycle={cycle}
      isLoading={isLoading}
    />
  )
}

export default BudgetsTable
