import React from 'react'
import clsx from 'clsx'
import { Input } from 'reactstrap'

// styles
import classes from 'components/ui/RadioButton/RadioButton.module.scss'

type propsType = {
  name: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  dataCy: string;
}

const RadioButton: React.FC<propsType> = ({
  name,
  checked,
  onChange,
  dataCy,
}) => (
  <div className="d-flex flex-column">
    <Input
      className={clsx(
        classes.radioButtonInput,
      )}
      type="radio"
      name={name}
      checked={checked}
      onChange={onChange}
      data-cy={dataCy}
    />
  </div>
)

export default RadioButton
