import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'

// components
import Table from 'components/ui/Table/index'

// helpers
import transformCurrencies from 'helpers/transform-currency'

// constants
import { ACTIVE } from 'constants/status'

// routes
import routes from 'routes'

// proptypes
import { CurrencyType } from 'constants/application-configs'

const ClientCell = ({ original }) => <Link className="fw-semibold color-green" to={{ pathname: formatRoute(routes.clients, { id: original.id }) }} data-cy={original.name}>{original.name}</Link>
const StatusCell = ({ value }) => <span className={clsx(value === ACTIVE ? 'color-green' : 'color-red', 'text-capitalize')}>{value}</span>

const columns = [
  {
    name: 'name',
    Header: 'Client name',
    Cell: ClientCell,
    width: 264,
    visible: true,
    style: { whiteSpace: 'unset' },
    fixed: 'left',
  },
  {
    name: 'status',
    Header: 'Status',
    filterType: 'select',
    width: 104,
    Cell: StatusCell,
    visible: true,
  },
  {
    name: 'currency',
    Header: 'Currency',
    filterType: 'select',
    width: 120,
    visible: true,
  },
  {
    name: 'manager',
    Header: 'Manager',
    filterType: 'select',
    width: 200,
    visible: true,
  },
  {
    name: 'pacing',
    Header: 'Pacing',
    filterType: 'numberRange',
    filterTypeColored: true,
    width: 144,
    visible: true,
  },
  {
    name: 'cycleBudget',
    Header: 'Cycle Budget',
    filterType: 'numberRange',
    width: 144,
    visible: true,
  },
  {
    name: 'rolloverBudget',
    Header: 'Rollover budget',
    filterType: 'numberRange',
    width: 144,
    visible: true,
  },
  {
    name: 'budgetTarget',
    Header: 'Budget target',
    filterType: 'numberRange',
    width: 144,
    visible: true,
  },
  {
    name: 'spent',
    Header: 'Spend',
    filterType: 'numberRange',
    width: 144,
    visible: true,
  },
  {
    name: 'labels',
    Header: 'Labels',
    filterType: 'multi-select',
    width: 200,
    visible: true,
  },
]

type ClientTableType = {
  data: Array<any>;
  isLoading: boolean;
  configCurrencies: CurrencyType[],
  exportClients: () => void;
}

const ClientsTable: React.FC<ClientTableType> = ({
  data,
  configCurrencies,
  exportClients,
  isLoading = false,
}) => {
  const tableData = useMemo(() => transformCurrencies(data, configCurrencies), [configCurrencies, data])

  return (
    <Table
      persistable
      tableName="dashboardClients"
      exportHandle={exportClients}
      customizable
      columns={columns}
      data={tableData}
      isLoading={isLoading}
    />
  )
}

export default ClientsTable
