const ATTEMPT = 'ATTEMPT'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'
const SYSTEM = 'SYSTEM'

const constantStates = [ATTEMPT, SUCCESS, ERROR, SYSTEM]

type constantsSetType = {
  [key: string] : string,
}
type resultType<T> = {
  [K in keyof T]: {
    ATTEMPT: string,
    SUCCESS: string,
    ERROR: string,
    SYSTEM: string,
  }
 }

export default function<T extends constantsSetType>(constantsSet: T): resultType<T> {
  const newConstantsSet = {}
  Object.keys(constantsSet).forEach((constantName) => {
    const constantValue = constantsSet[constantName]
    constantStates.forEach((constantState) => {
      newConstantsSet[constantName] = { ...newConstantsSet[constantName] }
      newConstantsSet[constantName][constantState] = `${constantValue}/${constantState}`
    })
  })

  return newConstantsSet as resultType<T>
}
