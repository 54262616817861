import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import InfiniteScroll from 'react-infinite-scroller'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// actions
import { getUserNotificationUnreadStatus, getAllUserNotifications, resetUserNotifications } from 'state-manager/actions/user-notifications'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import SpriteIcon from 'components/ui/SpriteIcon'

// styles
import classes from 'components/UserNotifications/UserNotifications.module.scss'

const UserNotifications: React.FC = () => {
  const [isUserNotificationsOpen, setIsUserNotificationsOpen] = useState(false)
  const [isNewUserNotifications, setNewUserNotifications] = useState(false)

  const { userNotifications, hasMoreNotifications } = useAppSelector((state) => ({
    userNotifications: state.userNotifications.data,
    hasMoreNotifications: state.userNotifications.hasMore,
  }))

  const dispatch = useAppDispatch()

  const notificationsContainer = useRef<HTMLDivElement>(null)

  _useDidMount(() => {
    getUserNotificationUnreadStatus()
      .then((response) => {
        setNewUserNotifications(!!response.data.result)
      })
  })

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (isUserNotificationsOpen && notificationsContainer.current && !notificationsContainer.current.contains(event.target as Node)) {
        event.stopPropagation()
        setIsUserNotificationsOpen(false)
      }
    }
    if (isUserNotificationsOpen) {
      const isCapture = true

      document.addEventListener('click', handleClickOutside, isCapture)
      return () => {
        document.removeEventListener('click', handleClickOutside, isCapture)
      }
    }
  }, [isUserNotificationsOpen])

  const handleResetUserNotifications = () => {
    dispatch(resetUserNotifications())
      .then(() => {
        setNewUserNotifications(false)
      })
  }

  const ifHasMoreNotifications = hasMoreNotifications && userNotifications.length % 10 === 0

  const closeNotificationBlock = () => setIsUserNotificationsOpen(false)

  return (
    <div>
      <div className="position-relative ml-2" role="button" onClick={() => setIsUserNotificationsOpen((prev) => !prev)} data-cy="open user notifications">
        {isUserNotificationsOpen && (
          <SpriteIcon name="bellOpened" size="md" />
        )}
        {(!isUserNotificationsOpen && (isNewUserNotifications ? (
          <SpriteIcon name="bellWithNotifications" size="md" />
        ) : (
          <SpriteIcon name="bell" size="md" />
        )))}
      </div>

      {isUserNotificationsOpen && (
        <div
          ref={notificationsContainer}
          className={classes.userNotificationsContainer}
          onClick={(e) => {
            if (e.target?.tagName === 'A') {
              closeNotificationBlock()
            }
          }}>
          <div className={clsx(classes.userNotificationsHeader, 'd-flex justify-content-between align-items-center fs-20 fw-medium py-2 px-3')}>
            Notifications

            {isNewUserNotifications && (
              <div role="button" className="color-green fs-16" onClick={handleResetUserNotifications}>Mark all as read</div>
            )}
            <SpriteIcon
              name="close"
              size="md"
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                closeNotificationBlock()
              }}
              dataCy='close notification block'
            />
          </div>
          <div className={classes.userNotificationsWrapper}>
            <InfiniteScroll
              pageStart={0}
              loadMore={(page: number) => dispatch(getAllUserNotifications(page))}
              hasMore={ifHasMoreNotifications}
              loader={<div className="loader" key={0}>Loading ...</div>}
              useWindow={false}>
              {userNotifications.length
                ? userNotifications.map((item, index) => (
                  <div
                    className={clsx(index % 2 && classes.userNotificationGreyBG, 'd-flex py-2 px-3')}
                    key={index}>
                    {!item.readAt && (
                      <span className={clsx(classes.userNotificationsUnreadDot, 'mr-2')} />
                    )}
                    <div>
                      <div className="fw-medium">{item.content}</div>
                      <div className="fs-14 mt-1 color-dark-grey">{item.createdAt}</div>
                    </div>
                  </div>
                ))
                : <div className='p-3'>No notifications yet</div>}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserNotifications
