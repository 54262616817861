import { GLOBAL_MODAL } from "state-manager/constants"

export type globalModalTypes = 'error' | 'success'

export interface IGlobalModal {
  isVisible: boolean,
  title: string,
  header: string,
  description: string,
  type: globalModalTypes,
  btnText: string,
  btnHandler?: null | (() => void),
}

type actionData = {
  type?: string,
  data?: IGlobalModal,
}

const initialState: IGlobalModal = {
  isVisible: false,
  title: '',
  header: '',
  description: '',
  type: 'error',
  btnText: 'Got it',
  btnHandler: null,
}

export default (state: IGlobalModal = initialState, action: actionData = {}) => {
  switch (action.type) {
    case GLOBAL_MODAL.SHOW: {
      return {
        ...initialState,
        ...action.data,
      }
    }
    case GLOBAL_MODAL.HIDE: {
      return initialState
    }
    default: {
      return state
    }
  }
}
