import React, { useMemo, useRef, useState } from 'react'
import { SortingRule } from 'react-table'

import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import Table from 'components/ui/Table'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// utils
import { getChangesHistory } from 'utils/requests'
import { HISTORY_ACTIONS } from 'state-manager/constants'
import { delayAction } from 'utils/delayAction'
import { capitalizeFirstLetter } from 'utils/string-manipulation'

const DELAY = 2000

const ChangesHistory: React.FC = () => {
  const { 
    pages,
    currentPage,
    lastPage,
    itemsPerPage,
    filters,
    locationList,
    eventList,
  } = useAppSelector((state) => state.history)

  const [sortOptions, setSortOptions] = useState<Array<SortingRule>>([])

  const dispatch = useAppDispatch()

  const timer = useRef<NodeJS.Timeout>(null)

  _useDidMount(() => {
    dispatch(getChangesHistory(1, itemsPerPage))
  })

  const columns = useMemo(() => ([
    {
      name: 'userName',
      Header: 'User Name',
      minWidth: 120,
      className: 'white-space-unset',
    },
    {
      name: 'date',
      filterType: 'new-datepicker',
      showTime: true,
      Header: 'Date & Time',
      className: 'white-space-unset',
      minWidth: 130,
    },
    {
      name: 'description',
      Header: 'Change',
      minWidth: 280,
      className: 'white-space-unset',
      style: {
        wordBreak: 'break-word',
      },
    },
    {
      name: 'event',
      Header: 'Event',
      minWidth: 120,
      className: 'white-space-unset',
      Filter: ({ onChange, filter }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
          data-cy="event filter">
          <option value="all" data-cy="event show all">Show All</option>
          {eventList.map((item) => (
            <option style={{ textTransform: 'capitalize' }} value={item} data-cy={`event ${item}`}>{capitalizeFirstLetter(item)}</option>
          ))}
        </select>
      ),
    },
    {
      name: 'location',
      Header: 'Location',
      minWidth: 150,
      className: 'color-green white-space-unset',
      Filter: ({ onChange, filter }) => {
        return (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
            data-cy="location filter">
            <option value="all" data-cy="filter show all">Show All</option>
            {locationList.map((item) => (
              <option key={item} value={item} data-cy={`filter ${item}`}>{item}</option>
            ))}
          </select>
        )
      },
    },
  ]), [eventList, locationList])

  return (
    <Table
      manual
      title="Change history"
      columns={columns}
      data={pages[currentPage] ?? []}
      pagination={{
        totalPages: lastPage,
        currentPage,
      }}
      pageSize={itemsPerPage}
      isServerSidePagination
      onServerSidePagination={({ nextPage }) => {
        dispatch(getChangesHistory(nextPage + 1, itemsPerPage, filters, sortOptions[0]))
      }}
      onServerSidePageSizeChange={(itemsPerPage) => {
        dispatch({
          type: HISTORY_ACTIONS.SET_ITEMS_PER_PAGE.SUCCESS,
          data: itemsPerPage,
        })
        dispatch(getChangesHistory(1, itemsPerPage, filters, sortOptions[0]))
      }}
      onFilter={(filters) => {
        delayAction(
          () => {
            dispatch({
              type: HISTORY_ACTIONS.SET_FILTERS.SUCCESS,
              data: filters,
            })
            dispatch(getChangesHistory(1, itemsPerPage, filters, sortOptions[0]))
          },
          DELAY,
          timer,
        )
      }}
      sortedOptions={sortOptions}
      onSortedChange={(newSorter) => {
        setSortOptions(newSorter)

        delayAction(
          () => {
            dispatch(getChangesHistory(1, itemsPerPage, filters, newSorter[0]))
          },
          DELAY,
          timer,
        )
      }}
    />
  )
}

export default ChangesHistory
