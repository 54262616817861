// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { NOTIFICATION_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

const urlPrefix = 'notification-settings'

export const getAllNotifications = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    url: urlPrefix,
  })
    .then((res) => {
      dispatch({
        type: NOTIFICATION_ACTIONS.NOTIFICATION_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: NOTIFICATION_ACTIONS.NOTIFICATION_GET_ALL.ERROR })
    })
}

export const addNotification = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url: urlPrefix,
  data,
})
  .then(() => {
    dispatch(getAllNotifications())
    notification.success('Notification successfully added!')
  })
  .finally(() => {
    dispatch({ type: NOTIFICATION_ACTIONS.NOTIFICATION_ADD.SYSTEM })
  })

export const updateNotification = (id: string, data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    urlPrefix,
    url: id,
    data,
  })
    .then(() => {
      notification.success('Notification successfully updated!')
      dispatch(getAllNotifications())
    })
    .finally(() => {
      dispatch({ type: NOTIFICATION_ACTIONS.NOTIFICATION_UPDATE.SYSTEM })
    })
}

export const deleteNotification = (id: string) => (dispatch: AppDispatch) => {
  reqH({
    method: 'DELETE',
    urlPrefix,
    url: id,
  })
    .then(() => {
      notification.success('Notification successfully deleted!')
      dispatch(getAllNotifications())
    })
    .finally(() => {
      dispatch({ type: NOTIFICATION_ACTIONS.NOTIFICATION_DELETE.SYSTEM })
    })
}

export const turnOffAllNotifications = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    url: urlPrefix,
  })
    .then(() => {
      notification.success('All notifications successfully turned off!')
      dispatch(getAllNotifications())
    })
    .finally(() => {
      dispatch({ type: NOTIFICATION_ACTIONS.NOTIFICATION_TURN_OFF_ALL.SYSTEM })
    })
}
