import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { BUDGET_ACTIONS } from 'state-manager/constants'

export type budgetInfoType = Array<{
  id: number,
  status: string,
  name: string,
  client: {
    result: {
      id: number,
      status: string,
      name: string,
      currencyId: number,
    },
  },
  currencyId: number,
  pacing: number,
  cycleBudget: number,
  rolloverBudget: number,
  budgetTarget: number,
  suggestedDailyBudget: number,
  totalDailyBudget: number,
  spendToDate: number,
  spentYesterday: number,
  conversionVTarget: number,
  cpcVTarget: number,
  roasVTarget: number,
  impressions: number,
  clicks: number,
  ctr: number,
  averageCpc: number,
  conversions: number,
  conversionRate: number,
  costPerConversion: number,
  conversionValue: number,
  roas: number,
  searchImpressionShare: number,
  cycleStartDate: string,
  cycleEndDate: string,
}>

export interface IBudgetContent {
  id: number;
  budgetTarget: number;
  spentToDate: number;
  pacing: number;
  client: {
    id: number;
    status: string;
    name: string;
    manager: {
      id: number;
      firstName: string;
      lastName: string;
    };
    currencyId: number;
    budgets: Array<unknown>;
    labels: Array<number>;
  }
}

export interface IBudgetContentWithPreviousMonth extends IBudgetContent {
  previousCycleMonth: IBudgetContent;
}

export interface IAllBudgets {
  data: budgetInfoType,
  responseWaiting: boolean,
  content: Array<IBudgetContentWithPreviousMonth>,
  isLoaded: boolean,
  timeLastUpdate: string | null,
}

const initialState: IAllBudgets = {
  data: [],
  content: [],
  responseWaiting: false,
  isLoaded: false,
}

export const typesV2 = {
  content: PropTypes.arrayOf(PropTypes.shape({
  })),
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    name: PropTypes.string,
    // TODO Check when budgets ready
    client: PropTypes.shape({
      result: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        name: PropTypes.string,
        currencyId: PropTypes.number,
      }),
    }),
    currencyId: PropTypes.number,
    pacing: PropTypes.number,
    cycleBudget: PropTypes.number,
    rolloverBudget: PropTypes.number,
    budgetTarget: PropTypes.number,
    suggestedDailyBudget: PropTypes.number,
    totalDailyBudget: PropTypes.number,
    spendToDate: PropTypes.number,
    spentYesterday: PropTypes.number,
    conversionVTarget: PropTypes.number,
    cpcVTarget: PropTypes.number,
    roasVTarget: PropTypes.number,
    impressions: PropTypes.number,
    clicks: PropTypes.number,
    ctr: PropTypes.number,
    averageCpc: PropTypes.number,
    conversions: PropTypes.number,
    conversionRate: PropTypes.number,
    costPerConversion: PropTypes.number,
    conversionValue: PropTypes.number,
    roas: PropTypes.number,
    searchImpressionShare: PropTypes.number,
    cycleStartDate: PropTypes.string,
    cycleEndDate: PropTypes.string,
  })),
  responseWaiting: PropTypes.bool,
  content: typesV2.content,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case BUDGET_ACTIONS.BUDGET_GET_ALL.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case BUDGET_ACTIONS.BUDGET_GET_ALL.SUCCESS:
      return updateObject(state, {
        // data: action.data,
        content: action.data.content,
        responseWaiting: false,
        isLoaded: true,
        timeLastUpdate: (new Date()).toJSON(),
      })

    case BUDGET_ACTIONS.BUDGET_GET_ALL.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    default:
      return state
  }
}
