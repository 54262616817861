import React, { useState } from 'react'

// components
import DashboardMenu from 'pages/Dashboard/shared/DashboardMenu'
import DashboardChart from 'pages/Dashboard/shared/Chart'

// actions
import { getAllBudgetsChartData } from 'state-manager/actions/budgets'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

// proptypes
import { useAppDispatch, useAppSelector } from 'state-manager/store'

const DashboardBudgetsChart: React.FC = () => {
  const [budgetCycle, setBudgetCycle] = useState<boolean | number>(false)

  const budgetsChartData = useAppSelector((state) => state.allBudgetsChart.data)

  const dispatch = useAppDispatch()

  _useDidMount(() => {
    if (!budgetsChartData.length) {
      dispatch(getAllBudgetsChartData())
    }
  })

  const modifiedClientsChartData = budgetsChartData.map((item) => ({
    name: item.name,
    id: item.id,
    x: budgetCycle ? item.previousCycle.x : item.currentCycle.x,
    y: budgetCycle ? item.previousCycle.y : item.currentCycle.y,
  }))

  return (
    <>
      <DashboardMenu onCycleChange={setBudgetCycle} />
      <DashboardChart type="budgets" chartData={modifiedClientsChartData} />
    </>
  )
}

export default DashboardBudgetsChart
