import { GLOBAL_MODAL } from "state-manager/constants"
import { IGlobalModal } from "state-manager/reducers/globalModal"

export const hideGlobalModal = () => ({
  type: GLOBAL_MODAL.HIDE,
})

type modalDataAction = Partial<Omit<IGlobalModal, 'isVisible'>>

export const showGlobalModal = (actionData: modalDataAction) => ({
  type: GLOBAL_MODAL.SHOW,
  data: {
    ...actionData,
    isVisible: true,
  }
})