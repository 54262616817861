/* utils */
import customEvent from 'utils/custom-event'

/* constants */
import { TOGGLE__VISIBILITY__SPINNER } from 'constants/custom-events'
import { SHOW, HIDE } from 'constants/visibility-actions'
import { store } from 'state-manager/store'

const toggle = (action) => {
  customEvent.trigger(TOGGLE__VISIBILITY__SPINNER, { action })
  // store
}

const show = () => {
  toggle(SHOW)
}

const hide = () => {
  toggle(HIDE)
}

export default {
  show,
  hide,
  toggle,
}
