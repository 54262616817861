import React from 'react'
import clsx from 'clsx'

// styles
import classes from 'components/ui/InputWrapper/styles.module.scss'

type propsType = {
  label?: string;
  hiddenLabel?: boolean;
  optional?: boolean;
  errorMsg?: string | boolean;
  classNames?: string;
  dataCy?: string;
}

const InputWrapper: React.FC<React.PropsWithChildren<propsType>> = ({
  label,
  hiddenLabel,
  optional,
  errorMsg,
  classNames,
  dataCy,
  children,
}) => (
  <div className={clsx('d-flex flex-column position-relative', classNames)} data-cy={dataCy}>
    <div className={clsx((optional || label) && 'mb-1', hiddenLabel && classes.labelOpacity, 'd-flex justify-content-between fw-regular fs-main')}>
      {label}
      {optional && (
        <div
          className="input-optional d-flex align-items-center fw-regular fs-sm color-black">
          optional
        </div>
      )}
    </div>
    {children}
    {errorMsg && (
      <div className="text-right fw-medium fs-sm color-red mt-1">
        {errorMsg}
      </div>
    )}
  </div>
)

export default InputWrapper
