import React, { ComponentType, PropsWithChildren, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import ReactTable, {
  ComponentDecoratorProps,
  FilteredChangeFunction,
  SortedChangeFunction,
  SortingRule, TableProps
} from 'react-table'
import selectTableHOC, { SelectInputComponentProps, SelectTableAdditionalProps } from 'react-table/lib/hoc/selectTable'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import _isEmpty from 'lodash.isempty'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import ModalCustomize from 'components/ui/Table/ModalCustomize'
import Checkbox from 'components/ui/Checkbox'

// hooks
import _useToggle from 'hooks/use-toggle'

// helpers
import { columnsTransformer } from 'components/ui/Table/columns-transformer'

// styles
import 'react-table/react-table.css'
import 'components/ui/Table/style.scss'
import classes from 'components/ui/Table/Table.module.scss'

import 'react-table-hoc-fixed-columns/lib/styles.css'
import { ICustomColumn, persistableTableNames } from 'state-manager/reducers/table'
import { TABLE_ACTIONS } from 'state-manager/constants'

const DEFAULT_PAGE_SIZE = 5

export type filter = {
  id: string,
  value: string | { start?: Date, end?: Date },
}

export type cellType = {
  id: string | number,
  [key: string]: any,
}

const getTableSortedState = (tableRef: React.MutableRefObject<any>): Array<any> | undefined => {
  const tableState = tableRef.current?.wrappedInstance?.getResolvedState?.()

  return tableState?.sortedData?.map(({ _original }) => _original)
}

type tableMainTypes = {
  manual?: boolean,
  data: Array<cellType>,
  columns: Array<ICustomColumn>,
  exportHandle?: () => void,
  className?: string,
  updatable?: boolean,
  openUpdate?: React.MouseEventHandler<HTMLDivElement>,
  title?: string,
  sortable?: boolean,
  defaultSortedBy?: string,
  onToggleSelection?: (selected: cellType) => void,
  onSelectAll?: (isSelected: boolean, sortedData: Array<any> | undefined) => void,
  isServerSidePagination?: boolean,
  paginatable?: boolean,
  selected?: Array<number>,
  selectedCount?: boolean,
  hiddenOverflow?: boolean,
  showAllData?: boolean,
  isLoading?: boolean,
  filterable?: boolean,
  resizable?: boolean,
  customizable?: boolean,
  selectable?: boolean,
  pagination?: {
    currentPage: number,
    totalPages: number,
  },
  pageSize?: number,
  onServerSidePagination?: (params: { nextPage: number }) => void,
  onServerSidePageSizeChange?: (itemsPerPage: number) => void,
  onFilter?: (filters: Array<filter>) => void,
  onSortedChange?: SortedChangeFunction,
  sortedOptions?: Array<SortingRule>,
  persistable?: boolean
  tableName?: persistableTableNames
}

type tableTypes = PropsWithChildren<tableMainTypes>

const NoDataConst = () => <div className={classes.noData}>Nothing to show yet</div>

const SelectInputComponent: ComponentType<SelectInputComponentProps> =
  ({ onClick, id, row, ...restProps }) => <Checkbox onChange={() => onClick(id, false, row)} {...restProps} />

// @ts-expect-error ReactTable has defaultProps (this is library types error)
const PaginationDefaultProps = ReactTable.defaultProps.PaginationComponent.defaultProps

const PreviousBtnWrapper: React.ElementType = (props) => (
  <PaginationDefaultProps.PreviousComponent data-cy="Go to previous page" onClick={props.onClick} disabled={props.disabled}>{props.children}</PaginationDefaultProps.PreviousComponent>
)

const NextBtnWrapper: React.ElementType = (props) => (
  <PaginationDefaultProps.NextComponent data-cy="Go to next page" onClick={props.onClick} disabled={props.disabled}>{props.children}</PaginationDefaultProps.NextComponent>
)

const getTbodyProps = () => ({ 'data-cy': 'table-data' })

function Table({
  manual,
  columns: originalColumns,
  data,
  tableName,
  exportHandle,
  children,
  pagination,
  isServerSidePagination = false,
  pageSize: customPageSize = DEFAULT_PAGE_SIZE,
  className = '',
  title = '',
  paginatable = true,
  persistable,
  sortable = true,
  defaultSortedBy,
  isLoading = false,
  filterable = true,
  resizable = false,
  customizable = false,
  selectable = false,
  onToggleSelection = () => {},
  onSelectAll,
  selected = [],
  selectedCount = false,
  hiddenOverflow = false,
  showAllData = false,
  updatable,
  openUpdate,
  onServerSidePagination,
  onServerSidePageSizeChange,
  onFilter,
  onSortedChange,
  sortedOptions,
}: tableTypes) {
  const [localColumnsVisibilityState, setLocalColumnsVisibilityState] = useState(originalColumns)

  const {
    globalPageSize,
    globalFiltered,
    globalColumnsVisibilityState,
    globalSorted,
  } = useAppSelector(({ table }) => tableName ? ({
    globalPageSize: table[tableName]?.pageSize,
    globalFiltered: table[tableName]?.filtered,
    globalColumnsVisibilityState: table[tableName]?.columnsState,
    globalSorted: table[tableName]?.sorted,
  }) : ({
    globalPageSize: null,
    globalFiltered: [],
    globalColumnsVisibilityState: [],
    globalSorted: [],
  }))

  const dispatch = useAppDispatch()

  const modifiedColumns = persistable
    ? (globalColumnsVisibilityState?.length ? globalColumnsVisibilityState : originalColumns)
    : localColumnsVisibilityState
  
  const setModifiedColumns = (columns: typeof modifiedColumns) => {
    if (persistable) {
      dispatch({
        type: TABLE_ACTIONS.SET_CUSTOMIZE,
        data: {
          tableName,
          columns,
        },
      })
    } else {
      setLocalColumnsVisibilityState(columns)
    }
  }

  const tableRef = useRef(null)

  const TableComponent = useMemo(
    () => selectable ? selectTableHOC(ReactTable) : withFixedColumns(ReactTable),
    [selectable],
  )

  const [customizeModalOpen, toggleCustomizeModalOpen] = _useToggle()

  // merged columns потрібні. Тут мержаться локальний і збережений в браузері стани.
  // Так як в локальному стані приходять функції, а зберегти функцію в браузері не є можливим,
  // то нам потрібно мержити ці дані
  const mergedColumns = useMemo(() => {
    return originalColumns.map((column) => {
      const matchedColumn = modifiedColumns.find(({ name }) => name === column.name)

      return ({
        ...column,
        visible: matchedColumn?.visible,
      })
    })
  }, [originalColumns, modifiedColumns])
  
  const [localFiltered, setLocalFiltered] = useState(persistable && globalFiltered ? globalFiltered : [])

  let isSelectedAllItemsCalculated: boolean

  // for server side pagination data always equals what user see on the table's page
  if (isServerSidePagination) {
    isSelectedAllItemsCalculated = selected.length !== 0 && selected.length === data.length

  // for pagination by react-table we have to get state what user see and figure out whether selected all items or not
  } else {
    const sortedData = getTableSortedState(tableRef) || []

    const isEveryItemSelected = sortedData.findIndex(({ id }) => !selected.includes(id)) === -1

    isSelectedAllItemsCalculated = selected.length !== 0 && isEveryItemSelected
  }

  const columns = useMemo(
    () => columnsTransformer(mergedColumns, data, localFiltered),
    // eslint-disable-next-line
    [mergedColumns],
  )

  const toggleAll = () => {
    const sortedData = getTableSortedState(tableRef) || []

    onSelectAll?.(!isSelectedAllItemsCalculated, sortedData)
  }

  const selectableProps: Partial<SelectTableAdditionalProps> & Partial<ComponentDecoratorProps> = selectable ? {
    keyField: 'id',
    selectType: 'checkbox',
    isSelected: (key: number) => selected.includes(key),
    toggleSelection: (originalKey) => {
      const key = parseInt(originalKey.split('-')[1])

      const item = data.find(({ id }) => id === key)

      if (item) {
        onToggleSelection(item)
      }
    },
    selectAll: isSelectedAllItemsCalculated,
    toggleAll,
    selectWidth: 60,
    SelectInputComponent,
    SelectAllInputComponent: ({ onClick, id, ...restProps }) => (
      <div className={classes.wrapperSelectedCount}>
        <Checkbox onChange={() => onClick(id)} {...restProps} className="position-absolute mt-2 z-index-1" dataCy="Select all" />
        {selectedCount && <div className={classes.selectedCount}>{selected.length}</div>}
      </div>
    ),
    getTrProps: (state, rowInfo) => ({
      style: {
        background: rowInfo && selected.includes(rowInfo.original.id) && '#ecf7ed',
      },
    }),
  } : {}

  const [localPageSize, setLocalPageSize] = useState(customPageSize)

  const pageSize = persistable ? (globalPageSize || DEFAULT_PAGE_SIZE) : localPageSize
  const setPageSize = (pageSize: number) => {
    if (persistable) {
      dispatch({
        type: TABLE_ACTIONS.SET_PAGE_SIZE,
        data: {
          tableName,
          pageSize,
        },
      })
    } else {
      setLocalPageSize(pageSize)
    }
  }

  const paginationProps: Partial<TableProps> = isServerSidePagination && !_isEmpty(pagination) ? {
    autoResetPageIndex: true,
    canNextPage: pagination.currentPage < pagination.totalPages,
    canPreviousPage: pagination.currentPage > 1,
    page: pagination.currentPage - 1,
    pages: pagination.totalPages,
    manualPagination: true,
    manual: true,
    onPageChange: (nextPage: number) => {
      if (onServerSidePagination) {
        onServerSidePagination({
          nextPage,
        })
      }
    },
    onPageSizeChange: (value: number) => {
      setPageSize(value)
      if (onServerSidePageSizeChange) {
        onServerSidePageSizeChange(value)
      }
    },
  } : {}

  const handleUnsetGlobalFiltered = () => {
    setLocalFiltered([])
    dispatch({
      type: TABLE_ACTIONS.UNSET_FILTERED,
      data: tableName,
    })
    if (isServerSidePagination) {
      const paginationParams = {
        nextPage: pagination.currentPage,
      }

      if (onServerSidePagination) {
        onServerSidePagination({...paginationParams})
      }
    }
  }

  const handleSortedChange: SortedChangeFunction = onSortedChange
    ? onSortedChange
    : (sorted) => {
      dispatch({
        type: TABLE_ACTIONS.SET_SORTED,
        data: {
          sorted,
          tableName,
        },
      })
    }

  const handleFilterChange: FilteredChangeFunction = (filtered) => {
    setLocalFiltered(filtered)
    if (persistable) {
      dispatch({
        type: TABLE_ACTIONS.SET_FILTERED,
        data: {
          tableName,
          filtered,
        },
      })
    }
    onFilter?.(filtered)
  }

  const sorted = sortedOptions || globalSorted || []

  return (
    <>
      {(title || customizable || children) && (
        <div className="d-flex-lg justify-content-between align-items-center mb-3">
          <div className="fw-semibold color-black fs-lg fs-sm-main">
            {title}
          </div>
          {(!children && customizable) && (
            <div>
              {persistable && localFiltered.some((item) => item.value !== 'all') && (
                <div role="button" className="btn color-green-fade table-button mr-4" data-cy="reset filters" onClick={handleUnsetGlobalFiltered}>
                  Reset filters
                </div>
              )}
              {updatable && (
                <div role="button" className="btn color-green-fade table-button mr-4" data-cy="bulk update" onClick={openUpdate}>
                  Bulk Update
                </div>
              )}
              {exportHandle && (
                <div role="button" className="btn color-green-fade table-button mr-4" data-cy="export csv" onClick={exportHandle}>
                  Export CSV
                </div>
              )}
              <div role="button" className="btn color-green-fade table-button" onClick={toggleCustomizeModalOpen} data-cy="customize table">
                Customize table
              </div>
            </div>
          )}
          {children}
        </div>
      )}
      {customizeModalOpen && (
        <ModalCustomize
          columns={modifiedColumns}
          customizeColumns={setModifiedColumns}

          onClose={toggleCustomizeModalOpen}
        />
      )}
      <TableComponent
        updatable
        minRows={5}
        ref={tableRef}

        sorted={sorted}
        onSortedChange={handleSortedChange}

        columns={columns}
        data={data}
        NoDataComponent={NoDataConst}
        loading={isLoading}

        showPagination={paginatable && !showAllData}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        onPageSizeChange={setPageSize}
        pageSize={showAllData ? data.length : pageSize}
        showPageJump={false}
        previousText="Prev page"
        nextText="Next page"
        {...paginationProps}

        sortable={sortable}
        defaultSorted={defaultSortedBy ? [{ id: defaultSortedBy, asc: true }] : []}

        filterable={filterable}
        filtered={localFiltered}
        onFilteredChange={handleFilterChange}

        resizable={resizable}

        className={clsx(hiddenOverflow && 'hidden-overflow', '-striped -highlight', !filterable && 'hidden-filters', className)}

        manual={manual}
        {...selectableProps}
        getTbodyProps={getTbodyProps}
        PreviousComponent={PreviousBtnWrapper}
        NextComponent={NextBtnWrapper}
      />
    </>
  )
}

export default Table
