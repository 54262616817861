import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import queryString from 'query-string'

// components
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'
import PasswordInput from 'components/ui/PasswordInput'

// actions
import { resetPassword } from 'state-manager/actions/auth'

// helpers
import { hasError } from 'helpers/field-has-errors'

// constants
import { formFields } from 'pages/ChangePassword/constants'
import { PASSWORD } from 'constants/regex'

// routes
import routes from 'routes'

const ChangePassword: React.FC = () => {
  const history = useHistory()
  const params = useParams<{ token: string }>()

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      [formFields.password]: '',
      [formFields.confirmPassword]: '',
    },

    validationSchema: Yup.object({
      [formFields.password]: Yup
        .string()
        .label('Password')
        .matches(PASSWORD, (options) => `${options.label} is invalid`)
        .min(8)
        .required(),
      [formFields.confirmPassword]: Yup
        .string()
        .label('Confirm password')
        . matches(PASSWORD, (options) => `${options.label} is invalid`)
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),

    onSubmit: (values, { resetForm }) => {
      const token = params.token
      const email = queryString.parse(history.location.search).email
      const data = {
        email,
        ...values,
      }
      dispatch(resetPassword(token, data))
      resetForm()
    },
  })

  const passwordHasError = hasError(formFields.password, formik.touched, formik.errors)
  const confirmPasswordHasError = hasError(formFields.confirmPassword, formik.touched, formik.errors)
  const isSubmitDisabled = !formik.isValid || !formik.dirty

  return (
    <Modal
      title="Change Password"
      onClose={() => history.push(routes.modalSignIn[0])}
      withCloseButton>
      <div className="form-page">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <PasswordInput
              name="password"
              label="Password"
              placeholder="Password"
              value={formik.values[formFields.password]}
              errorMsg={passwordHasError && formik.errors[formFields.password]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dataCy='change-password-input'
            />
            <br />
            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm Password"
              value={formik.values[formFields.confirmPassword]}
              errorMsg={confirmPasswordHasError && formik.errors[formFields.confirmPassword]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dataCy='confirm-password-input'
            />
          </div>
          <br />
          <Button
            type="submit"
            title="Change Password"
            disabled={isSubmitDisabled}
            dataCy='submit-change-password'
          />
        </form>
      </div>
    </Modal>
  )
}

export default ChangePassword
