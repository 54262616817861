// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { LABEL_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

const url = 'label'

export const getAllLabels = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    url,
  })
    .then((res) => {
      dispatch({
        type: LABEL_ACTIONS.LABEL_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: LABEL_ACTIONS.LABEL_GET_ALL.ERROR })
    })
}

export const addLabel = (name: { name: number | string }) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url,
  data: name,
})
  .then((res) => {
    notification.success('Label successfully added!')
    dispatch(getAllLabels())
    return res.data.result
  })
  .finally(() => {
    dispatch({ type: LABEL_ACTIONS.LABEL_ADD.SYSTEM })
  })
