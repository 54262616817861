import { CLIENT_MODAL } from "state-manager/constants"

export interface IClientModal {
  isVisible: boolean,
  id?: number,
}

type actionData = {
  type?: string,
  data?: { id?: number },
}

const initialState: IClientModal = {
  isVisible: false,
}

export default (state: IClientModal = initialState, action: actionData = {}): IClientModal => {
  switch (action.type) {
    case CLIENT_MODAL.SHOW: {
      return {
        isVisible: true,
        id: action.data?.id,
      }
    }
    case CLIENT_MODAL.HIDE: {
      return {
        isVisible: false,
        id: null,
      }
    }
    default: {
      return state
    }
  }
}
