import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Sentry from 'errors/sentry/Sentry'

const initialState = {
  error: null,
  eventId: null,
}

class ErrorBoundary extends PureComponent {

  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(() => {
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  showSentryDialog = () => {
    const { eventId } = this.state
    const { udFirstName, udLastName, udEmail } = this.props

    const sentryUser = {}

    if (this.udEmail) {
      sentryUser.name = `${udFirstName} ${udLastName}`
      sentryUser.email = udEmail
    }

    Sentry.showReportDialog({ eventId, user: sentryUser })
  }

  render() {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return (
        <>
          <h1>Something went wrong :(</h1>
          <button type="button" onClick={this.showSentryDialog}>
            Report feedback
          </button>
        </>
      )
    }

    return children
  }

}

const mapStateToProps = (state) => ({
  udFirstName: state.userData.firstName,
  udLastName: state.userData.lastName,
  udEmail: state.userData.email,
})

export default connect(mapStateToProps, null)(ErrorBoundary)
