import React, { PropsWithChildren } from 'react'
import MediaQuery, { MediaQueryAllQueryable } from 'react-responsive'

import * as screenSizes from 'constants/screen-sizes'

export default {
  XL_DOWN: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} maxWidth={screenSizes.XL_DOWN} />,
  XL_UP: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} minWidth={screenSizes.XL_UP} />,
  LG_DOWN: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} maxWidth={screenSizes.LG_DOWN} />,
  LG_UP: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} minWidth={screenSizes.LG_UP} />,
  MD_DOWN: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} maxWidth={screenSizes.MD_DOWN} />,
  MD_UP: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} minWidth={screenSizes.MD_UP} />,
  SM_DOWN: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} maxWidth={screenSizes.SM_DOWN} />,
  SM_UP: (props: PropsWithChildren<MediaQueryAllQueryable>) => <MediaQuery {...props} minWidth={screenSizes.SM_UP} />,
}
