import React from 'react'
import clsx from 'clsx'
import { Input } from 'reactstrap'

// components
import InputWrapper from 'components/ui/InputWrapper'
import SpriteIcon from 'components/ui/SpriteIcon'

// hooks
import _useToggle from 'hooks/use-toggle'

// styles
import classes from 'components/ui/PasswordInput/PasswordInput.module.scss'

type propsType = {
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  optional?: boolean;
  errorMsg?: string | boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any, Element>) => void;
  className?: string;
  dataCy: string;
}

const PasswordInput: React.FC<propsType> = ({
  label,
  value,
  name,
  placeholder,
  optional,
  errorMsg,
  disabled,
  onChange,
  onBlur,
  className,
  dataCy,
}) => {
  const [visible, switchVisible] = _useToggle()

  return (
    <InputWrapper
      label={label}
      optional={optional}
      errorMsg={errorMsg}
      classNames={className}
      dataCy={`${dataCy}-input-wrapper`}>
      <Input
        className={clsx(
          'input',
          errorMsg && 'input-invalid',
        )}
        type={visible ? 'text' : 'password'}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        data-cy={dataCy}
      />
      <div
        role="button"
        className={clsx(
          classes.passwordSwitcher,
          'd-flex justify-content-center align-items-center',
        )}
        data-cy={`${dataCy} eye icon`}
        onClick={switchVisible}>
        <SpriteIcon name={visible ? 'iconHide' : 'iconShow'} size="sm" />
      </div>
    </InputWrapper>
  )
}

export default PasswordInput
