import React, { useState } from 'react'
import clsx from 'clsx'
// @ts-expect-error no d.ts found for react-outside-click-handler
import OutsideClickHandler from 'react-outside-click-handler'

// components
import SpriteIcon from 'components/ui/SpriteIcon'
import ModalEditTeamMember from 'pages/TeamMembers/ModalEditTeamMember'

// actions
import {
  deleteInviteTeamMember,
  resendInviteTeamMember,
  updateTeamMemberRole,
} from 'state-manager/actions/team-members'

// hooks
import _useToggle from 'hooks/use-toggle'

// constants
import { ROLE_SUPER_COMPANY_ADMIN, ROLE_COMPANY_ADMIN, ROLE_MANAGER, ROLE_READ_ONLY_USER } from 'constants/roles'

// styles
import classes from 'components/ui/Table/Cells/Cell.module.scss'
import ModalConfirmDeactivation from './ModalDeleteTeamMember/index'
import { useAppDispatch } from 'state-manager/store'

type CellEditType = {
  id: string
  role: string
  withClients: number
  superAdmin: {
    firstName: string
    lastName: string
  }
  isPending: boolean
  companyId: string
}

const CellEdit: React.FC<CellEditType> = ({
  id,
  role,
  withClients,
  superAdmin,
  isPending,
  companyId,
}) => {
  const [editOpen, setEditOpen] = useState(false)
  const [isModalOpen, toggleIsModalOpen] = _useToggle()
  const [deleteTeamMemberId, toggleDeleteModalOpen] = _useToggle()

  const dispatch = useAppDispatch()

  if (role === ROLE_SUPER_COMPANY_ADMIN) {
    return null
  }

  const handleRoleUpdate = (id: number | string, newRole: string) => {
    const formData = {
      role: newRole,
    }

    dispatch(updateTeamMemberRole(id, formData))
    setEditOpen(false)
  }

  const handleResendInvite = () => {
    dispatch(resendInviteTeamMember(companyId, id))
    setEditOpen(false)
  }

  const handleDeleteInvite = () => {
    dispatch(deleteInviteTeamMember(companyId, id))
    setEditOpen(false)
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setEditOpen(false)
      }}>
      <div className="position-relative">
        <SpriteIcon dataCy={`edit user ${id}`} onClick={() => setEditOpen(!editOpen)} className={classes.editIcon} name="edit" size="smd" />
        {editOpen && (
          <div className={classes.editMenuContainer}>
            {isPending ? (
              <>
                <div
                  role="button"
                  className={clsx(classes.editClient, 'p-2')}
                  data-cy="resend invite"
                  onClick={handleResendInvite}>
                  Re-send invite
                </div>
                <div
                  role="button"
                  className={clsx(classes.editClient, 'p-2')}
                  data-cy="delete invite"
                  onClick={handleDeleteInvite}>
                  Delete invite
                </div>
              </>
            ) : (
              <>
                <div className="px-2 pt-2 pb-1">
                  {role !== ROLE_MANAGER && (
                    <div role="button" className="mb-1" onClick={() => handleRoleUpdate(id, ROLE_MANAGER)} data-cy="make manager">Make manager</div>
                  )}
                  {(role === ROLE_MANAGER || role === ROLE_READ_ONLY_USER) && (
                    <div role="button" className="mb-1" onClick={() => handleRoleUpdate(id, ROLE_COMPANY_ADMIN)} data-cy="make admin">Make admin</div>
                  )}
                  {role !== ROLE_READ_ONLY_USER && (
                    <div role="button" className="mb-1" onClick={() => handleRoleUpdate(id, ROLE_READ_ONLY_USER)} data-cy="make read-only user">Make read-only user</div>
                  )}
                </div>
                {role !== ROLE_SUPER_COMPANY_ADMIN && role !== ROLE_COMPANY_ADMIN && (
                  <div
                    role="button"
                    className={clsx(classes.editClient, 'p-2')}
                    data-cy="edit clients"
                    onClick={toggleIsModalOpen}>
                    Edit clients
                  </div>
                )}
                <div
                  role="button"
                  className={clsx(classes.deleteTeamMember, 'p-2')}
                  data-cy="delete team member"
                  onClick={toggleDeleteModalOpen}>
                  Delete team member
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {isModalOpen && <ModalEditTeamMember id={id} onClose={toggleIsModalOpen} />}
      {deleteTeamMemberId && (
        <ModalConfirmDeactivation
          id={id}
          onClose={toggleDeleteModalOpen}
          withClients={withClients}
          superAdminName={`${superAdmin.firstName} ${superAdmin.lastName}`}
        />
      )}
    </OutsideClickHandler>
  )
}

export default CellEdit
