import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

// components
import Button from 'components/ui/Button'

// constants
import { UPLOADS_URL } from 'constants/main'

// assets
import AvatarUploadIcon from 'assets/icons/avatarUpload.svg'

// proptypes
import { IUserData } from 'state-manager/reducers/user-data'

// styles
import classes from 'pages/Settings/AvatarUpload/AvatarUpload.module.scss'

type AvatarUploadType = {
  avatar: IUserData['avatar']
  uploadAvatar: (data: FormData) => void
  deleteAvatar: () => void
}

const AvatarUpload: React.FC<AvatarUploadType> = ({ avatar, uploadAvatar, deleteAvatar }) => {
  const [avatarPreview, setAvatarPreview] = useState('')

  useEffect(() => {
    if (avatar !== null) {
      setAvatarPreview(`${UPLOADS_URL}user/${avatar.userId}/user-avatar/${avatar.hash}-small.jpg`)
    }
  }, [avatar])

  const onDrop = useCallback<Exclude<DropzoneOptions['onDrop'], undefined>>((acceptedFiles) => {
    const files = acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }))

    const formData = new FormData()
    formData.append('type', 'userAvatar')
    formData.append('file', files[0])

    setAvatarPreview(files[0].preview)
    uploadAvatar(formData)
    //eslint-disable-next-line
  }, [])

  const removeAvatar = () => {
    setAvatarPreview('')
    deleteAvatar()
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {!avatarPreview.length ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...getRootProps()}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          {isDragActive ?
            <p>Drop the files here ...</p> :
            <img src={AvatarUploadIcon} alt="avatar-thumbnail" />}
        </div>
      ) : (
        <div className="d-flex justify-content-start align-items-center flex-column-sm">
          <img src={avatarPreview} className={clsx(classes.avatar, 'mb-sm-3')} alt="avatar-thumbnail" />
          <div className="w-20">
            <div className="text-center">
              <label className={clsx(classes.uploadButton, 'btn fw-semibold')} htmlFor="uploadInput">Upload an Image</label>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <input id="uploadInput" {...getInputProps()} />
            </div>
            <Button
              title="Remove photo"
              className={clsx(classes.removePhotoButton, 'color-green')}
              onClick={removeAvatar}
              dataCy='remove_photo_button'
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AvatarUpload
