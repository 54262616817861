import React from 'react'
import PropTypes from 'prop-types'

// components
import SpriteIcon from 'components/ui/SpriteIcon'
import Table from 'components/ui/Table/index'

// helpers
import getCurrency from 'helpers/get-currency'

// proptypes
import { CurrencyType, currenciesTypes } from 'constants/application-configs'

// styles
import classes from 'pages/TeamMembers/ModalEditTeamMember/modalEditTeamMember.module.scss'

type DataTableType = {
  data: Array<{
    id: number
    clientName: string
    currency: string
    budgetSummary: string | number
  }>
  deleteClient: (id: number) => void
  currencies: Array<CurrencyType>
}

const DataTable: React.FC<DataTableType> = ({
  data,
  deleteClient,
  currencies,
}) => {
  const columns = [
    {
      name: 'name',
      Header: 'Client Name',
      filterType: 'select',
      style: { whiteSpace: 'unset', wordBreak: 'break-all', textAlign: 'center' },
      width: 120,
    },
    {
      name: 'currencyId',
      Header: 'Currency',
      Cell: ({ value }) => getCurrency(value, currencies),
      style: { whiteSpace: 'unset', wordBreak: 'break-all', textAlign: 'center' },
      width: 320,
    },
    {
      name: 'budgetSummary',
      Header: 'Budgets Summary',
      Cell: ({ value }) => value || <span className={classes.textOpacity}>No budgets</span>,
      width: 160,
    },
    {
      name: '',
      isHideSortOption: true,
      isHideFilter: true,
      Cell: ({ original }) => (
        <div role="button" onClick={() => deleteClient(original.id)}>
          <SpriteIcon name="closeRed" size="sm" />
        </div>
      ),
      width: 40,
    },
  ]

  return (
    <Table
      className="editTeamMemberTable"
      tableName="editTeamMember"
      filterable={false}
      showAllData

      columns={columns}
      data={data}
    />
  )
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    clientName: PropTypes.string,
    currency: PropTypes.string,
    budgetSummary: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  })).isRequired,
  currencies: currenciesTypes.currencies.isRequired,
  deleteClient: PropTypes.func.isRequired,
}

export default DataTable
