import React from 'react'
import PropTypes from 'prop-types'
import {useAppDispatch} from "state-manager/store"

// components
import StaticNotification from 'components/StaticNotification'

// actions
import { deleteDataSource } from 'state-manager/actions/data-sources'

// assets
import CancelIcon from 'assets/icons/cancelOutline.svg'

type ModalConfirmDeactivationType = {
  id: number;
  onClose: () => void;
}

const ModalConfirmDeactivation: React.FC<ModalConfirmDeactivationType> = ({ id, onClose }) => {
  const dispatch = useAppDispatch()
  return (
    <StaticNotification
      icon={CancelIcon}
      onClose={onClose}
      title="Remove Data Source"
      yes="Yes, remove"
      no="Cancel"
      type="warning"
      handleClick={() => {
        const data = {
          dataSourceId: id,
        }
        dispatch(deleteDataSource(data))
        onClose()
      }}
      description="Are you sure you want to remove this data source? All budgets containing campaigns from this data source will also be deleted, even if they contain campaigns from other data sources."
    />
  )
}

ModalConfirmDeactivation.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ModalConfirmDeactivation
