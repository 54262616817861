// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// actions
import { fetchSubscription } from 'state-manager/reducers/subscription'

// constants
import { PLAN_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

const urlPrefix = 'plans'

export const getAllPlans = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'GET',
    url: urlPrefix,
  })
    .then((res) => {
      dispatch({
        type: PLAN_ACTIONS.PLAN_GET_ALL.SUCCESS,
        data: res.data.result,
      })
    })
    .catch(() => {
      dispatch({ type: PLAN_ACTIONS.PLAN_GET_ALL.ERROR })
    })
}

export const getPlanByToken = (token: string) => (dispatch: AppDispatch) => reqH({
  method: 'GET',
  url: `plan/${token}`,
})
  .then((res) => {
    dispatch({
      type: PLAN_ACTIONS.PLAN_GET_CUSTOM.SUCCESS,
      data: res.data.result,
    })

    return res.data.result
  })

export const createPlanRequest = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  url: urlPrefix,
  data,
})
  .then(() => {
    dispatch(fetchSubscription())
    notification.success('Plan request successfully sent')
  })
  .finally(() => {
    dispatch({ type: PLAN_ACTIONS.PLAN_CREATE_REQUEST.SYSTEM })
  })
