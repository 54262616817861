import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
// @ts-expect-error no types for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import Button from 'components/ui/Button'
import ModalConfirmCancelSubscription from 'pages/Invoices/ModalConfirmCancelSubscription'
import Badge from 'components/ui/Badge'

// actions
import { cancelSubscription } from 'state-manager/actions/subscriptions'
import { getUserCoupon } from 'state-manager/actions/coupon'
import { showGlobalModal } from 'state-manager/actions/global-modal'
import { fetchSubscription } from 'state-manager/reducers/subscription'

// media queries
import MQ from 'utils/mq'

// hooks
import _useToggle from 'hooks/use-toggle'
import { _useGlobalDemo } from 'hooks/use-demo'

// routes
import routes from 'routes'

import reqH from 'utils/request-handler'

import classes from 'pages/Invoices/InvoicesThumbnail/InvoicesThumbnail.module.scss'

const InvoicesThumbnail: React.FC = () => {
  const [isModalOpen, toggleIsModalOpen] = _useToggle()

  const paymentMethods = useAppSelector((state) => state.billingInfo.data?.paymentMethods)
  const currentSubscription = useAppSelector((state) => state.subscriptions.data)
  const configCurrencies = useAppSelector((state) => state.currencies.data)

  const coupon = useAppSelector((state) => state.coupon)

  const [isCouponLoaded, setIsCouponLoaded] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getUserCoupon())
      .catch((e) => {})
      .then(() => setIsCouponLoaded(true))
    //eslint-disable-next-line
  }, [])

  const demoHOC = _useGlobalDemo()
  const history = useHistory()

  if (!currentSubscription.renewsAt || !configCurrencies.length || !currentSubscription.plan || !currentSubscription.plan.price) {
    return null
  }

  const currencySymbol = configCurrencies.length ? configCurrencies.find((item) => item.id === currentSubscription.currency)?.symbol : '$'

  const date = new Date(currentSubscription.renewsAt)

  const cancelDateStr = useMemo(() => {
    if (typeof currentSubscription.cancelsAt === 'string') {
      return (new Date(currentSubscription.cancelsAt)).toLocaleDateString()
    }
    return 'Invalid date'
  }, [currentSubscription.cancelsAt])

  const handleCancelSubscription = () => {
    dispatch(cancelSubscription())
    toggleIsModalOpen()
  }

  const discount = coupon?.percentOff ? (100 - coupon.percentOff) / 100 : 1

  const finalPrice = discount || discount === 0 ? (currentSubscription.plan.price / 100) * discount : currentSubscription.plan.price / 100

  const handleChangePlan = () => {
    if (!paymentMethods?.length && !currentSubscription.isOnTrial) {
      dispatch(showGlobalModal({
        header: 'Warning!',
        description: 'In order to change the billing plan, you must add at least one payment method.',
        type: 'error',
        btnText: 'Got it',
      }))
    } else {
      history.push(formatRoute(routes.subscriptions))
    }
  }

  if (!isCouponLoaded) {
    return null
  }

  const handleActivate = () => {
    reqH({
      method: 'POST',
      url: 'users/current/subscription/resume'
    })
      .then(() => {
        dispatch(fetchSubscription())
      })
  }

  const renderButtons = () => {
    const { status, isSubscriptionScheduledToCancel } = currentSubscription

    if (status === 'active' || status === 'trialing') {
      if (isSubscriptionScheduledToCancel) {
        return (
          <Button
            title={status === 'trialing' ? 'Activate trial' : 'Activate'}
            onClick={handleActivate}
            dataCy='activate-plan-button'
          />
        )
      } else {
        return (
          <>
            <Button
              title="Change Plan"
              onClick={demoHOC(handleChangePlan)}
              dataCy='change-plan-button'
            />
            <div
              role="button"
              className="fw-semibold mt-1 text-center color-red"
              onClick={demoHOC(toggleIsModalOpen)}>
              Cancel subscription
            </div>
          </>
        )
      }
    } else if (status === 'canceled' || status === 'incomplete_expired') {
      return (
        <Button
          title="Activate subscription"
          onClick={demoHOC(handleChangePlan)}
          dataCy='change-plan-button'
        />
      )
    } else {
      return null
    }
  }

  return (
    <>
      <div className={clsx(classes.thumbnailWrapper, 'd-flex justify-content-between align-items-center mb-6')}>
        <div className={clsx(classes.thumbnailItemFirst, 'color-white fw-medium')}>
          <p className="text-center">Current billing plan</p>
          <p className="text-center">
            <span className={clsx(classes.thumbnailPriceText, 'd-flex fw-semibold fs-40 align-items-end mt-1')}>
              {currencySymbol}{finalPrice.toFixed(2)}
              <span className={clsx(classes.currentBillingPlanPeriod, 'fw-medium fs-20')}>/ {currentSubscription.plan?.period}</span>
            </span>
          </p>
        </div>
        <MQ.MD_UP>
          <div className={clsx(classes.thumbnailItem, 'fw-regular text-center')}>
            <p>Maximum</p>
            <p>Monthly Media Spend</p>
            <p className="fw-medium fs-20 mt-1">
              {currencySymbol}
              {currentSubscription.maximumMonthlyMediaSpend}
            </p>
          </div>
          <div className={clsx(classes.thumbnailItem, 'fw-regular text-center')}>
            <p>Current</p>
            <p>Usage</p>
            <p className="fw-medium fs-20 mt-1">
              {currencySymbol}
              {currentSubscription.currentMonthlyMediaSpend}
            </p>
          </div>
          <div className={clsx(classes.thumbnailItem, 'fw-regular text-center')}>
            {currentSubscription.isSubscriptionScheduledToCancel ? (
              <>
                <p>Subscription</p>
                <p>End Date</p> 
                <p className="fw-medium fs-20 mt-1">{cancelDateStr}</p>
              </>
            ) : (
              <>
                <p>Next</p>
                <p>Renewal Date</p> 
                <p className="fw-medium fs-20 mt-1">{date.toLocaleDateString()}</p>
              </>
            )}
            {currentSubscription.isOnTrial && (<Badge title="On Trial" className="mt-1" />)}
          </div>
        </MQ.MD_UP>
        <MQ.MD_DOWN>
          <div className={clsx(classes.thumbnailItemsWrapper, 'd-flex block-width mx-4 align-items-center')}>
            <div className={clsx(classes.thumbnailItem, 'fw-regular text-center')}>
              <p className="mr-1">Maximum Monthly Media Spend</p>
              <p className="fw-regular fs-20">
                {currencySymbol}
                {currentSubscription.maximumMonthlyMediaSpend}
              </p>
            </div>
            <div className={clsx(classes.thumbnailItem, 'fw-regular text-center')}>
              <p className="mr-1">Current Usage</p>
              <p className="fw-regular fs-20">
                {currencySymbol}
                {currentSubscription.currentMonthlyMediaSpend}
              </p>
            </div>
            <div className={clsx(classes.thumbnailItem, 'fw-regular text-center')}>
              {currentSubscription.isSubscriptionScheduledToCancel ? (
                <>
                  <p className="mr-1">Subscription End Date</p>
                  <p className="fw-regular fs-20">{cancelDateStr}</p>
                </>
              ) : (
                <>
                  <p className="mr-1">Next Renewal Date</p>
                  <p className="fw-regular fs-20">{date.toLocaleDateString()}</p>
                </>
              )}
            </div>
            {currentSubscription.isOnTrial && (
              <div className={clsx('fw-regular text-right w-100')}>
                <Badge title="On Trial" className="mt-1" />
              </div>
            )}
          </div>
        </MQ.MD_DOWN>
        <div className={clsx(classes.thumbnailLastItem, 'mr-3 py-4')}>
          {renderButtons()}
        </div>
      </div>
      {isModalOpen && (
        <ModalConfirmCancelSubscription
          handleClick={handleCancelSubscription}
          renewalDate={date.toLocaleDateString()}
          onClose={toggleIsModalOpen}
        />
      )}
    </>
  )
}

export default InvoicesThumbnail
