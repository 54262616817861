import { createBrowserHistory } from 'history'
import { persistStore } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import createRootReducer, { IRootState } from 'state-manager/reducers/root-reducer'

const history = createBrowserHistory()

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(routerMiddleware(history)),
})

const persistor = persistStore(store)

// if (ENV !== 'production' && module.hot) {
//   module.hot.accept('../reducers/root-reducer', () => {
//     // eslint-disable-next-line global-require
//     const createRootReducerHot = require('../reducers/root-reducer').default
//     store.replaceReducer(createRootReducerHot(history))
//   })
// }

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector

export { store, persistor, history }
