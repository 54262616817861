import React from 'react'
import clsx from 'clsx'

// components
import PersonalInfoForm from 'pages/Settings/PersonalInfo'
import ChangePasswordForm from 'pages/Settings/ChangePassword'
import PrivacyForm from 'pages/Settings/Privacy'

// styles
import classes from 'pages/Settings/Settings.module.scss'

const Settings: React.FC = () => (
  <>
    <h2 className="fw-regular">Settings</h2>
    <div className={clsx(classes.settingsContainer, 'd-flex justify-content-between mb-3')}>
      <PersonalInfoForm />
      <div className={classes.settingsFormsContainer}>
        <ChangePasswordForm /><br />
        <PrivacyForm />
      </div>
    </div>
  </>
)

export default Settings
