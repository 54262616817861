import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { INVOICE_ACTIONS } from 'state-manager/constants'

export interface IInvoices {
  data: Array<{
    invoiceID: string,
    date: string,
    total: string,
    invoicePDF: string,
  }>,
}

const initialState: IInvoices = {
  data: [],
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    invoiceID: PropTypes.string,
    date: PropTypes.string,
    total: PropTypes.string,
    invoicePDF: PropTypes.string,
  })),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case INVOICE_ACTIONS.INVOICE_GET_ALL.SUCCESS:
      return updateObject(state, {
        data: action.data,
      })

    default:
      return state
  }
}
