// utils
import reqH from 'utils/request-handler'
import notification from 'utils/notifications'

// constants
import { USER_ACTIONS } from 'state-manager/constants'
import { AppDispatch } from 'state-manager/store'

const urlPrefix = 'user'

export const getUserData = () => (dispatch: AppDispatch) => reqH({
  method: 'GET',
  url: urlPrefix,
})
  .then((res) => {
    dispatch({
      type: USER_ACTIONS.USER_GET_DATA.SUCCESS,
      data: res.data.result,
    })
  })

export const updatePassword = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix: 'auth',
    url: 'change-password',
    data,
  })
    .then(() => {
      notification.success('Successfully updated!')
    })
    .finally(() => {
      dispatch({ type: USER_ACTIONS.USER_UPDATE_PASSWORD.SYSTEM })
    })
}

export const updateUser = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    url: urlPrefix,
    shouldUpdateUserData: true,
    data,
  })
    .then(() => {
      notification.success('Successfully updated!')
    })
    .finally(() => {
      dispatch({ type: USER_ACTIONS.USER_UPDATE.SYSTEM })
    })
}

export const uploadUserAvatar = (data: FormData) => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    url: 'media/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
    .then((res) => {
      const formData = {
        mediaId: res.data.result.id,
      }
      reqH({
        method: 'POST',
        urlPrefix: 'user',
        url: 'update-avatar',
        data: formData,
      })
        .then(() => {
          dispatch(getUserData())
          notification.success('Successfully uploaded!')
        })
    })
    .finally(() => {
      dispatch({ type: USER_ACTIONS.USER_UPLOAD_AVATAR.SYSTEM })
    })
}

export const deleteAvatar = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'POST',
    urlPrefix,
    url: 'update-avatar',
  })
    .then(() => {
      dispatch(getUserData())
      notification.success('Successfully removed!')
    })
    .finally(() => {
      dispatch({ type: USER_ACTIONS.USER_DELETE_AVATAR.SYSTEM })
    })
}

export const update2FA = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'POST',
  urlPrefix,
  url: 'update-two-factor-auth',
  shouldUpdateUserData: true,
  data,
})
  .then(() => {
    notification.success('Successfully updated!')
  })
  .finally(() => {
    dispatch({ type: USER_ACTIONS.USER_UPDATE_2FA.SYSTEM })
  })

export const updateTechSupport = (data: Record<string, any>) => (dispatch: AppDispatch) => reqH({
  method: 'PUT',
  urlPrefix,
  url: 'support',
  data,
})
  .then(() => {
    dispatch(getUserData())
    notification.success('Successfully updated!')
  })
  .finally(() => {
    dispatch({ type: USER_ACTIONS.USER_UPDATE_TECH_SUPPORT.SYSTEM })
  })

export const skipWelcomeGuide = () => (dispatch: AppDispatch) => {
  reqH({
    method: 'PUT',
    urlPrefix,
    url: 'skip-guide',
    shouldUpdateUserData: true,
  })
    .then(() => {
      notification.success('Successfully updated!')
    })
    .finally(() => {
      dispatch({ type: USER_ACTIONS.USER_SKIP_GUIDE.SYSTEM })
    })
}
