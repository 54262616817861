import React, { useEffect } from 'react'
import clsx from 'clsx'

import classes from './AppSumoBilling.module.scss'
import { useDispatch } from 'react-redux'
import { getAppSumoPlans } from 'utils/requests'
import {useAppSelector} from "state-manager/store"

// TODO: formatNumber has the same logic
const parseCurrency = (number: number) => number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

type Props = {
  selectedPlanId: string,
}

const AppSumoBilling = ({ selectedPlanId }: Props) => {
  const plans = useAppSelector((state) => state.sumoPlans)

  const changePlanLink = useAppSelector((state) => state.subscriptions.data?.changePlanLink)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!plans.length) {
      getAppSumoPlans(dispatch)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div className={clsx(classes.planesContainer, 'mt-4')}>
      {plans.map(({ planId, name, price, monthlyAdSpend, link }) => (
        <div className={clsx(classes.plan, selectedPlanId === planId ? classes.selected : classes.available)} key={planId}>
          <div className={classes.contentWrapper}>
            <h3 className="text-center">{name}</h3>
            <div className={classes.line} />
            <ul className='mt-3'>
              <li>Users - Unlimited</li>
              <li>Budgets - Unlimited</li>
            </ul>
            <div className={clsx(classes.line, 'mt-3')} />
            <p className={clsx('text-center', 'mt-4', classes.purchase)}>Deal price - <span className={classes.price}>${parseCurrency(price / 100)}</span></p>
            <p className='text-center'>Monthly ad spend - <span className={classes.oldPrice}>${parseCurrency(monthlyAdSpend)}</span></p>
          </div>
          {selectedPlanId !== planId ? (
            <a className={classes.buyBtn} href={changePlanLink || link} data-cy={`switch ${planId}`}>Switch</a>
          ) : (<div className={clsx('text-center', classes.activeLabel)}>Active</div>)}
        </div>
      ))}
    </div>
  )
}

export default AppSumoBilling
