import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// routes
import routes from 'routes'

import { signIn } from 'state-manager/actions/auth'

import classes from './autoAuth.module.scss'

const AutoAuth = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (location.state) {
      signIn(location.state)(dispatch)
    } else {
      history.replace(routes.modalSignIn[0], { from: location.pathname })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.container}>
      Authorization...
    </div>
  )
}

export default AutoAuth
