import React, {useEffect, useMemo, useState} from 'react'
import clsx from 'clsx'
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Scatter, ResponsiveContainer, ScatterProps } from 'recharts'
import { NavLink } from 'react-router-dom'

// components
import CustomTooltip from 'pages/Dashboard/shared/Chart/chartHoverTooltip'

// styles
import classes from 'pages/Dashboard/shared/Chart/Chart.module.scss'

type DashboardChartType = {
  chartData: Array<{
    name: string;
    id: number;
    x: number;
    y: number;
  }>;
  type: 'clients' | 'budgets';
}

const DashboardChart: React.FC<DashboardChartType> = ({
  chartData,
  type,
}) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const [topState, setTopState] = useState(0)
  const [leftState, setLeftState] = useState(0)
  const [status, setStatus] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)
  const [timeElapsed, setTimeElapsed] = useState(0)
  const [budgetSpend, setBudgetSpend] = useState(0)
  const [isReverted, setIsReverted] = useState(false)

  const handleTooltipOpen: ScatterProps['onClick'] = (props) => {
    props.payload.x > 50
      ? setIsReverted(true)
      : setIsReverted(false)
    setTooltipOpen((isTooltipOpen) => !isTooltipOpen)
    setTopState(props.y - 20)
    setLeftState(props.payload.x > 50 ? props.x - 20 : props.x + 20)
    setTimeElapsed(props.payload.x)
    setBudgetSpend(props.payload.y)
    setName(props.payload.name)
    setId(props.payload.id)
    setStatus(props.payload.top ? 'Over' : props.payload.mid ? 'OK' : 'Under')
  }

  const data = useMemo(() => {
    type ArrayType = {
      x: number
      y?: number
      bot?: number
      top?: number
      mid?: number
      upperLine?: number
      middleLine?: number
      underLine?: number
    }

    const array: Array<ArrayType> = chartData.map((item) => {
      if (item.x - item.y >= 10) {
        return {
          ...item,
          bot: item.y,
        }
      } else if (item.y - item.x >= 10) {
        return {
          ...item,
          top: item.y
        }
      } else if (item.y - item.x <= 10) {
        return {
          ...item,
          mid: item.y,
        }
      }

      return { ...item }
    })

    return array.concat([
      { x: 0, upperLine: 10 },
      { x: 90, upperLine: 100 },
      { x: 0, middleLine: 0 },
      { x: 100, middleLine: 100 },
      { x: 10, underLine: 0 },
      { x: 100, underLine: 90 },
    ])
  }, [chartData])

  useEffect(() => {
    setTooltipOpen(false)
  }, [chartData])

  return (
    <div className="position-relative">
      <ResponsiveContainer width="100%" height={420}>
        <ComposedChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 20, right: 80, bottom: 20, left: 20,
          }}>
          <CartesianGrid stroke="#f5f5f5" />
          <Tooltip content={CustomTooltip} />
          <XAxis dataKey="x" tickCount={11} type="number" label={{ value: '% Time Used', position: 'insideBottom', offset: -20 }} />
          <YAxis type="number" tickCount={6} label={{ value: '% Fullfilled', angle: -90, position: 'insideLeft' }} />
          <Scatter dataKey="top" fill="blue" onClick={handleTooltipOpen} />
          <Scatter dataKey="mid" fill="green" onClick={handleTooltipOpen} />
          <Scatter dataKey="bot" fill="red" onClick={handleTooltipOpen} />
          <Line isAnimationActive={false} dataKey="middleLine" stroke="grey" dot={false} activeDot={false} legendType="none" />
          <Line isAnimationActive={false} dataKey="upperLine" stroke="grey" dot={false} activeDot={false} legendType="none" />
          <Line isAnimationActive={false} dataKey="underLine" stroke="grey" dot={false} activeDot={false} legendType="none" />
        </ComposedChart>
      </ResponsiveContainer>
      {isTooltipOpen && (
        <div className={clsx(classes.tooltipContainer, isReverted && classes.graphConverted)} style={{ top: topState, left: leftState }}>
          <div>
            {status}
          </div>
          <div>
            Time Elapsed: {timeElapsed}%
          </div>
          <div>
            Budget Spent: {budgetSpend}%
          </div>
          <NavLink to={`/panel/${type}/${id}`}>
            {name}
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default DashboardChart
