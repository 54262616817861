import React from 'react'
import { useAppDispatch, useAppSelector } from 'state-manager/store'

// components
import DataTable from 'pages/DataSources/table'

// actions
import { getAllDataSources, refreshDataSource } from 'state-manager/actions/data-sources'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'


const DataSources: React.FC = () => {
  const { data: dataSources } = useAppSelector((state) => state.dataSources)

  const dispatch = useAppDispatch()

  _useDidMount(() => {
    dispatch(getAllDataSources())
  })

  return (
    <DataTable
      data={dataSources}
      refreshDataSource={(id) => dispatch(refreshDataSource(id))}
    />
  )
}

export default DataSources
