import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import Table from 'components/ui/Table/index'
import SpriteIcon from 'components/ui/SpriteIcon'

// styles
import classes from 'pages/Invoices/Invoices.module.scss'

// helpers
import { isDemoAccount, userIdSelector } from 'helpers/demo'

// hooks
import { DEMO_MODAL } from 'state-manager/constants'

const InvoiceLink: React.FC<{ value: string }> = ({ value }) => (
  <div>
    <a href={value} target="_blank" rel="noreferrer">
      <SpriteIcon name="download" size="sm" />
      <span className={classes.downloadIcon} />
    </a>
  </div>
)

const DemoInvoiceLink: React.FC<{ toggleIsShowDemoModal: () => void }> = ({ toggleIsShowDemoModal }) => (
  <div onClick={toggleIsShowDemoModal} role="button">
    <SpriteIcon name="download" size="sm" />
    <span className={classes.downloadIcon} />
  </div>
)

const DataTable = ({
  data,
}) => {
  const userId = useSelector(userIdSelector)
  const isDemoEnabled = isDemoAccount(userId)

  const dispatch = useDispatch()

  const columns = React.useMemo(
    () => [
      {
        name: 'invoiceID',
        Header: 'Invoice ID',
        style: { whiteSpace: 'unset', wordBreak: 'break-all', textAlign: 'center' },
      },
      {
        name: 'date',
        Header: 'Date',
        filterType: 'new-datepicker',
        showTime: true,
        width: 264,
        style: { whiteSpace: 'unset' },
      },
      {
        name: 'total',
        Header: 'Value',
        filterType: 'select',
      },
      {
        name: 'invoicePdf',
        isHideSortOption: true,
        isHideFilter: true,
        Cell: isDemoEnabled
          ? <DemoInvoiceLink toggleIsShowDemoModal={() => dispatch({ type: DEMO_MODAL.SHOW })} />
          : InvoiceLink,
        width: 60,
      },
    ],
    [dispatch, isDemoEnabled],
  )

  return (
    <Table
      tableName="invoices"
      title="Invoices History"
      hiddenOverflow
      columns={columns}
      data={data}
    />
  )
}

export default DataTable
