import React, { useEffect, useMemo, useState } from 'react'
import { saveAs } from 'file-saver'

// components
import DashboardMenu from 'pages/Dashboard/shared/DashboardMenu'
import BudgetsTable from 'pages/Dashboard/Budgets/table'
import RefreshButton from 'components/ui/RefreshButton'

// actions
import { getAllBudgets, getUrlForExportBudgetTable } from 'state-manager/actions/budgets'

// helpers
import { getLabelsInBudgets } from 'helpers/get-labels-in-budgets'
import notification from 'utils/notifications'

import { useAppDispatch, useAppSelector } from 'state-manager/store'
import useRefreshData, { BUDGET_AND_CLIENT_TABLE_REFRESH_DELAY, checkIfDataNeedToRefresh } from 'hooks/useRefreshData'

const DashboardBudgets: React.FC = () => {
  const [budgetCycle, setBudgetCycle] = useState<boolean | number>(false)

  const {
    labels,
    configCurrencies,
    budgets,
    budgetsResponseWaiting,
    timeLastUpdate,
  } = useAppSelector((state) => ({
    labels: state.labels.labels,
    configCurrencies: state.currencies.data,
    budgets: state.allBudgets.content,
    budgetsResponseWaiting: state.allBudgets.responseWaiting,
    timeLastUpdate: state.allBudgets.timeLastUpdate,
  }))

  const dispatch = useAppDispatch()

  const { isDataNeedToRefresh, restoreTimer } = useRefreshData()

  useEffect(() => {
    if (timeLastUpdate && checkIfDataNeedToRefresh(new Date(timeLastUpdate), BUDGET_AND_CLIENT_TABLE_REFRESH_DELAY)) {
      dispatch(getAllBudgets())
        .then(() => {
          if (isDataNeedToRefresh) {
            restoreTimer()
          }
        })
    }
    //eslint-disable-next-line
  }, [])

  const budgetsData = useMemo(() => (
    budgetCycle ? budgets.map((item) => item.previousCycleMonth) : budgets
  ), [budgetCycle, budgets])

  const modifiedBudgetsData = useMemo(
    () => getLabelsInBudgets(budgetsData, labels),
    [budgetsData, labels],
  )

  const handleExportBudgetsButton = async () => {
    const res = await dispatch(getUrlForExportBudgetTable('csv', budgetCycle))
    if (res?.url) {
      saveAs(res.url)
    } else {
      notification.error('Unable to load table try again later')
    }
  }

  return (
    <>
      <DashboardMenu
        onCycleChange={setBudgetCycle}
      />
      <RefreshButton
        isDataNeedToRefresh={isDataNeedToRefresh}
        onClick={() => {
          dispatch(getAllBudgets()).then(restoreTimer)
        }}
      />
      <BudgetsTable
        data={modifiedBudgetsData}
        exportBudgets={handleExportBudgetsButton}
        configCurrencies={configCurrencies}
        cycle={!!budgetCycle}
        isLoading={budgetsResponseWaiting}
      />
    </>
  )
}

export default DashboardBudgets
