import React from 'react'
import clsx from 'clsx'
// @ts-expect-error no d.ts found for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'
import { Link } from 'react-router-dom'

// components
import Table from 'components/ui/Table/index'
import Button from 'components/ui/Button'
import CellEdit from 'pages/TeamMembers/table-cell-edit'

import { IUserData } from 'state-manager/reducers/user-data'
import { IAllClients } from 'state-manager/reducers/all-clients'

// styles
import classes from 'pages/TeamMembers/TeamMembers.module.scss'

import routes from 'routes'
import { ADD_TEAM_MEMBER_MODAL } from 'state-manager/constants'
import { useAppDispatch } from 'state-manager/store'
import { ICustomColumn } from 'state-manager/reducers/table'
import {teamMemberType} from "state-manager/reducers/team-members";

type UserNameType = { original: { firstName?: string, lastName?: string } }

const UserName = ({ original }: UserNameType) => (original.firstName ? <span>{original.firstName} {original.lastName}</span> : 'Pending')

type DataTableType = {
  data: Array<teamMemberType>
  allClients: IAllClients['data']
  udPermissions: IUserData['permissions']
}

const DataTable: React.FC<DataTableType> = ({
  data,
  allClients,
  udPermissions,
}) => {
  const dispatch = useAppDispatch()

  const superCompanyAdmin = data.find((member) => member.role === 'super_company_admin')

  const columns = React.useMemo<Array<ICustomColumn>>(
    () => [
      {
        name: 'userName',
        Header: 'User name',
        accessor: (data) => `${data.firstName} ${data.lastName}`,
        Cell: UserName,
        style: { whiteSpace: 'unset', wordBreak: 'break-all', textAlign: 'center' },
      },
      {
        name: 'email',
        Header: 'Email',
        style: { whiteSpace: 'unset', wordBreak: 'break-all', textAlign: 'center' },
      },
      {
        name: 'clients',
        Header: 'Clients',
        accessor: (data) => {
          if (data?.clients) {
            return allClients.filter((client) => data.clients.includes(client.id)).map((client) => client.name).join(', ')
          }
          return null
        },
        width: 440,
        style: { whiteSpace: 'unset' },
        Cell: ({ original }) => {
          if (!original.clients?.length && !original.hasAllClients) {
            return 'None'
          }
          if (original.hasAllClients) {
            return 'All'
          }
          const clients = allClients.filter((item) => original.clients.includes(item.id))
          return (
            <>
              {clients.map((client, index) => (
                <div key={index}>
                  <Link className="fw-semibold color-green" to={{ pathname: formatRoute(routes.clients, { id: client.id }) }}>{client.name}</Link>
                  <span className="color-green">{index < clients.length - 1 ? ', ' : ''}</span>&nbsp;
                </div>
              ))}
            </>
          )
        },
      },
      {
        name: 'roleLabel',
        Header: 'Access level',
        width: 180,
        filterType: 'select',
      },
      {
        name: 'edit',
        width: 80,
        isHideSortOption: true,
        isHideFilter: true,
        Cell: ({ original }) => {
          const { id, role, companyId } = original

          if (!original.firstName) {
            return (
              <div className="px-2">
                <CellEdit
                  id={id}
                  companyId={companyId}
                  role={role}
                  withClients={0}
                  isPending
                  superAdmin={superCompanyAdmin}
                />
              </div>
            )
          }

          return (
            <div className="px-2">
              <CellEdit
                id={id}
                role={role}
                withClients={original.clients.length}
                superAdmin={superCompanyAdmin}
                isPending={false}
              />
            </div>
          )
        },
      },
    ],
    [allClients, superCompanyAdmin],
  )

  return (
    <Table
      tableName="teamMembers"
      title="Team members"
      hiddenOverflow
      columns={columns}
      data={data}>
      {udPermissions.canInviteUsers && (
        <Button
          title="Add User"
          disabled={!allClients.length}
          dataCy="add new team member"
          className={clsx(classes.addUserButton, 'btn mt-sm-1')}
          onClick={() => {
            dispatch({type: ADD_TEAM_MEMBER_MODAL.SHOW})
          }}>
          Add User
        </Button>
      )}
    </Table>
  )
}

export default DataTable
