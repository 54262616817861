import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { CLIENT_ACTIONS } from 'state-manager/constants'

export interface IAllClentsCharts {
  data: Array<{
    id: number,
    name: string,
    currentCycle: {
      x: number,
      y: number,
    },
    previousCycle: {
      x: number,
      y: number,
    },
  }>,
}

const initialState: IAllClentsCharts = {
  data: [],
}

export const types = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    currentCycle: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    previousCycle: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  })),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CLIENT_ACTIONS.CLIENT_GET_CHART_DATA.SUCCESS:
      return updateObject(state, {
        data: action.data,
      })

    default:
      return state
  }
}
