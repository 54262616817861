import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import uniqBy from 'lodash.uniqby'

// components
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'
import Input from 'components/ui/TextInput'
import Table from 'components/ui/Table'

import { ACTIVE, EMPTY, PAUSED } from 'constants/status'

import { CampaignType } from 'state-manager/reducers/single-budget'

// styles
import classes from './ModalUpdate.module.scss'

const options = [
  EMPTY,
  ACTIVE,
  PAUSED,
]

const CampaignCell = ({ value }: { value: string }) => <div className={classes.whiteSpace}>{value}</div>

type ModalUpdateType = {
  onSave: (values: {
    selectedValues: Array<CampaignType>;
    newStatus: string;
    newBudget: string;
  }) => void;
  data: Array<CampaignType>;
  onClose: () => void;
}

const ModalUpdate: React.FC<ModalUpdateType> = ({
  onSave,
  onClose,
  data,
}) => {
  const newColumns = [
    {
      name: 'name',
      Header: 'Campaign name',
      visible: true,
      width: 450,
      Cell: CampaignCell,
    },
    {
      name: 'status',
      Header: 'Status',
      filterType: 'select',
      width: 150,
      visible: true,
    },
    {
      name: 'dailyBudget',
      Header: 'Daily budget',
      filterType: 'numberRange',
      filterWithoutCell: false,
      width: 160,
      visible: true,
    },
  ]

  const formik = useFormik({
    initialValues: {
      values: [],
      budget: '',
      status: EMPTY,
    },

    validationSchema: Yup.object().shape({
      values: Yup
        .array()
        .min(1, 'Select at least one campaign'),
      budget: Yup
        .number()
        .when('status', ([status], schema) => (
          status === EMPTY ? schema.required('This field is required') : schema
        ))
        .positive(),
      status: Yup
        .string()
        .when('budget', ([budget], schema) => (
          !budget ? schema.oneOf([ACTIVE, PAUSED], 'Select value') : schema
        )),
    }, ['budget', 'status']),

    onSubmit: (values) => {
      onSave({
        selectedValues: values.values.map(({ id }) => id),
        newStatus: values.status,
        newBudget: values.budget,
      })
    },
  })
  const selected = formik.values.values

  const handleSelectCampaign = (value) => {
    let newValue = [...selected]

    const idx = newValue.indexOf(value)

    if (idx !== -1) {
      newValue = [
        ...newValue.slice(0, idx),
        ...newValue.slice(idx + 1),
      ]
    } else {
      newValue.push(value)
    }

    formik.setFieldValue('values', newValue)
  }

  const handleSelectAll = (selectAll: boolean, sortedData) => {
    if (selectAll) {
      const newSelection = Array.isArray(sortedData) ? [...selected, ...sortedData] : [...selected, ...data]
      formik.setFieldValue(
        'values',
        uniqBy(newSelection, 'id'),
      )
    } else {
      formik.setFieldValue(
        'values',
        [],
      )
    }
  }

  const selectedIds = useMemo(() => selected.map(({ id }) => id), [selected])

  return (
    <Modal
      title="Set Parameters"
      size="xl"
      className="d-flex-column"
      onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <div className={clsx(classes.modalUpdateContainer, 'mb-2')}>
          <Table
            tableName="updateBulk"
            selectable
            onToggleSelection={handleSelectCampaign}
            onSelectAll={handleSelectAll}
            selected={selectedIds}
            columns={newColumns}
            data={data}
            sortable={false}
            selectedCount
            pageSize={50}
            showAllData
          />
        </div>
        <div className={clsx(classes.stableHeight, 'fw-medium fs-sm color-red mb-2 text-center')}>
          {formik.touched.values && formik.errors.values}
        </div>
        <div className={classes.actionsWrapper}>
          <div className={classes.status}>
            <div className={classes.title}>Status</div>
            <select
              data-cy="status selector"
              name="status"
              onChange={formik.handleChange}
              value={formik.values.status}
              className={classes.statusSelect}>
              {options.map((option, index) => (
                <option key={index} value={option} label={option} data-cy={option}>
                  {option}
                </option>
              ))}
            </select>
            <div className={clsx(classes.stableHeight, 'text-right fw-medium fs-sm color-red mt-1')}>
              {formik.touched.status && formik.errors.status}
            </div>
          </div>
          <div className={classes.dailyBudget}>
            <Input
              small
              dataCy="daily budget input"
              type="number"
              label="Daily budget"
              name="budget"
              value={`${formik.values.budget}`}
              errorMsg={formik.touched.budget && formik.errors.budget}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter budget"
              className={classes.budgetInput}
            />
          </div>
        </div>
        <Button
          title="Save Changes"
          dataCy="save bulk changes"
          type="submit"
        />
      </form>
    </Modal>
  )
}

export default ModalUpdate
