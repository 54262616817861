import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// components
import NumberInput from 'components/ui/NumberInput'
import SpriteIcon from 'components/ui/SpriteIcon'

// hooks
import _useToggle from 'hooks/use-toggle'

import { formatNumber } from 'utils/formatNumber'

// styles
import classes from 'pages/Budgets/Budgets.module.scss'
import { NUMBER_FORMATTER } from 'constants/regex'

const formatter = (str: string) => {
  const [integerPart, decimalPart] = str.split('.')

  const formattedInteger = integerPart.replace(NUMBER_FORMATTER, ',')
  if (!integerPart) {
    return '0'
  }
  if (decimalPart) {
    return `${formattedInteger}.${decimalPart.substring(0, 2)}`
  }
  return formattedInteger
}

type CellDailyBudgetSelectType = {
  value: number;
  campaignId: string;
  budgetId: string;
  isSharedBudget?: boolean;
  updateCampaignDailyBudget: (budgetId: string, campaignId: string, formatted: number) => Promise<void>;
  canManageBudgets?: boolean;
}

const CellDailyBudgetSelect: React.FC<CellDailyBudgetSelectType> = ({
  value,
  campaignId,
  budgetId,
  isSharedBudget,
  updateCampaignDailyBudget,
  canManageBudgets = true,
}) => {
  const [isEditing, setIsEditing] = _useToggle(false)
  const [innerValue, setInnerValue] = useState(value)

  const handleDailyBudgetChange = () => {
    const formatted = Math.trunc(innerValue * 100) / 100
    updateCampaignDailyBudget(budgetId, campaignId, formatted)
      .then(setIsEditing)
  }

  return isEditing ? (
    <div className="d-flex align-items-center justify-content-between">
      <NumberInput
        className="input-small-height"
        value={innerValue}
        onChange={setInnerValue}
        formatter={formatter}
      />
      <SpriteIcon className="ml-1" name="check" size="sm" onClick={handleDailyBudgetChange} />
      <SpriteIcon className="ml-1" name="close" size="sm" onClick={setIsEditing} />
    </div>
  ) : (
    <div className="d-flex align-items-center justify-content-between">
      <div>
        <span className="mr-1">{formatNumber(value)}</span>
        {canManageBudgets && <SpriteIcon name="editPencil" size="sm" onClick={setIsEditing} />}
      </div>
      {isSharedBudget && (
        <div className={clsx(classes.sharedLabel, 'color-white fs-14 background-green-gradient')}>shared</div>
      )}
    </div>
  )
}

CellDailyBudgetSelect.propTypes = {
  value: PropTypes.number.isRequired,
  campaignId: PropTypes.number.isRequired,
  budgetId: PropTypes.number.isRequired,
  isSharedBudget: PropTypes.bool.isRequired,
  updateCampaignDailyBudget: PropTypes.func.isRequired,
}

export default CellDailyBudgetSelect
