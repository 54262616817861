import React from 'react'
import clsx from 'clsx'

// styles
import classes from 'pages/shared/PageTemplate/PageTemplate.module.scss'

const PageTemplate: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <div className={clsx(classes.pageTemplate, 'position-relative px-4 py-4')}>
    {children}
  </div>
)

export default PageTemplate
