import React, { useState } from 'react'
import { matchSorter } from 'match-sorter'
import uniqBy from 'lodash.uniqby'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

export const selectWithSearchFilterMethod = (filter, rows) => {
  const selected = []

  const searchResult = matchSorter(rows, filter.value, { keys: [filter.id], threshold: matchSorter.rankings.CONTAINS })

  const finalResult = [
    ...selected,
    ...searchResult,
  ]

  return selected.length ? uniqBy(finalResult, 'id') : finalResult
}

const SelectWithSearchFilter = ({ onChange, filter, id, options }) => {
  const [value, setValue] = useState(filter?.value ?? '')

  _useDidUpdate(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, 500)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <>
      <datalist id={`${id}list`}>
        {options.map((option, index) => {
          if (option) {
            return (
              <option key={index} value={option} data-cy={`${id} option - ${option}`}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            )
          }
        })}
      </datalist>
      <input
        data-cy={`${id} input`}
        list={`${id}list`}
        type="text"
        placeholder="Selected All"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        className="form-control"
      />
    </>
  )
}

export default SelectWithSearchFilter
