import React, { useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'

export const multiSelectFilterMethod = (filter, row) => {
  if (!filter.value.length) {
    return true
  }

  // if (row?.selected) {
  //   return true
  // }

  const precessedOptions = filter.value.map((option) => (option.value))

  return precessedOptions.every((el) => row[filter.id].includes(el))
}

// eslint-disable-next-line react/prop-types
const MultiSelectFilter = ({ onChange, filter, options = [] }) => {
  const precessedOptions = options.map((option) => ({ label: option, value: option }))

  // eslint-disable-next-line react/prop-types
  const [selected, setSelected] = useState(filter?.value || [])

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions)
    onChange(selectedOptions)
  }

  const customValueRenderer = (selected) => {
    if (!selected.length) {
      return 'All'
    }
    if (selected.length > 1) {
      return `${selected.length} selected`
    }

    return selected.map(({ label }) => label)
  }

  return (
    <MultiSelect
      hasSelectAll={false}
      options={precessedOptions}
      value={selected}
      onChange={handleChange}
      disableSearch={options.length < 5}
      labelledBy="select"
      valueRenderer={customValueRenderer}
      ClearSelectedIcon={() => {}}
      overrideStrings={{
        allItemsAreSelected: 'all',
        selectAll: 'all',
      }}
    />
  )
}

export default MultiSelectFilter
