import React from 'react'
import clsx from 'clsx'
import classes from './Widgets.module.scss'

type WidgetsType = {
  items: Array<{
    value: number;
    name: string;
    color: 'green' | 'blue' | 'yellow';
    isPercent: boolean;
  }>
}

const Widgets: React.FC<WidgetsType> = ({ items }) => (
  <div className={classes.wrapperWidgets}>
    {items.map((item) => (
      <div key={item.name} className={clsx(classes.widget, classes[item.color])}>
        <div className={classes.name}>{item.name}</div>
        <div className={classes.value}>
          {item.isPercent ? `${item.value} %` : item.value}
        </div>
      </div>
    ))}
  </div>
)

export default Widgets
