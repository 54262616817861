import { SUMO_PLANS } from "state-manager/constants"
import checkObjectStructure from "helpers/checkObjectStructure"

export interface ISumoPlan {
  planId: string,
  name: string,
  price: number,
  monthlyAdSpend: number,
  link: string,
}

type actionType = {
  type: string,
  data: Array<any>,
}

const initialState: Array<ISumoPlan> = []

export default (state = initialState, action: actionType) => {
  switch (action.type) {
    case SUMO_PLANS.sumoGetPlans.SUCCESS: {
      const idx = action.data.findIndex((item) => !checkObjectStructure(item, ['planId', 'name', 'price', 'monthlyAdSpend', 'link']))
      if (idx !== -1) {
        return state
      }
      return action.data
    }
    default: {
      return state
    }
  }
}
