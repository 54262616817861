import React from 'react'

// components
import StaticNotification from 'components/StaticNotification'

// assets
import CancelIcon from 'assets/icons/cancelOutline.svg'

type ModalConfirmDeactivationType = {
  renewalDate?: string;
  onClose: () => void;
  handleClick: () => void;
}

const ModalConfirmDeactivation: React.FC<ModalConfirmDeactivationType> = ({
  renewalDate,
  onClose,
  handleClick,
}) => (
  <StaticNotification
    icon={CancelIcon}
    title="Cancel Subscription"
    yes="Cancel Subscription"
    no="Cancel"
    type="warning"
    onClose={onClose}
    handleClick={handleClick}
    description={`Please note all clients and budgets will be deactivated on ${renewalDate}. All data will be deleted after 30 days.`}
  />
)

export default ModalConfirmDeactivation
