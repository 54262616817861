import { useEffect, useState } from "react"

// the time after which the data should be updated (15 min)
const REFRESH_DELAY = 900000

export const BUDGET_AND_CLIENT_TABLE_REFRESH_DELAY = 30000

export const checkIfDataNeedToRefresh = (timeFromLastUpdate: Date, refreshDelay?: number) => {
  refreshDelay = refreshDelay ?? REFRESH_DELAY
  const now = new Date()
  return (now - timeFromLastUpdate) > refreshDelay
}

const useRefreshData = () => {
  const [isDataNeedToRefresh, setIsDataNeedToRefresh] = useState(false)

  useEffect(() => {
    if (!isDataNeedToRefresh) {
      const timerId = setTimeout(() => {
        setIsDataNeedToRefresh(true)
      }, REFRESH_DELAY)

      return () => {
        clearTimeout(timerId)
      }
    }
  }, [isDataNeedToRefresh])

  return { isDataNeedToRefresh, restoreTimer: () => setIsDataNeedToRefresh(false) }
}

export default useRefreshData
