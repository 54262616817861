import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// components
import DashboardMenu from 'pages/Dashboard/shared/DashboardMenu'
import DashboardChart from 'pages/Dashboard/shared/Chart'

// actions
import { getAllClientsChartData } from 'state-manager/actions/clients'

// hooks
import _useDidMount from 'hooks/lifecycle/use-did-mount'

import { useAppSelector } from 'state-manager/store'

const DashboardClientsChart: React.FC = () => {
  const [budgetCycle, setBudgetCycle] = useState<boolean | number>(false)

  const dispatch = useDispatch()

  const clientsChartData = useAppSelector((state) => state.allClientsChart.data)

  _useDidMount(() => {
    if (!clientsChartData.length) {
      dispatch(getAllClientsChartData())
    }
  })

  const modifiedClientsChartData = clientsChartData.map((item) => ({
    name: item.name,
    id: item.id,
    x: budgetCycle ? item.previousCycle.x : item.currentCycle.x,
    y: budgetCycle ? item.previousCycle.y : item.currentCycle.y,
  }))

  return (
    <>
      <DashboardMenu onCycleChange={setBudgetCycle} />
      <DashboardChart type="clients" chartData={modifiedClientsChartData} />
    </>
  )
}

export default DashboardClientsChart
