import React, { useCallback, useState } from 'react'
import Slider, { Handle, SliderProps } from 'rc-slider'
import clsx from 'clsx'

// constants
import { NUMBER_FORMATTER } from 'constants/regex'

// styles
import 'rc-slider/assets/index.css'
import classes from 'pages/Subscriptions/MoneySlider/Slider.module.scss'

const purple = '#3DB14A'
const gray = '#EAEAEE'

const marks = {
  1: 25000,
  2: 50000,
  3: 100000,
  4: 250000,
  5: 500000,
  6: 750000,
  7: 1000000,
  8: 1500000,
  9: 2000000,
  10: 3000000,
  11: 4000000,
}

const steps = {
  1: {},
  2: {},
  3: {},
  4: {},
  5: {},
  6: {},
  7: {},
  8: {},
  9: {},
  10: {},
  11: {},
}

type marksOption = keyof typeof marks

const getRealValue = (sliderValue: marksOption) => marks[sliderValue]

const numberWithCommas = (number: number) => number.toString().replace(NUMBER_FORMATTER, ',')

const MyHandle: React.FC<{ value: marksOption, currency: string }> = ({ value, currency }) => {
  const realValue = getRealValue(value)
  return (
    <div className={classes.container}>
      <div className={clsx(classes.wrapper, 'd-flex justify-content-center align-items-center')}>
        <div className="fw-medium color-black fs-16 mt-3">
          Monthly ad spend{'\n'}
          {realValue < 4000000 ? `${currency}${numberWithCommas(realValue)}` : `Over ${currency}3,000,000`}
        </div>
      </div>
    </div>
  )
}

type CustomHandleType = Exclude<SliderProps['handle'], undefined>

type MoneySliderType = {
  value: number
  currency: string
  handleSliderChange: (value: number) => void
}

const getInitialSliderKeyByValue = (searchValue: number) => {
  for (const [key, value] of Object.entries(marks)) {
    if (value === searchValue) {
      return Number(key)
    }
  }
  return 1
}

const MoneySlider: React.FC<MoneySliderType> = ({ value, handleSliderChange, currency }) => {
  const [sliderValue, setSliderValue] = useState<marksOption>(() => getInitialSliderKeyByValue(value))

  const CustomHandle = useCallback<CustomHandleType>((props) => (
    <Handle {...props}>
      <MyHandle currency={currency} value={props.value as marksOption} />
    </Handle>
  ), [currency])

  return (
    <Slider
      marks={steps}
      min={1}
      max={11}
      step={null}
      value={sliderValue}
      handleStyle={{
        marginTop: '-12px',
        width: '32px',
        height: '32px',
        borderWidth: '8px',
        borderColor: '#3DB14A',
        borderRadius: 4,
        backgroundColor: 'white',
        padding: 0,
        boxShadow: '0 1px 1px rgba(40,47,82,0.5), inset 0 0 0 4px white',
      }}
      handle={CustomHandle}
      dotStyle={{ display: 'none' }}
      trackStyle={{ backgroundColor: purple, height: '8px' }}
      railStyle={{ backgroundColor: gray, height: '8px' }}
      onChange={(value) => {
        setSliderValue(value as marksOption)
        handleSliderChange(marks[value as marksOption])
      }}
    />
  )
}

export default MoneySlider
