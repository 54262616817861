import { CurrencyType } from "constants/application-configs"

export type currencyType = {
  id: string;
  symbol: string;
  code: string;
}

export default (currencyId: number, configCurrencies: Array<CurrencyType>, onlySymbol?: boolean) => {
  const currentCurrency = configCurrencies.find((singleCurrency) => singleCurrency.id === currencyId)
  if (!currentCurrency) {
    return ''
  }

  return !onlySymbol ? `${currentCurrency.symbol} ${currentCurrency.code}` : currentCurrency.symbol
}
