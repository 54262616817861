import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
// @ts-expect-error no d.ts file for react-router-named-routes
import { formatRoute } from 'react-router-named-routes'

// components
import SpriteIcon from 'components/ui/SpriteIcon'

// routes
import routes from 'routes'

// constants
import { LOCATION_BUDGET_EDIT } from 'pages/ManageBudget/constants'

type ManageBudgetPageLayoutType = {
  title: string;
  subtitles: Array<string>;
  step: number;
}

const ManageBudgetPageLayout: React.FC<React.PropsWithChildren<ManageBudgetPageLayoutType>> =
  ({
    title,
    children,
    subtitles = [],
    step = 0,
  }) => {
    const isEditing = window.location.pathname.includes(LOCATION_BUDGET_EDIT)
    const params = useParams<{ id: string }>()

    const history = useHistory()

    const handleBackButton = () => {
      if (isEditing) {
        history.push({ pathname: formatRoute(routes.budgets, { id: params.id }) })
        return
      }

      if (history.length > 2) {
        history.goBack()
      } else {
        history.push(routes.dashboardClients)
      }
    }

    return (
      <div className="full-height w-100 mw-1110 m-auto p-2">
        <div className="d-flex align-items-center mt-8">
          <SpriteIcon name="arrowLeft" size="md" className="mr-3 position-absolute cursor-pointer" onClick={handleBackButton} dataCy="return" />
          <div className="wrapper d-flex flex-column align-center w-100">
            <div className="fs-lg fw-semibold text-center w-100">
              {title}
            </div>
            {subtitles.length && step > 0 && (
              <div className="w-100 text-center mt-2 color-dark-grey">
                {subtitles[step - 1]}
              </div>
            )}
          </div>
        </div>
        <div className="page-content-manage-budgets d-flex justify-content-center">
          {children}
        </div>
      </div>
    )
  }

export default ManageBudgetPageLayout
