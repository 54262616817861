import React from 'react'
import clsx from 'clsx'

// styles
import classes from 'components/ui/Badge/Badge.module.scss'

type props = {
  title: string,
  className?: string,
}

const Badge: React.FC<props> = ({
  title,
  className,
}) => (
  <span
    className={clsx(classes.badge, className)}>
    {title}
  </span>
)

export default Badge
