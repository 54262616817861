import React, { useRef } from 'react'
import { Formik } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'

import MQ from 'utils/mq'
import Dropdown from 'components/ui/Dropdown'
import Tabs from 'components/ui/Tabs'
import { TAB_SIZE_SM } from 'components/ui/Tabs/constants'

import ManageBudgetPageLayout from 'pages/ManageBudget/layout'
import classes from 'pages/ManageBudget/styles.module.scss'
import { BudgetFormValuesType } from '.'
import { BudgetStepType } from './steps'

const tabsOptions = [
  { id: 1, label: 'General' },
  { id: 2, label: 'Amount' },
  { id: 3, label: 'Campaigns' },
  { id: 4, label: 'Targets' },
  { id: 5, label: 'Automation' },
]

const tabsDropdownOptions = [
  { value: 1, label: 'General' },
  { value: 2, label: 'Amount' },
  { value: 3, label: 'Campaigns' },
  { value: 4, label: 'Targets' },
  { value: 5, label: 'Automation' },
]

type EditBudgetType = {
  initialValues: BudgetFormValuesType;
  handleSubmit: (values: BudgetFormValuesType) => void;
  steps: Array<BudgetStepType>;
}

const EditBudget: React.FC<EditBudgetType> = ({ initialValues, handleSubmit, steps }) => {
  const subtitles = steps.map((item) => item.subtitle)
  const validationSchema = steps.reduce((prev, curr) => (
    curr.validationSchema
      ? prev.concat(curr.validationSchema)
      : prev
  ), Yup.object())

  const tabRef = useRef<HTMLDivElement>()

  return (
    <Formik
      initialValues={{
        currentTab: 1,
        ...initialValues,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        handleSubmit,
      }) => {
        const currectStep = values.currentTab - 1
        const Component = steps[currectStep].component

        const onSubmit = () => {
          const errorsArr = Object.keys(errors)
          const idx = errorsArr[0]
            ? steps.findIndex(
              ({ validationSchema }) => (
                validationSchema
                  ? Object.keys(validationSchema.fields).includes(errorsArr[0])
                  : false
              ),
            )
            : -1
          if (idx === -1) {
            handleSubmit()
          } else {
            if (tabRef.current) {
              const element = tabRef.current.querySelector(`[data-index="${idx}"]`)
              if (!element) {
                return
              }
              if (element.classList.contains('colorChangingTab')) {
                element.classList.remove('colorChangingTab')
                setTimeout(() => element.classList.add('colorChangingTab'))
              } else {
                element.classList.add('colorChangingTab')
              }
            }
          }
        }

        return (
          <ManageBudgetPageLayout
            title="Edit Budget"
            subtitles={subtitles}
            step={values.currentTab}>
            <div className={classes.formWrapper}>
              <div className={clsx('pt-7', classes.formInner, values.currentTab !== 3 && classes.formInnerMaxWidth)}>
                <MQ.SM_UP>
                  <div className="d-flex justify-content-center mb-4">
                    <Tabs
                      size={TAB_SIZE_SM}
                      items={tabsOptions}
                      onSelect={(tab) => setFieldValue('currentTab', tab + 1)}
                      value={currectStep}
                      className="p-1"
                      tabRef={tabRef}
                    />
                  </div>
                </MQ.SM_UP>
                <MQ.SM_DOWN>
                  <Dropdown
                    options={tabsDropdownOptions}
                    field={{
                      value: tabsDropdownOptions[values.currentTab - 1].value,
                      onChange: (selectedOption) => setFieldValue('currentTab', selectedOption.value),
                    }}
                    wrapperClassNames="mb-4 z-index-9"
                  />
                </MQ.SM_DOWN>
                <Component
                  key={steps[currectStep].id}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                />
                <div className={clsx(classes.buttonsBarWrapper, 'p-2')}>
                  <div className="d-flex align-items-center justify-content-between mw-920 m-auto">
                    <button
                      data-cy="save budget changes"
                      className="btn color-green m-auto"
                      type="submit"
                      onClick={onSubmit}>
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ManageBudgetPageLayout>
        )
      }}
    </Formik>
  )
}

export default EditBudget
