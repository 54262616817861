import React from 'react'

import SpriteIcon from 'components/ui/SpriteIcon'

type TableCellRefreshType = {
  id: number;
  onRefresh: (id: number) => void;
}

const TableCellRefresh: React.FC<TableCellRefreshType> = ({
  id,
  onRefresh,
}) => (
  <div
    className="d-flex align-items-center">
    <div
      role="button"
      data-cy="Refresh data source"
      onClick={() => onRefresh(id)}>
      <SpriteIcon name="refresh" size="md" />
    </div>
  </div>
)

export default TableCellRefresh
