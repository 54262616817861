import React from 'react'
import clsx from 'clsx'
import { Bar } from 'react-chartjs-2'

// helpers
import { getLabels, getActualData, getBudgetData } from 'components/ui/Charts/helpers'

// styles
import classes from 'components/ui/Charts/chart.module.scss'

type LineChartProps = {
  budgetData: any;
}

const LineChart: React.FC<LineChartProps> = ({
  budgetData,
}) => {
  const labels = getLabels(budgetData)
  const budgetedSpend = getBudgetData(budgetData)
  const actualSpend = getActualData(budgetData)

  const maxYAxe = Math.ceil(Math.max(...budgetedSpend, ...actualSpend) / 100) * 100 || 100
  const stepSize = Math.ceil(maxYAxe / 5)

  const data = {
    labels,
    datasets: [{
      type: 'line',
      label: 'Actual Daily Spend (cumulative)',
      data: actualSpend,
      fill: true,
      borderColor: '#71B37C',
      backgroundColor: '#80c98a',

    }, {
      type: 'line',
      label: 'Budget (or Target Spend)',
      data: budgetedSpend,
      fill: false,
      backgroundColor: '#BABABA',
      borderColor: '#BABABA',
      pointBorderColor: '#BABABA',
      pointBackgroundColor: '#BABABA',
      pointHoverBackgroundColor: '#878787',
      pointHoverBorderColor: '#878787',
    }],
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        boxWidth: 10,
      },
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        stacked: false,
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0,
          max: maxYAxe,
          stepSize,
        },
      }],
    },
  }

  return (
    <div className={clsx(classes.graphContainer)}>
      <Bar
        data={data}
        width={1920}
        height={200}
        options={options}
      />
    </div>

  )
}

export default React.memo(LineChart)
